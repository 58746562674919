import React, {useContext, useState, useEffect, useCallback} from "react";
import PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faFileExport,
  faSearch,
  faFilter,
  faEyeSlash,
  faEye
} from "@fortawesome/free-solid-svg-icons";
import styled, {css} from "styled-components";

// Contexts
import {AuthContext} from "../../contexts/auth.js";

// Hooks
import useApi from "../../hooks/useApi.js";
import useMountedState from "../../hooks/useMountedState.js";

// Components
import BaseTable from "../../components/BaseTable.js";
import Pagination from "../../components/Pagination.js";
import Dropdown from "../../components/Dropdown.js";
import PhoneNumbers from "../../components/PhoneNumbers.js";
import Badge from "../../components/Badge.js";
import ModalExport from "../../components/ModalExport.js";
import ModalFacilityUserAdd from "./ModalFacilityUserAdd.js";
import ModalFacilityUserEdit from "./ModalFacilityUserEdit.js";
import ModalFacilityUserDelete from "./ModalFacilityUserDelete.js";
import ModalUserFilter from "../general/ModalUserFilter.js";
import ModalViewAllFacilityUsers from "../general/ModalViewAllFacilityUsers.js";

// Style
import {bp} from "../../style/components/breakpoints.js";
import {colors, heroTheme, pad} from "../../style/components/variables.js";
import {
  Button,
  Heading,
  Inline,
  TableWrapper,
  TableHeaderWrap,
  TableMenu,
  TableFooter,
  Text,
  Search,
  SearchWrapper,
  SearchIcon,
  Abbr,
  Small,
  Pill
} from "../../style/components/general.js";

const formatUsers = formattedUsers =>
  formattedUsers.map(user => {
    const {
      publicId,
      email,
      firstName,
      lastName,
      type,
      role,
      phones,
      primary,
      company,
      isInvited,
      isAccepted,
      isActive,
      isDeleted
    } = user;

    return {
      publicId: publicId,
      firstName: `${firstName} ${lastName}`,
      userCompanyId: company?.name,
      userRoleId: role?.label || type?.name?.toUpperCase(),
      status: (
        <>
          {isActive && isAccepted && !isDeleted && (
            <Pill color={heroTheme.success}>
              <Small inverted>Active</Small>
            </Pill>
          )}
          {isDeleted && (
            <Pill color={colors.gray}>
              <Small inverted>
                <Abbr title="User is deleted">deleted!</Abbr>
              </Small>
            </Pill>
          )}
          {isInvited && !isAccepted && !isDeleted && (
            <Pill color={heroTheme.warning}>
              <Small inverted>
                <Abbr title="User has not accepted invitation">invited</Abbr>
              </Small>
            </Pill>
          )}
          {isInvited && isAccepted && !isActive && !isDeleted && (
            <Pill color={heroTheme.error}>
              <Small inverted>
                <Abbr title="User is disabled">disabled</Abbr>
              </Small>
            </Pill>
          )}
          {!isInvited && !isActive && !isDeleted && (
            <Pill color={colors.gray}>
              <Small inverted>
                <Abbr title="User has not been invited">contact</Abbr>
              </Small>
            </Pill>
          )}
        </>
      ),
      email: <a href={`mailto:${email}`}>{email}</a>,
      phone: primary && <PhoneNumbers primary={primary} phones={phones} />,
      isDeleted
    };
  });

const TableFacilityUsers = ({facilityId, setRefreshFacility}) => {
  const isMounted = useMountedState();

  const {roles, atLeast, roleCanAccessResource} = useContext(AuthContext);

  const [loadingUsers, setLoadingUsers] = useState(true);
  const [users, setUsers] = useState();
  const [original, setOriginal] = useState([]);
  // Edit
  const [edit, setEdit] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  // Search
  const [query, setQuery] = useState("");
  // Filters
  const [activeFilters, setActiveFilters] = useState();
  const [showHidden, setShowHidden] = useState(false);
  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [pageTotal, setPageTotal] = useState(1);
  const [orderBy, setOrderBy] = useState("asc");
  const [groupBy, setGroupBy] = useState("firstName");
  const [limits, setLimits] = useState([]);
  const [limit, setLimit] = useState(10);
  // Modals
  const [showModalFilters, setShowModalFilters] = useState(false);
  const [showModalExport, setShowModalExport] = useState(false);
  const [showModalAdd, setShowModalAdd] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalViewAll, setShowModalViewAll] = useState(false);

  const {api: apiFacilityUsers} = useApi("facility-users");

  const getFacilityUsers = useCallback(
    p => {
      setLoadingUsers(true);

      const filter = {
        ExcludeCreator: true
      };

      if (query) filter.Search = query;
      if (activeFilters?.departments) filter.Departments = activeFilters.departments;
      if (activeFilters?.roles) filter.Roles = activeFilters.roles;
      if (showHidden) {
        filter.showHidden = true;
        if (activeFilters?.state) filter.State = activeFilters.state;
      }
      apiFacilityUsers
        .callGet(null, {
          facilityId: facilityId,
          page: p || 1,
          orderBy,
          groupBy,
          limit,
          filter: JSON.stringify(filter)
        })
        .then(({status, data}) => {
          if (status === 200 && data) {
            setCurrentPage(data.page);
            setPageTotal(data.pages);
            setOriginal(data.users);
            const formatted = formatUsers(data.users);
            setUsers(formatted);

            // Page Limit
            const {total: dataTotal} = data;
            setTotal(dataTotal);
            let count = 10;
            const temp = [];
            while (count < dataTotal + 10 && count <= 30) {
              temp.push(count);
              count += 10;
            }

            setLimits(temp);
            setLoadingUsers(false);
          }
        });
    },
    [apiFacilityUsers, facilityId, orderBy, groupBy, limit, query, activeFilters, showHidden]
  );

  // Initial Load
  useEffect(() => {
    if (isMounted() && facilityId && !users) getFacilityUsers();
  }, [isMounted, getFacilityUsers, facilityId, users]);

  // Update users on search, filter, sort or limit
  useEffect(() => {
    if (isMounted()) {
      setUsers(undefined);
      setCurrentPage(1);
    }
  }, [isMounted, query, activeFilters, showHidden, orderBy, groupBy, limit]);

  useEffect(() => {
    if (edit.length === 1) {
      original.map(user => {
        if (user.publicId === edit[0].publicId) setSelectedUser(user);
      });
    }
  }, [edit, original, setSelectedUser]);

  const handleSearch = e => {
    setCurrentPage(1);
    setQuery(e.target.value);
  };

  const handleReload = () => {
    setCurrentPage(1);
    getFacilityUsers();
  };

  return (
    <>
      <TableWrapper>
        <TableHeaderWrap>
          <StyledInline>
            <Heading>Users</Heading>
            <InlineSpaced>
              <SearchWrapper>
                <SearchIcon>
                  <FontAwesomeIcon icon={faSearch} />
                </SearchIcon>
                <Search
                  name="search"
                  type="text"
                  placeholder="Search..."
                  onChange={e => handleSearch(e)}
                />
              </SearchWrapper>
              <TableMenu>
                {atLeast("admin") && roles?.length > 0 && (
                  <Button type="button" onClick={() => setShowModalFilters(!showModalFilters)}>
                    <Badge
                      count={
                        (activeFilters?.departments ? activeFilters.departments.length : 0) +
                        (activeFilters?.roles ? activeFilters.roles.length : 0) +
                        (showHidden && activeFilters?.state?.length
                          ? activeFilters.state.length
                          : 0) +
                        (showHidden && !activeFilters?.state?.length ? 1 : 0)
                      }
                      offset="14px"
                      color={colors.heroGreen}
                    />
                    <FontAwesomeIcon icon={faFilter} />
                  </Button>
                )}
                {atLeast("admin") && roles?.length === 0 && (
                  <Button type="button" onClick={() => setShowHidden(!showHidden)}>
                    <FontAwesomeIcon icon={showHidden ? faEyeSlash : faEye} /> Hidden
                  </Button>
                )}
                {roleCanAccessResource("user", "export") && users && users.length > 0 && (
                  <Option
                    type="button"
                    onClick={() => {
                      setShowModalExport(true);
                    }}>
                    <abbr title="Export">
                      <FontAwesomeIcon icon={faFileExport} />
                    </abbr>
                  </Option>
                )}
              </TableMenu>
            </InlineSpaced>
          </StyledInline>
        </TableHeaderWrap>

        <BaseTable
          headings={{
            publicId: {header: "", disabled: true},
            firstName: {header: "Name", disabled: false},
            userCompanyId: {header: "Company", disabled: false},
            userRoleId: {header: "Role", disabled: false},
            status: {header: "Status", disabled: true},
            email: {header: "Email", disabled: false},
            phone: {header: "Phone", disabled: false}
          }}
          data={users || null}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          groupBy={groupBy}
          setGroupBy={setGroupBy}
          items={edit}
          setItems={setEdit}
          loading={loadingUsers}
        />
        <TableFooter>
          {total > 0 && (
            <>
              <Inline>
                {total > 10 && limits.length > 0 && (
                  <PageLimit>
                    <Dropdown
                      options={limits}
                      selection={limit}
                      setSelection={selection => {
                        setLimit(selection);
                        setCurrentPage(1);
                      }}
                    />
                  </PageLimit>
                )}
                <Text quiet>
                  {limit <= 30 && pageTotal > 1 && limits.length > 0 && "per page, "}
                  <ModalViewLink
                    disabled={!total || total <= 10}
                    onClick={!total || total <= 10 ? undefined : () => setShowModalViewAll(true)}>
                    {total} total
                  </ModalViewLink>
                </Text>
              </Inline>
              {limit <= 30 && pageTotal > 1 && (
                <Pagination
                  current={currentPage}
                  setCurrent={setCurrentPage}
                  pageTotal={pageTotal}
                  updateData={() => getFacilityUsers(currentPage)}
                  loading={loadingUsers}
                />
              )}
            </>
          )}
        </TableFooter>
      </TableWrapper>

      {showModalFilters && (
        <ModalUserFilter
          visible={showModalFilters}
          setVisible={setShowModalFilters}
          filters={activeFilters}
          setFilters={setActiveFilters}
          showHidden={showHidden}
          setShowHidden={setShowHidden}
        />
      )}

      {showModalExport && (
        <ModalExport
          visible={showModalExport}
          setVisible={setShowModalExport}
          allowRange={false}
          getExport={exportParams =>
            apiFacilityUsers.callGet(null, {
              ...exportParams,
              facilityId: facilityId,
              filter: JSON.stringify({ExcludeCreator: true})
            })
          }
        />
      )}

      {showModalAdd && (
        <ModalFacilityUserAdd
          visible={showModalAdd}
          setVisible={setShowModalAdd}
          facilityId={facilityId}
          updateTable={getFacilityUsers}
        />
      )}

      {showModalEdit && (
        <ModalFacilityUserEdit
          selected={selectedUser}
          visible={showModalEdit}
          setVisible={setShowModalEdit}
          updateTable={getFacilityUsers}
        />
      )}

      {showModalDelete && (
        <ModalFacilityUserDelete
          selected={edit}
          visible={showModalDelete}
          setVisible={setShowModalDelete}
          updateTable={getFacilityUsers}
          setRefreshFacility={setRefreshFacility}
        />
      )}

      {showModalViewAll && (
        <ModalViewAllFacilityUsers
          visible={showModalViewAll}
          setVisible={setShowModalViewAll}
          showOptions={false}
          reload={handleReload}
          setRefreshFacility={setRefreshFacility}
        />
      )}
    </>
  );
};

TableFacilityUsers.propTypes = {
  facilityId: PropTypes.number.isRequired,
  setRefreshFacility: PropTypes.func.isRequired
};

// Style Overrides
const StyledInline = styled(Inline)`
  gap: ${pad}px;
  display: block;
  width: 100%;

  ${bp(1)} {
    display: flex;
  }
`;

const Option = styled(Button)`
  margin-left: ${pad}px;
`;

const PageLimit = styled.div`
  margin-right: ${pad}px;

  select {
    padding: ${pad / 2}px;
  }
`;

const InlineSpaced = styled(Inline)`
  justify-content: space-between;
  width: 100%;
`;

const ModalViewLink = styled.span`
  ${({disabled}) =>
    !disabled &&
    css`
      text-decoration: underline;
      cursor: pointer;
    `}
`;

export default TableFacilityUsers;
