import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileExport, faFilter, faSearch} from "@fortawesome/free-solid-svg-icons";

// Contexts
import {AuthContext} from "../../contexts/auth.js";
import {FacilityNavContext} from "../../contexts/facilitynav.js";

// Hooks
import useApi from "../../hooks/useApi.js";
import useMountedState from "../../hooks/useMountedState.js";
import usePrevious from "../../hooks/usePrevious.js";

// Components
import Modal from "../../components/Modal.js";
import BaseTable from "../../components/BaseTable.js";
import PhoneNumbers from "../../components/PhoneNumbers.js";
import ModalUserFilter from "./ModalUserFilter.js";
import ModalFacilityUserAdd from "../facility/ModalFacilityUserAdd.js";
import ModalFacilityUserEdit from "../facility/ModalFacilityUserEdit.js";
import ModalExport from "../../components/ModalExport.js";
import ModalFacilityUserDelete from "../facility/ModalFacilityUserDelete.js";
import Badge from "../../components/Badge.js";

// Style
import {colors, pad} from "../../style/components/variables.js";
import {bp} from "../../style/components/breakpoints.js";
import {
  Abbr,
  Inline,
  Text,
  TableFooter,
  TableWrapper,
  Small,
  SearchWrapper,
  Search,
  Button,
  Heading,
  TableMenu,
  SearchIcon
} from "../../style/components/general.js";

const ModalViewAllFacilityUsers = ({visible, setVisible, reload, setRefreshFacility}) => {
  const isMounted = useMountedState();

  const {atLeast, roleCanAccessResource} = useContext(AuthContext);
  const {facility} = useContext(FacilityNavContext);

  const [tableData, setTableData] = useState(null);
  const [query, setQuery] = useState(null);
  const [orderBy, setOrderBy] = useState("desc");
  const [groupBy, setGroupBy] = useState("createdAt");
  const [total, setTotal] = useState(0);
  const [current, setCurrent] = useState(1);
  const [pages, setPages] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [activeFilters, setActiveFilters] = useState();
  const [items, setItems] = useState([]);
  const [showDisabled, setShowDisabled] = useState(false);
  const [showDeleted, setShowDeleted] = useState(false);

  const [showModalFilters, setShowModalFilters] = useState(false);
  const [showModalExport, setShowModalExport] = useState(false);
  const [showModalAdd, setShowModalAdd] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);

  const selectedUser = useMemo(() => (items?.length === 1 ? items[0] : null), [items]);

  const scrollPos = useRef(null);
  const scrollContainer = useRef(null);

  const {api, loading} = useApi("facility-users");

  const limit = useRef(12);

  const prevOrderBy = usePrevious(orderBy);
  const prevGroupBy = usePrevious(groupBy);

  const formatTableData = useCallback(
    formattedUsers =>
      formattedUsers.map(user => {
        const {
          role,
          department,
          firstName,
          lastName,
          isInvited,
          isAccepted,
          isActive,
          isDeleted,
          email,
          primary,
          phones,
          company
        } = user;

        return {
          ...user,
          userRoleId: role?.label || null,
          userDepartmentId: department,
          firstName: (
            <span>
              {firstName} {lastName}&nbsp;
              {isDeleted && (
                <ErrorSmall>
                  <Abbr title="User is deleted">deleted!</Abbr>
                </ErrorSmall>
              )}
              {isInvited && !isAccepted && !isDeleted && (
                <Small>
                  <Abbr title="User has not accepted invitation">invited</Abbr>
                </Small>
              )}
              {isInvited && isAccepted && !isActive && !isDeleted && (
                <Small>
                  <Abbr title="User is disabled">disabled!</Abbr>
                </Small>
              )}
              {!isInvited && !isActive && !isDeleted && (
                <Small>
                  <Abbr title="User has not been invited">contact</Abbr>
                </Small>
              )}
            </span>
          ),
          email: <a href={`mailto:${email}`}>{email}</a>,
          phone: primary && <PhoneNumbers primary={primary} phones={phones} />,
          company: company ? company.name : ""
        };
      }),
    []
  );

  const getTableData = useCallback(
    (page, l) => {
      const filter = {
        ExcludeCreator: true
      };

      if (query) filter.Search = query;
      if (activeFilters?.departments) filter.Departments = activeFilters.departments;
      if (activeFilters?.roles) filter.Roles = activeFilters.roles;
      if (showDisabled) filter.showDisabled = true;
      if (showDeleted) filter.showDeleted = true;

      const payload = {
        page: page || 1,
        orderBy: orderBy,
        groupBy,
        limit: l ?? limit.current,
        filter: JSON.stringify(filter),
        userManagement: true
      };

      if (facility?.id) payload.facilityId = facility.id;

      api.callGet(null, payload).then(({status, data}) => {
        if (status === 200 && data?.users) {
          const formatted = formatTableData(data.users);

          // Page Limit
          const {total: dataTotal, page: tPage, pages: tPages} = data;

          setCurrent(tPage);
          setPages(tPages);
          setTableData(prev => (page === 1 ? formatted : [...prev, ...formatted]));
          setTotal(dataTotal);
        }
      });
    },
    [
      api,
      formatTableData,
      facility,
      query,
      orderBy,
      groupBy,
      showDeleted,
      activeFilters,
      showDisabled
    ]
  );

  useEffect(() => {
    if (
      isMounted() &&
      prevOrderBy &&
      prevGroupBy &&
      (prevOrderBy !== orderBy || prevGroupBy !== groupBy)
    )
      getTableData(1);
  }, [isMounted, getTableData, prevOrderBy, prevGroupBy, orderBy, groupBy]);

  useEffect(() => {
    if (query || query === "") getTableData(1);
  }, [getTableData, query]);

  // Initial Load
  useEffect(() => {
    if (isMounted() && !tableData) {
      // estimation for number of cards to overflow
      const approxRows = Math.floor((window.innerHeight * 0.9 - 200) / 40);
      limit.current = approxRows;
      getTableData(1, approxRows);
    }
  }, [isMounted, tableData, getTableData]);

  // Load more on Scroll
  useEffect(() => {
    const modal = document.getElementById("modal");

    const handleScroll = () => {
      const maxScrollTop = modal.scrollHeight - modal.clientHeight;
      if (modal.scrollTop + 1 >= maxScrollTop && current < pages && !loading)
        getTableData(current + 1);
    };

    modal.addEventListener("scroll", handleScroll);

    return () => modal.removeEventListener("scroll", handleScroll);
  }, [current, pages, getTableData, loading]);

  useLayoutEffect(() => {
    if (showModalFilters || showModalExport || showModalDelete || showModalEdit || showModalAdd) {
      scrollPos.current = document.getElementById("modal").scrollTop;
      setShowModal(true);
    } else {
      scrollContainer.current.scrollTop = scrollPos.current;
      setShowModal(false);
      setItems([]);
    }
  }, [showModalFilters, showModalExport, showModalEdit, showModalDelete, showModalAdd]);

  const handleSearch = e => {
    setCurrent(1);
    setPages(1);
    setQuery(e.target.value);
  };

  const handleReload = () => {
    setCurrent(1);
    setPages(1);
    getTableData(1);
    setItems([]);
    reload();
  };

  if (showModal && showModalFilters)
    return (
      <ModalUserFilter
        visible={showModalFilters}
        setVisible={setShowModalFilters}
        filters={activeFilters}
        setFilters={setActiveFilters}
        showDisabled={showDisabled}
        setShowDisabled={setShowDisabled}
        showDeleted={showDeleted}
        setShowDeleted={setShowDeleted}
        hasBackButton
        goBack={() => setShowModalFilters(false)}
      />
    );

  if (showModal && showModalExport)
    return (
      <ModalExport
        visible={showModalExport}
        setVisible={setShowModalExport}
        getExport={exportParams =>
          api.callGet(null, {
            ...exportParams,
            facilityId: facility.id,
            filter: JSON.stringify({ExcludeCreator: true})
          })
        }
        hasBackButton
        goBack={() => setShowModalExport(false)}
      />
    );

  if (showModal && showModalAdd)
    return (
      <ModalFacilityUserAdd
        visible={showModalAdd}
        setVisible={setShowModalAdd}
        facilityId={facility.id}
        updateTable={handleReload}
        hasBackButton
        goBack={() => setShowModalAdd(false)}
      />
    );

  if (showModal && showModalEdit && selectedUser)
    return (
      <ModalFacilityUserEdit
        selected={selectedUser}
        visible={showModalEdit}
        setVisible={setShowModalEdit}
        updateTable={handleReload}
        hasBackButton
        goBack={() => setShowModalEdit(false)}
      />
    );

  if (showModal && showModalDelete)
    return (
      <ModalFacilityUserDelete
        selected={items}
        visible={showModalDelete}
        setVisible={setShowModalDelete}
        updateTable={handleReload}
        setRefreshFacility={setRefreshFacility}
        hasBackButton
        goBack={() => setShowModalDelete(false)}
      />
    );

  return (
    <Modal visible={visible} setVisible={setVisible} scrollRef={scrollContainer}>
      <TableWrapper>
        <TableMenuRow>
          <StyledInline>
            <Heading>Users</Heading>
            <InlineSpaced>
              <SearchWrapper>
                <SearchIcon>
                  <FontAwesomeIcon icon={faSearch} />
                </SearchIcon>
                <Search
                  name="search"
                  type="text"
                  placeholder="Search..."
                  onChange={e => handleSearch(e)}
                />
              </SearchWrapper>
              <TableMenu>
                {atLeast("admin") &&
                  (tableData?.length > 0 ||
                    (activeFilters && Object.keys(activeFilters)?.length > 0)) && (
                    <Option
                      type="button"
                      onClick={() => {
                        setShowModalFilters(!showModalFilters);
                      }}>
                      <Badge
                        count={
                          (activeFilters?.departments ? activeFilters.departments.length : 0) +
                          (activeFilters?.roles ? activeFilters.roles.length : 0) +
                          (showDisabled ? 1 : 0) +
                          (showDeleted ? 1 : 0)
                        }
                        offset="14px"
                        color={colors.heroGreen}
                      />
                      <FontAwesomeIcon icon={faFilter} />
                    </Option>
                  )}
                {roleCanAccessResource("user", "export") && tableData && tableData.length > 0 && (
                  <Option
                    type="button"
                    onClick={() => {
                      setShowModalExport(true);
                    }}>
                    <abbr title="Export">
                      <FontAwesomeIcon icon={faFileExport} />
                    </abbr>
                  </Option>
                )}
              </TableMenu>
            </InlineSpaced>
          </StyledInline>
        </TableMenuRow>
        <BaseTable
          headings={{
            publicId: {header: "", disabled: true},
            firstName: {header: "Name", disabled: false},
            userRoleId: {header: "Role", disabled: false},
            userCompanyId: {header: "Company", disabled: false},
            email: {header: "Email", disabled: false},
            phone: {header: "Phone", disabled: false}
          }}
          data={tableData}
          loading={loading}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          groupBy={groupBy}
          setGroupBy={setGroupBy}
          items={items}
          setItems={setItems}
        />
        <TableFooter>
          <Inline>
            <Text>
              <span>{total} total</span>
            </Text>
          </Inline>
        </TableFooter>
      </TableWrapper>
    </Modal>
  );
};

ModalViewAllFacilityUsers.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  reload: PropTypes.func.isRequired,
  setRefreshFacility: PropTypes.func.isRequired
};

// Style Overrides

const TableMenuRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${pad}px;
`;

const Option = styled(Button)`
  margin-left: ${pad}px;
`;

const ErrorSmall = styled(Small)`
  color: ${props => props.theme.error};
`;

const StyledInline = styled(Inline)`
  gap: ${pad}px;
  display: block;
  width: 100%;

  ${bp(1)} {
    display: flex;
  }
`;

const InlineSpaced = styled(Inline)`
  justify-content: space-between;
  width: 100%;
`;

export default ModalViewAllFacilityUsers;
