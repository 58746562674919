import React, {useContext, useEffect, useState} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";

// Contexts
import {SettingsContext} from "../../contexts/settings.js";

// Hooks
import useApi from "../../hooks/useApi.js";

// Utils
import {startExport} from "../../utils/export.js";
import {getFields} from "../../utils/builder.js";
import {prettyDateInUserTimezone} from "../../utils/helpers.js";

// Components
import Modal from "../../components/Modal.js";
import InputText from "../../components/form/InputText.js";
import InputCheck from "../../components/form/InputCheck.js";
import InputDate from "../../components/form/InputDate.js";
import InputCheckGroup from "../../components/form/InputCheckGroup.js";
import ModalExportPreview from "../../components/ModalExportPreview.js";

// Style
import {
  HeadingCenter,
  Button,
  Form,
  FormGroup,
  FormField,
  Label,
  Inline,
  ButtonLoader
} from "../../style/components/general.js";

const weatherOptions = [
  {name: "description", label: "DESCRIPTION"},
  {name: "tempAvg", label: "AVG TEMPERATURE"},
  {name: "tempMin", label: "MIN TEMPERATURE"},
  {name: "tempMax", label: "MAX TEMPERATURE"},
  {name: "rainfall", label: "RAINFALL"},
  {name: "cumulative", label: "CUMULATIVE RAINFALL"}
];

const ModalExport = ({visible, setVisible, checksheet, hasAddress}) => {
  const {allIds, byId} = checksheet.builder;

  const {settings} = useContext(SettingsContext);

  const options = getFields(allIds, byId).map(({name, ancestry}) => ({
    name,
    label: ancestry
  }));

  const {api: apiRecords} = useApi("checksheet-records", {suppress: {success: true, error: false}});

  const [loading, setLoading] = useState(false);
  const [previewLoading, setPreviewLoading] = useState(false);
  const [showModalPreview, setShowModalPreview] = useState(false);
  const [tableHeadings, setTableHeadings] = useState(null);
  const [tableData, setTableData] = useState(null);

  const initialValues = {
    exportName: "",
    range: false,
    help: false,
    start: "",
    end: "",
    fields: null
  };

  const schema = yup.object().shape({
    exportName: yup.string().required(),
    range: yup.boolean(),
    weather: yup.boolean(),
    unacceptable: yup.boolean(),
    start: yup
      .string()
      .nullable()
      .when("range", {
        is: val => !!val,
        then: () => yup.string().required()
      }),
    end: yup
      .string()
      .nullable()
      .when("range", {
        is: val => !!val,
        then: () => yup.string().required()
      }),
    help: yup.boolean(),
    weatherFields: yup.lazy((_v, ctx) => {
      if (ctx.parent.weather) {
        return yup.array().nullable().of(yup.string());
      }
      return yup.mixed().nullable();
    }),
    fields: yup.array().nullable().of(yup.string())
  });

  const form = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(schema)
  });
  const {watch, handleSubmit, reset, setValue} = form;
  const watchExportName = watch("exportName");
  const watchRange = watch("range");
  const watchWeather = watch("weather");

  useEffect(() => {
    if (!visible) reset({});
  }, [reset, visible]);

  useEffect(() => {
    setValue(
      "weatherFields",
      weatherOptions.map(({name}) => name)
    );
  }, [watchWeather, setValue]);

  const generateExportData = async (values, stripQuotes = false) => {
    const {start, end, fields, help, weatherFields, unacceptable} = values;
    const params = {
      facilityChecksheetId: checksheet.id,
      exportName: watchExportName,
      help: help || false,
      weather: watchWeather || false,
      unacceptable
    };

    if (start) params.start = start;
    if (end) params.end = end;

    if (fields?.length > 0) params["fields"] = fields.toString();
    if (watchWeather && weatherFields?.length > 0)
      params["weatherFields"] = weatherFields.toString();

    const exportData = await apiRecords.callPatch(null, params);
    const {status, data} = exportData;
    if (status === 200 && data?.rows?.length > 0) {
      const rows = [];
      data.rows.map(row => {
        const temp = {};
        row.map(({key, value}) => {
          // Handle extra quotes
          const stripped =
            stripQuotes && value?.match && value.match(/^".*"$/)
              ? value.slice(1, value.length - 1)
              : value;
          let formattedValue = stripped;
          // Format dates
          if (
            formattedValue?.match(
              /\d{4}[-](0?[1-9]|1[012])[-](0?[1-9]|[12][0-9]|3[01])T([0-1]?[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9](.\d{6})?/
            )
          )
            formattedValue = prettyDateInUserTimezone(
              formattedValue,
              settings?.timezone,
              "YYYY-MM-DD hh:mm A"
            );
          temp[key] = formattedValue;
        });
        rows.push(temp);
      });

      return rows;
    }
    return null;
  };

  const handlePreview = async () => {
    setPreviewLoading(true);
    const values = watch();
    const data = await generateExportData(values, true);

    if (data) {
      const headers = {};
      Object.keys(data[0]).map(key => {
        headers[key] = {header: key, disabled: true};
      });
      setTableHeadings(headers);
      setTableData(data);
      setShowModalPreview(true);
    }

    setPreviewLoading(false);
  };

  const createExport = async values => {
    setLoading(true);
    const data = await generateExportData(values);

    if (data) {
      startExport("csv", values.exportName, data);
      reset({...initialValues});
      setVisible(false);
    }
    setLoading(false);
  };

  if (showModalPreview)
    return (
      <ModalExportPreview
        visible={visible}
        setVisible={setVisible}
        goBack={() => setShowModalPreview(false)}
        exportName={watchExportName}
        tableHeadings={tableHeadings}
        tableData={tableData}
        handleExport={() => {
          startExport("csv", watch("exportName"), tableData);
          setVisible(false);
          reset({...initialValues});
        }}
      />
    );

  return (
    <Modal visible={visible} setVisible={setVisible}>
      <HeadingCenter>Export CSV</HeadingCenter>

      <FormProvider {...form}>
        <Form onSubmit={handleSubmit(createExport)}>
          <FormGroup>
            <FormField>
              <InputText name="exportName" label="Please provide a name for the export." required />
            </FormField>

            <FormField>
              <InputCheckGroup name="fields" label="Target Fields" options={options} all />
            </FormField>

            <FormField>
              <InputCheck name="help">Include selected help information in export?</InputCheck>
            </FormField>

            <FormField>
              <InputCheck name="unacceptable">
                Include unacceptable parameter explanations in export?
              </InputCheck>
            </FormField>

            <FormField>
              <InputCheck name="range">Specify a date range?</InputCheck>
            </FormField>
            {watchRange && (
              <FormField>
                <Label bold>Please provide a range for data in export.</Label>
                <DateRange>
                  from&nbsp;
                  <InputDate name="start" />
                  &nbsp;to&nbsp;
                  <InputDate name="end" />
                </DateRange>
              </FormField>
            )}

            {hasAddress && (
              <FormField>
                <InputCheck name="weather">
                  Include facility weather information in export?
                </InputCheck>
              </FormField>
            )}
            {watchWeather && (
              <FormField>
                <InputCheckGroup
                  name="weatherFields"
                  label="Target Weather Fields"
                  options={weatherOptions}
                  all
                />
              </FormField>
            )}
          </FormGroup>
          <Inline>
            <Button type="submit" loading={loading ? 1 : 0}>
              Export {loading && <ButtonLoader />}
            </Button>
            <Button type="button" onClick={handlePreview} loading={previewLoading ? 1 : 0}>
              Preview {previewLoading && <ButtonLoader />}
            </Button>
          </Inline>
        </Form>
      </FormProvider>
    </Modal>
  );
};

ModalExport.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  checksheet: PropTypes.objectOf(PropTypes.any).isRequired,
  hasAddress: PropTypes.bool
};

ModalExport.defaultProps = {
  hasAddress: false
};

// Style Overrides
const DateRange = styled(Inline)`
  display: flex;

  div {
    max-width: 150px;
  }
`;

export default ModalExport;
