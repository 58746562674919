import React, {useState, useEffect, useContext, useMemo, useCallback} from "react";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {faClose} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

// Contexts
import {AuthContext} from "../../contexts/auth.js";

// Hooks
import useApi from "../../hooks/useApi.js";
import useMountedState from "../../hooks/useMountedState.js";

// Components
import Modal from "../../components/Modal.js";
import SearchSelect from "../../components/SearchSelect.js";
import {
  InputText,
  InputSelect,
  InputPhoneGroup,
  InputError,
  InputCheck
} from "../../components/form/FormInputs.js";

// Style
import {pad} from "../../style/components/variables.js";
import {voice} from "../../style/components/typography.js";
import {
  HeadingCenter,
  Form,
  FormGroup,
  FormField,
  ButtonFull,
  ButtonLoader,
  Pill,
  Button,
  Abbr,
  Label
} from "../../style/components/general.js";

const ModalManage = ({
  visible,
  setVisible,
  hasBackButton = false,
  goBack,
  facilityId,
  user,
  updateTable,
  invite = true
}) => {
  const isMounted = useMountedState();

  const {currentUser, types, roles, departments} = useContext(AuthContext);

  const {api: apiUser, loading} = useApi("users");
  const {api: apiCompany} = useApi("companies");

  const [userTypes, setUserTypes] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [companyResults, setCompanyResults] = useState([]);

  const schema = yup.object().shape({
    firstName: yup.string().required("Please provide first name."),
    lastName: yup.string().required("Please provide last name."),
    email: yup.string().email("Invalid email address").required("Please provide email."),
    userRoleId: yup
      .mixed()
      .nullable()
      .when("isActive", {
        is: val =>
          roles &&
          roles.length > 0 &&
          ((invite && (user.isAccepted || !!val)) || (!invite && !!val)),
        then: () => yup.string().required("Please provide user role."),
        else: () => yup.mixed().nullable()
      }),
    company: yup.string().required("Please provide company"),
    department: yup
      .mixed()
      .nullable()
      .when(["isActive", "includeDepartment"], {
        is: (isActive, includeDepartment) => !isActive || includeDepartment,
        then: () => yup.string().required("Please provide department"),
        else: () => yup.mixed().nullable()
      }),
    phones: yup
      .mixed()
      .nullable()
      .when("isActive", {
        is: val => !!val,
        then: () =>
          yup.array().of(
            yup.object().shape({
              number: yup
                .string()
                .matches(/^\(\d{3}\)\s\d{3}-\d{4}/i, {
                  message: "Please provide valid phone #.",
                  excludeEmptyString: true
                })
                .required("Phone number is required."),
              extension: yup.string().nullable(),
              type: yup.string().required("Type is required."),
              primary: yup.bool()
            })
          ),
        else: () => yup.mixed().nullable()
      })
  });

  const defaultValues = useMemo(
    () => ({
      firstName: user?.firstName,
      lastName: user?.lastName,
      email: user?.email,
      userTypeId: user?.type?.id,
      userRoleId: user?.role?.id,
      company: user?.company?.name,
      watchIncludeDepartment: !!user?.department?.name,
      department: user?.department?.name,
      phones:
        user?.phones?.length > 0
          ? user.phones
          : [
              {
                id: "",
                number: "",
                extension: "",
                type: "mobile",
                primary: false
              }
            ],
      isActive: (!user && invite) || (user?.isInvited && !user?.isAccepted) || user?.isActive
    }),
    [user, invite]
  );

  const form = useForm({
    defaultValues,
    resolver: yupResolver(schema)
  });
  const {
    watch,
    handleSubmit,
    reset,
    setValue,
    formState: {submitCount, errors}
  } = form;

  const watchRole = watch("userRoleId");
  const watchCompany = watch("company");
  const watchIncludeDepartment = watch("includeDepartment");
  const watchIsActive = watch("isActive");

  useEffect(() => {
    if (isMounted() && types) {
      const filtered = types.filter(userType => userType.id <= currentUser.type.id);
      setUserTypes(filtered);
    }
  }, [isMounted, types, currentUser]);

  useEffect(() => {
    if (isMounted()) {
      apiCompany.callGet().then(({data, status}) => {
        if (status === 200 && data) setCompanies(data);
      });
    }
  }, [apiCompany, isMounted]);

  const searchCompanies = query => {
    if (query)
      setCompanyResults(
        companies
          .filter(c => c.name.toLowerCase().includes(query.toLowerCase()))
          .map(c => ({name: c.id, label: c.name}))
      );
    else setCompanyResults(companies.map(c => ({name: c.id, label: c.name})));
  };

  const addUser = useCallback(
    ({
      email,
      firstName,
      lastName,
      phones,
      userTypeId,
      userRoleId,
      company,
      department,
      isActive
    }) => {
      const params = {
        facilityId,
        email,
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        userTypeId,
        company,
        department,
        isActive
      };

      if (!user?.publicId && isActive && invite) params.invite = true;

      if (userRoleId && isActive) params.userRoleId = userRoleId;
      else params.userRoleId = null;

      if (phones?.length > 0 && phones[0].number) params.phones = phones;

      const success = user?.publicId ? 200 : 201;
      const request = user?.publicId
        ? apiUser.callPut(user.publicId, params)
        : apiUser.callPost(params);
      request.then(({status}) => {
        if (status === success) {
          setVisible(false);
          updateTable();
        }
      });
    },
    [apiUser, facilityId, setVisible, updateTable, user, invite]
  );

  useEffect(() => {
    if (!visible) reset({});
  }, [reset, visible]);

  return (
    <Modal
      visible={visible}
      setVisible={setVisible}
      maxWidth="570px"
      hasBackButton={hasBackButton}
      goBack={goBack}>
      <ModalTitle>
        {user ? "Edit" : "Add"} {invite ? "User" : "Contact"}
      </ModalTitle>

      <FormProvider {...form}>
        <Form onSubmit={handleSubmit(addUser)} noValidate>
          <FormGroup>
            <FormField>
              <InputText name="firstName" label="First Name" required />
            </FormField>
            <FormField>
              <InputText name="lastName" label="Last Name" required />
            </FormField>
            <FormField>
              <InputText name="email" label="Email" required />
            </FormField>
            <FormField>
              <InputPhoneGroup name="phones" label="Phone(s)" limit={3} required={watchIsActive} />
            </FormField>

            <FormField>
              {watchCompany ? (
                <>
                  <Label bold>COMPANY *</Label>
                  <SelectedContainer data-testid="addField.tag-selected">
                    <Abbr>{watchCompany}</Abbr>
                    &nbsp;
                    <IconButton
                      onClick={() => setValue("company", null, {shouldValidate: !!submitCount})}>
                      <FontAwesomeIcon icon={faClose} />
                    </IconButton>
                  </SelectedContainer>
                </>
              ) : (
                <SearchSelect
                  label="COMPANY *"
                  results={companyResults}
                  setResults={setCompanyResults}
                  search={searchCompanies}
                  add={selected => {
                    const selectedName = typeof selected === "string" ? selected : selected.label;
                    setValue("company", selectedName, {shouldValidate: !!submitCount});
                  }}
                  showAll
                  allowNew
                />
              )}
              <InputError name="company" errors={errors} />
            </FormField>

            {user && (
              <FormField>
                <InputCheck
                  name="isActive"
                  label="Activate or Disable Account"
                  active={watchIsActive}>
                  <AccountStatus active={watchIsActive}>
                    {watchIsActive ? "Account Active" : "Account Inactive!"}
                  </AccountStatus>
                </InputCheck>
              </FormField>
            )}

            {/* show roles input when creating / editing a user, except when deactivating a user that hasn't accepted yet */}
            {/* for contacts, only show role input when user account is being activated */}
            {((invite && (user?.isAccepted || watchIsActive)) || (!invite && watchIsActive)) && (
              <StyledFormField>
                {roles?.length > 0 && (
                  <FormField>
                    <InputSelect
                      name="userRoleId"
                      label="Role"
                      placeholder="Assign role..."
                      options={[
                        ...roles.map(({id, label}) => ({
                          name: id,
                          label
                        }))
                      ]}
                    />
                  </FormField>
                )}
                {roles?.length === 0 &&
                  userTypes?.length &&
                  (!roles || roles.length === 0 || `${watchRole}` === "-1") && (
                    <FormField>
                      <InputSelect
                        name="userTypeId"
                        label={
                          `${watchRole}` === "-1"
                            ? "User Type (must be provided when role is omitted)"
                            : "User Type"
                        }
                        options={userTypes.map(({id, name}) => ({
                          name: id,
                          label: name.toUpperCase()
                        }))}
                      />
                    </FormField>
                  )}
              </StyledFormField>
            )}

            {invite && (watchIsActive || user?.isAccepted) && (
              <FormField>
                <InputCheck name="includeDepartment">Provide contact type?</InputCheck>
              </FormField>
            )}
            {((!invite && !user) ||
              (invite && user && !user.isAccepted && !watchIsActive) ||
              watchIncludeDepartment) && (
              <FormField>
                <InputSelect name="department" label="Contact Type" options={departments} />
              </FormField>
            )}
          </FormGroup>
          <ButtonFull type="submit" loading={loading ? 1 : 0}>
            {user ? "Edit" : "Add"}&nbsp;{invite ? "User" : "Contact"}
            {loading && <ButtonLoader />}
          </ButtonFull>
        </Form>
      </FormProvider>
    </Modal>
  );
};

ModalManage.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  hasBackButton: PropTypes.bool,
  goBack: PropTypes.func,
  facilityId: PropTypes.number,
  user: PropTypes.objectOf(PropTypes.any),
  updateTable: PropTypes.func.isRequired,
  invite: PropTypes.bool
};

// Style Overrides
const ModalTitle = styled(HeadingCenter)`
  margin: ${pad}px 0;
`;

const StyledFormField = styled(FormField)`
  margin-top: -${pad}px;
  margin-bottom: ${pad}px;
`;

const SelectedContainer = styled(Pill)`
  width: min-content;
  height: min-content;
  max-width: 100%;
  margin: ${pad}px ${pad}px 0 0;
  color: ${({theme}) => theme.tertiary};
`;

const IconButton = styled(Button)`
  ${voice.quiet};
  background-color: transparent;
  width: min-content;
  padding: 0;

  svg {
    fill: ${({theme}) => theme.tertiary};
  }
`;

const AccountStatus = styled.span`
  ${({active, theme}) =>
    !active &&
    css`
      color: ${theme.error};
    `}
`;

export default ModalManage;
