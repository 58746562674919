import React, {useState, useEffect, useCallback, useRef} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faEye, faEyeSlash, faMinus} from "@fortawesome/free-solid-svg-icons";

// Hooks
import useMountedState from "../../hooks/useMountedState.js";
import useApi from "../../hooks/useApi.js";

// Utils
import {toTitleCase} from "../../utils/helpers.js";

// Components
import Modal from "../../components/Modal.js";
import BaseTable from "../../components/BaseTable.js";
import InputCheck from "../../components/form/InputCheck.js";

// Style
import {heroTheme, pad} from "../../style/components/variables.js";
import {
  Form,
  FormGroup,
  ButtonFull,
  Button,
  Inline,
  Heading
} from "../../style/components/general.js";

const ModalUserAssign = ({visible, setVisible, hasBackButton, goBack, users, setUsers}) => {
  const isMounted = useMountedState();

  const schema = yup.object().shape({
    facilities: yup.mixed().not([false], "Please select at least one facility")
  });

  const form = useForm({
    defaultValues: {facilities: []},
    resolver: yupResolver(schema)
  });
  const {setValue, handleSubmit, reset, watch} = form;

  const {api: apiFacilities} = useApi("facilities");
  const {api: apiFacilityUsers} = useApi("facility-users");

  const [showArchived, setShowArchived] = useState(false);
  const [facilities, setFacilities] = useState(undefined);
  const [userFacilities, setUserFacilities] = useState();
  const [loading, setLoading] = useState(false);

  const checkboxes = useRef();

  const watchFacilities = watch("facilities");

  const getFacilities = useCallback(
    (show = false) =>
      apiFacilities.callGet(null, {withDeleted: show, getAll: true}).then(({data, status}) => {
        if (status === 200)
          setFacilities(
            data.map(facility => ({
              label: `${facility.name} ${toTitleCase(facility.type)}`,
              value: facility.id,
              isDeleted: facility.isDeleted
            }))
          );
      }),
    [apiFacilities]
  );

  const getFacilityUsers = useCallback(() => {
    setLoading(true);

    apiFacilityUsers
      .callGet("", {ids: users.map(({publicId}) => publicId).join(",")})
      .then(({status, data}) => {
        if (status === 200 && data) {
          setUserFacilities(data);
          const intersection = [];
          Object.keys(data).map(publicId => {
            data[publicId].forEach(facility => {
              if (!intersection.includes(`${facility.id}`)) intersection.push(`${facility.id}`);
            });
          });
          setValue("facilities", intersection);
        }
      })
      .finally(() => setLoading(false));
  }, [apiFacilityUsers, users, setValue]);

  // Initial load - get all facilities
  useEffect(() => {
    if (isMounted()) getFacilities();
  }, [isMounted, getFacilities]);

  useEffect(() => {
    if (isMounted() && users.length > 0) getFacilityUsers();
  }, [isMounted, users, getFacilityUsers]);

  const assignUsers = selected => {
    const formatted = users.map(user => user.email);
    apiFacilityUsers
      .callPost({
        users: formatted,
        facilities: selected.facilities.map(id => parseInt(id, 10))
      })
      .then(() => {
        setVisible(false);
        reset({});
        setUsers([]);
      });
  };

  const handleSelectAll = () => {
    const inputs = checkboxes.current.querySelectorAll("input");
    const fieldValues = [];
    if (!watchFacilities || (watchFacilities && watchFacilities.length < facilities.length))
      Array.from(inputs).forEach(input => {
        fieldValues.push(input.value);
      });

    setValue("facilities", fieldValues);
  };

  return (
    <Modal
      visible={visible}
      setVisible={setVisible}
      hasBackButton={hasBackButton}
      goBack={goBack}
      maxWidth="90vw">
      <Menu>
        <Heading>Assign Users</Heading>
        <Button
          type="button"
          onClick={() => {
            getFacilities(!showArchived);
            setShowArchived(prev => !prev);
          }}>
          <FontAwesomeIcon icon={showArchived ? faEyeSlash : faEye} />
          &nbsp;Archived
        </Button>
      </Menu>

      <FormProvider {...form}>
        <Form onSubmit={handleSubmit(assignUsers)} ref={checkboxes}>
          <BaseTable
            headings={{
              member: {header: "Member", disabled: true},
              ...users.reduce((acc, {publicId, firstName}) => {
                acc[publicId] = {header: firstName, disabled: true};
                return acc;
              }, {})
            }}
            data={
              userFacilities && facilities?.length > 0
                ? facilities.map(({label, value, isDeleted}) => ({
                    isDeleted,
                    member: (
                      <InputCheck name="facilities" value={value}>
                        {label}
                        {isDeleted ? " (ARCHIVED)" : ""}
                      </InputCheck>
                    ),
                    ...users.reduce((acc, {publicId}) => {
                      acc[publicId] = (
                        <Icon
                          icon={
                            Object.keys(userFacilities)?.includes(publicId) &&
                            userFacilities[publicId]?.find(
                              ({name, type}) =>
                                `${name.toLowerCase()} ${type.toLowerCase()}` ===
                                label.toLowerCase()
                            )
                              ? faCheck
                              : faMinus
                          }
                          color={
                            Object.keys(userFacilities)?.includes(publicId) &&
                            userFacilities[publicId]?.find(
                              ({name, type}) =>
                                `${name.toLowerCase()} ${type.toLowerCase()}` ===
                                label.toLowerCase()
                            )
                              ? heroTheme.success
                              : heroTheme.secondary
                          }
                        />
                      );
                      return acc;
                    }, {})
                  }))
                : null
            }
            loading={loading || facilities === undefined}
          />
          {!loading && facilities !== undefined && (
            <Button type="button" onClick={handleSelectAll}>
              {watchFacilities?.length !== facilities?.length ? "Select All" : "Deselect All"}
            </Button>
          )}
          <br />
          <FormGroup>
            <ButtonFull type="submit">{users.length === 1 ? "Reassign" : "Assign"}</ButtonFull>
          </FormGroup>
        </Form>
      </FormProvider>
    </Modal>
  );
};

ModalUserAssign.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  hasBackButton: PropTypes.bool,
  goBack: PropTypes.func,
  users: PropTypes.arrayOf(PropTypes.any).isRequired,
  setUsers: PropTypes.func.isRequired
};

// Style Overrides
const Menu = styled(Inline)`
  width: 100%;
  justify-content: space-between;
  padding: ${pad}px 0;
`;

const Icon = styled(FontAwesomeIcon)`
  fill: ${({color}) => color};
`;

export default ModalUserAssign;
