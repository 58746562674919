import React, {useCallback, useEffect, useMemo, useState} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit} from "@fortawesome/free-solid-svg-icons";

// Utils
import useApi from "../../hooks/useApi.js";
import useMountedState from "../../hooks/useMountedState.js";

// Components
import Modal from "../../components/Modal.js";

// Style
import {pad} from "../../style/components/variables.js";
import {Heading, Inline, Loader, NotLoaded, Text} from "../../style/components/general.js";

const ModalTemplateInstances = ({visible, setVisible, template}) => {
  const isMounted = useMountedState();
  const [checksheets, setChecksheets] = useState(null);
  const {api, loading} = useApi("checksheets");

  const getComponentLink = useCallback(
    (checksheetId, componentName) =>
      `/facilities/${checksheets[checksheetId].slug}/checksheets/${checksheetId}/edit#${componentName}`,
    [checksheets]
  );

  useEffect(() => {
    if (isMounted() && template.instances)
      api.callGet(null, {ids: Object.keys(template.instances)}).then(response => {
        if (response.status === 200)
          setChecksheets(
            Object.fromEntries(response.data.map(checksheet => [`${checksheet.id}`, checksheet]))
          );
      });
  }, [api, isMounted, template]);

  const renderContent = useMemo(() => {
    if (loading || !checksheets) return null;
    const rendered = Object.keys(template.instances)
      .flatMap(checksheetId =>
        template.instances[checksheetId].flatMap(componentName => {
          if (!checksheets[checksheetId]) return null;
          const component = checksheets[checksheetId].builder.byId[componentName];
          if (!component) return null;
          const group = checksheets[checksheetId].builder.byId[component.parentName];
          if (!group) return null;

          return (
            <StyledInline key={`${checksheetId}-${componentName}`}>
              <Checksheet>{checksheets[checksheetId].name}</Checksheet>&nbsp;{">"}&nbsp;
              <Group>{group.label}</Group>&nbsp;{">"}&nbsp;
              <Component>{component.label} </Component>
              <span>
                <Edit target="_blank" href={getComponentLink(checksheetId, componentName)}>
                  <FontAwesomeIcon icon={faEdit} />
                </Edit>
              </span>
            </StyledInline>
          );
        })
      )
      .filter(val => val !== null);

    if (rendered.length > 0) return rendered;
    return <Text>No Instances Found</Text>;
  }, [checksheets, getComponentLink, loading, template]);

  return (
    <Modal
      testId="facility.addComponent"
      visible={visible}
      setVisible={setVisible}
      width="mincontent">
      <ModalTitle>Associated Components</ModalTitle>
      {!loading ? (
        renderContent
      ) : (
        <NotLoaded>
          <Loader />
        </NotLoaded>
      )}
    </Modal>
  );
};

ModalTemplateInstances.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  template: PropTypes.objectOf(PropTypes.any)
};

ModalTemplateInstances.defaultProps = {
  template: null
};

// Style Overrides
const ModalTitle = styled(Heading)`
  margin: ${pad}px 0;
  color: ${props => props.theme.component};
`;

const Group = styled.span`
  color: ${props => props.theme.group};
  white-space: nowrap;
`;

const Component = styled.span`
  color: ${props => props.theme.component};
  white-space: nowrap;
`;

const Checksheet = styled.span`
  white-space: nowrap;
`;

const Edit = styled.a`
  border: 0;
  margin-left: ${pad * 2}px;
  background: 0;

  svg {
    fill: ${props => props.theme.secondary};
  }
`;

const StyledInline = styled(Inline)`
  margin-bottom: ${pad}px;
  height: min-content;
`;

export default ModalTemplateInstances;
