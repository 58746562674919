import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {FormProvider, useFieldArray, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";

// Hooks
import useMountedState from "../../hooks/useMountedState.js";

// Utils
import {initialFieldValues, fieldValidationSchema} from "../../utils/builder.js";

// Components
import Modal from "../../components/Modal.js";
import FieldBuilder from "../../components/FieldBuilder.js";

// Style
import {pad} from "../../style/components/variables.js";
import {Form, ButtonFull, HeadingCenter, Button} from "../../style/components/general.js";

const AddField = ({
  visible = false,
  setVisible,
  addFields,
  builder,
  parent,
  units = [],
  hasBackButton = false,
  goBack
}) => {
  const isMounted = useMountedState();

  const [initialValues, setInitialValues] = useState({fields: [{...initialFieldValues}]});

  const schema = yup.object().shape({
    fields: yup.array().of(fieldValidationSchema(parent?.name, builder))
  });
  const form = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(schema)
  });
  const {watch, handleSubmit, reset, control} = form;
  const {insert, remove, append} = useFieldArray({control, name: "fields"});

  const fields = watch("fields");

  const [restrictedError, setRestrictedError] = useState(null);
  const [acceptableError, setAcceptableError] = useState(null);
  const [rangeError, setRangeError] = useState(null);
  const [conditionError, setConditionError] = useState(null);

  const exists = val => val || val === 0;

  const handleAddField = values => {
    let shouldReturn = false;
    const tempRestrictedErrors = [];
    const tempAcceptableErrors = [];
    const tempRangeErrors = [];
    const tempConditionErrors = [];
    values.fields.forEach(field => {
      if (field.hasARange && !exists(field.aMin) && !exists(field.aMax)) {
        tempAcceptableErrors.push("Either a min or max must be provided.");
        shouldReturn = true;
      } else tempAcceptableErrors.push(null);

      if (field.hasRange && !exists(field.min) && !exists(field.max)) {
        tempRestrictedErrors.push("Either a min or max must be provided.");
        shouldReturn = true;
      } else tempRestrictedErrors.push(null);

      if (field.type === "range" && !(exists(field.rangeMin) && exists(field.rangeMax))) {
        tempRangeErrors.push("Min and max must be provided.");
        shouldReturn = true;
      } else tempRangeErrors.push(null);

      if (field.hasAlert && !exists(field.alertCondition)) {
        tempConditionErrors.push("Condition must be provided.");
        shouldReturn = true;
      }

      if (field.units && field.units.includes("Other: "))
        field.units = field.units.replace("Other: ", "");

      if (field.phone && field.phone.number === "") delete field.phone;
    });

    setRestrictedError(tempRestrictedErrors);
    setAcceptableError(tempAcceptableErrors);
    setRangeError(tempRangeErrors);
    setConditionError(tempConditionErrors);

    if (shouldReturn) return;

    const fieldList = [];
    values["fields"].map(field => {
      fieldList.push({...field, parentName: parent?.name || ""});
    });

    addFields(fieldList);
    reset({fields: [{...initialFieldValues}]});
    if (hasBackButton && goBack) goBack();
    else setVisible(false);
  };

  useEffect(() => {
    if (isMounted() && fields && fields.length === 0) append(initialFieldValues);
  }, [append, fields, isMounted]);

  useEffect(() => {
    if (fields) setInitialValues(fields);
  }, [fields]);

  return (
    <Modal visible={visible} setVisible={setVisible} hasBackButton={hasBackButton} goBack={goBack}>
      <ModalTitle>Custom Field</ModalTitle>
      <FormProvider {...form}>
        <Form onSubmit={handleSubmit(handleAddField)} noValidate>
          {fields?.map((field, idx) => (
            // eslint-disable-next-line react/no-array-index-key
            <CustomFieldWrapper key={idx}>
              <FieldBuilder
                name={`fields.${idx}`}
                field={{...field, parentName: parent?.name || ""}}
                restrictedError={restrictedError ? restrictedError[idx] : null}
                acceptableError={acceptableError ? acceptableError[idx] : null}
                rangeError={rangeError ? rangeError[idx] : null}
                conditionError={conditionError ? conditionError[idx] : null}
                units={units}>
                <Menu>
                  {fields.length > 1 && (
                    <Edit type="button" onClick={() => remove(idx)}>
                      <FontAwesomeIcon icon={faMinus} />
                    </Edit>
                  )}
                  <Edit type="button" onClick={() => insert(idx + 1, initialFieldValues)}>
                    <FontAwesomeIcon icon={faPlus} />
                  </Edit>
                </Menu>
              </FieldBuilder>
            </CustomFieldWrapper>
          ))}

          <Submit type="submit" data-testid="addField.submit">
            Add
          </Submit>
        </Form>
      </FormProvider>
    </Modal>
  );
};

AddField.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  addFields: PropTypes.func.isRequired,
  builder: PropTypes.objectOf(PropTypes.any).isRequired,
  parent: PropTypes.objectOf(PropTypes.any),
  units: PropTypes.arrayOf(PropTypes.string),
  hasBackButton: PropTypes.bool,
  goBack: PropTypes.func
};

// Style Overrides
const ModalTitle = styled(HeadingCenter)`
  margin: ${pad}px 0;
  color: ${({theme}) => theme.field};
`;

const Submit = styled(ButtonFull)`
  margin-bottom: ${pad}px;
  margin-top: ${pad * 2}px;
`;

const Menu = styled.div`
  display: inline-flex;
  position: relative;
  margin: ${pad}px 0 ${pad}px auto;
  right: 0;
`;

const Edit = styled(Button)`
  &:first-child {
    margin-right: ${pad}px;
    padding-left: 11.5px;
    padding-right: 11.5px;
  }
`;

const CustomFieldWrapper = styled.div`
  width: 100%;
  position: relative;
  margin-top: ${pad}px;
`;

export default AddField;
