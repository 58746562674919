import React, {useContext} from "react";

// Utils
import {AuthContext} from "../contexts/auth.js";

// Components
import TableUsers from "./users/TableUsers.js";
import TableUserContacts from "./users/TableUserContacts.js";

// Style
import {Page} from "../style/components/general.js";

const Users = () => {
  const {currentUser} = useContext(AuthContext);
  return (
    currentUser && (
      <Page>
        <TableUsers />
        <TableUserContacts />
      </Page>
    )
  );
};

export default Users;
