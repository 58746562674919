import React, {useEffect, useMemo, useRef, useState} from "react";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";
import {useFormContext} from "react-hook-form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faClose,
  faGreaterThan,
  faGreaterThanEqual,
  faLessThan,
  faLessThanEqual
} from "@fortawesome/free-solid-svg-icons";

// Hooks
import useApi from "../hooks/useApi.js";

// Utils
import {initialFieldValues} from "../utils/builder.js";
import {exists} from "../utils/helpers.js";

// Components
import SearchSelect from "./SearchSelect.js";
import Help from "./Help.js";
import {
  InputFieldType,
  InputText,
  InputRadioGroup,
  InputNumber,
  InputCheck,
  InputTextGroup,
  InputSelect,
  InputSwitch,
  InputTextArea,
  InputPhone,
  InputLimitGroup,
  InputCheckGroup,
  InputError
} from "./form/FormInputs.js";

// Style
import {voice} from "../style/components/typography.js";
import {pad, border, radius} from "../style/components/variables.js";
import {
  FormGroup,
  FormField,
  Label,
  Text,
  Error,
  Button,
  Abbr,
  Pill,
  Inline
} from "../style/components/general.js";

const FieldBuilder = ({
  name,
  field,
  children,
  editElement = false,
  restrictedError,
  acceptableError,
  // rangeError,
  conditionError,
  units = []
}) => {
  const exclude = excluded => !excluded.includes(field.type);
  const include = included => included.includes(field.type);

  const {api: apiFieldTags} = useApi("field-tags", {suppress: {success: true, error: true}});

  const oldType = useRef(field.type);

  const form = useFormContext();

  const {
    watch,
    reset,
    setValue,
    formState: {errors, submitCount}
  } = form;

  const watchFieldName = watch(name ? `${name}.name` : "name");
  const watchLabel = watch(name ? `${name}.label` : "label");
  const watchFieldType = watch(name ? `${name}.type` : "type");
  const watchUnits = watch(name ? `${name}.units` : "units");
  const watchFieldTag = watch(name ? `${name}.tag` : "tag");
  const watchStrictMax = watch(name ? `${name}.strictMax` : "strictMax");
  const watchStrictMin = watch(name ? `${name}.strictMin` : "strictMin");
  const watchAMin = watch(name ? `${name}.aMin` : "aMin");
  const watchAMax = watch(name ? `${name}.aMax` : "aMax");
  const watchHasRange = watch(name ? `${name}.hasRange` : "hasRange");
  const watchRMin = watch(name ? `${name}.min` : "min");
  const watchRMax = watch(name ? `${name}.max` : "max");

  const defaultRestrictedRangeException = useMemo(() => {
    if (exists(watchRMin) && exists(watchRMax))
      return `Leaving this blank defaults to: 'Value must be > ${watchRMin}' or 'Value must be < ${watchRMax}'`;
    if (exists(watchRMin)) return `Leaving this blank defaults to: 'Value must be > ${watchRMin}'`;
    if (exists(watchRMax)) return `Leaving this blank defaults to: 'Value must be < ${watchRMax}'`;
    return "Override default violation message";
  }, [watchRMin, watchRMax]);

  const [unitResults, setUnitResults] = useState();
  const [fieldTags, setFieldTags] = useState();
  const [tagResults, setTagResults] = useState();

  useEffect(() => {
    if (!fieldTags)
      apiFieldTags.callGet().then(({status, data}) => {
        if (status && data) {
          setFieldTags(data);
          setTagResults(data);
        }
      });
  }, [fieldTags, apiFieldTags]);

  useEffect(() => {
    if (oldType.current && watchFieldType !== oldType.current) {
      oldType.current = watchFieldType;

      if (editElement) {
        const newFieldState = {
          ...initialFieldValues,
          name: watchFieldName,
          label: watchLabel,
          type: watchFieldType,
          tag: watchFieldTag
        };
        reset({...newFieldState});
      } else {
        const all = watch();
        const allFields = watch("fields");
        const newFieldState = {
          ...initialFieldValues,
          label: watchLabel,
          type: watchFieldType,
          tag: watchFieldTag
        };
        allFields.splice(parseInt(name.split(".")[1], 10), 1, newFieldState);
        reset({...all, fields: [...allFields]});
      }
    }
  }, [watchFieldType, name, reset, watch, editElement, watchLabel, watchFieldName, watchFieldTag]);

  useEffect(() => {
    const aRangeComment = watch(name ? `${name}.aRangeComment` : "aRangeComment");
    if (aRangeComment === null || aRangeComment === undefined)
      setValue(name ? `${name}.aRangeComment` : "aRangeComment", true);
  }, [name, setValue, watch]);

  useEffect(() => {
    if (!watchHasRange) {
      setValue(name ? `${name}.min` : "min", null);
      setValue(name ? `${name}.max` : "max", null);
    }
  }, [name, setValue, watchHasRange]);

  const searchUnits = query => {
    if (!query) setUnitResults(units);
    setUnitResults(units.filter(u => u.toLowerCase().includes(query.toLowerCase())));
  };

  return (
    <Builder data-testid="field.builder">
      <FormField>
        <InputText
          name={name ? `${name}.label` : "label"}
          label="Field Name"
          placeholder="Please provide a name to represent the value collected."
          testId="addField.label"
          required
        />
      </FormField>
      <FormField>
        <InputFieldType name={name ? `${name}.type` : "type"} />
      </FormField>
      <FormField>
        {!watchFieldTag ? (
          <SearchWrapper>
            <SearchSelect
              testId="addField.tags"
              label="Tag"
              placeholder="Search tags..."
              results={tagResults}
              setResults={setTagResults}
              search={query => {
                if (!query) setTagResults(fieldTags);
                else
                  setTagResults(
                    fieldTags?.filter(({name: tagName}) =>
                      tagName.toLowerCase().includes(query.toLowerCase())
                    )
                  );
              }}
              add={selected => {
                const selectedName = typeof selected === "string" ? selected : selected.name;
                if (!fieldTags?.map(({name: tagName}) => tagName).includes(selectedName)) {
                  setFieldTags(prev =>
                    prev ? [...prev, {name: selectedName}] : [{name: selectedName}]
                  );
                  apiFieldTags.callPost({name: selectedName});
                }
                setValue(name ? `${name}.tag` : "tag", selectedName);
              }}
              showAll
              allowNew
            />
          </SearchWrapper>
        ) : (
          <SelectedContainer data-testid="addField.tag-selected">
            <Abbr>{watchFieldTag}</Abbr>
            &nbsp;
            <IconButton
              onClick={() =>
                setValue(name ? `${name}.tag` : "tag", null, {shouldValidate: !!submitCount})
              }>
              <FontAwesomeIcon icon={faClose} />
            </IconButton>
          </SelectedContainer>
        )}
        <InputError name={name ? `${name}.tag` : "tag"} errors={errors} />
      </FormField>
      <FormField>
        <InputText
          name={name ? `${name}.prompt` : "prompt"}
          label="Prompt"
          placeholder="Please provide a prompt for the form field."
          testId="addField.prompt"
        />
      </FormField>
      {field.parentName && field.parentName !== "" && (
        <FormField>
          <InputText
            name={name ? `${name}.help` : "help"}
            label="Help Details"
            placeholder="Please provide details for container's help section."
            testId="addField.help"
          />
        </FormField>
      )}
      {/* {field.type === "range" && (
        <>
          <FormField>
            <InputNumber
              name={name ? `${name}.rangeMin` : "rangeMin"}
              placeholder="Min"
              testId="addField.rangeMin"
              label="RANGE"
            />
          </FormField>
          <FormField>
            <InputNumber
              name={name ? `${name}.rangeMax` : "rangeMax"}
              placeholder="Max"
              testId="addField.rangeMax"
            />
            {rangeError && <StyledErrorText>{rangeError}</StyledErrorText>}
          </FormField>
        </>
      )} */}
      {(field.type === "number" || field.type === "range") && (
        <FormGroup>
          <FormField>
            <InputRadioGroup
              name={name ? `${name}.degree` : "degree"}
              label="NUMBER TYPE"
              options={["Decimal", "Number"]}
              orient={false}
              testId="addField.numberType"
              required
            />
          </FormField>
          <FormField>
            {!watchUnits ? (
              <SearchWrapper>
                <SearchSelect
                  label="Units *"
                  testId="addField.units"
                  placeholder="Search units..."
                  results={unitResults}
                  setResults={setUnitResults}
                  search={searchUnits}
                  add={val =>
                    setValue(name ? `${name}.units` : "units", val, {shouldValidate: !!submitCount})
                  }
                  showAll
                  allowNew
                />
              </SearchWrapper>
            ) : (
              <SelectedContainer data-testid="addField.units-selected">
                <Abbr>{watchUnits}</Abbr>
                &nbsp;
                <IconButton
                  onClick={() =>
                    setValue(name ? `${name}.units` : "units", null, {
                      shouldValidate: !!submitCount
                    })
                  }>
                  <FontAwesomeIcon icon={faClose} />
                </IconButton>
              </SelectedContainer>
            )}
            <InputError name={name ? `${name}.units` : "units"} errors={errors} />
          </FormField>

          <Label bold>OPTIONAL CONFIGURATION</Label>
          {watch(name ? `${name}.degree` : "degree") === "Decimal" && (
            <>
              <FormField>
                <InputCheck
                  name={name ? `${name}.hasPrecision` : "hasPrecision"}
                  testId="addField.hasPrecision">
                  Custom rounding precision? System default will round to 2 places.
                </InputCheck>
              </FormField>
              {watch(name ? `${name}.hasPrecision` : "hasPrecision") && (
                <FormGroup>
                  <FormField>
                    <InputSelect
                      name={name ? `${name}.precision` : "precision"}
                      testId="addField.precision"
                      placeholder="Precision"
                      options={[1, 2, 3, 4, 5, 6, 7, 8]}
                    />
                  </FormField>
                </FormGroup>
              )}
            </>
          )}
          <FormField>
            <InputCheck
              name={name ? `${name}.hasQualifier` : "hasQualifier"}
              testId="addField.hasQualifier">
              Allow qualifier&nbsp;
              <Help>Atach qualifier to response (including: &gt;,&lt;,&gt;=,&lt;=,ND,DNQ,EST)</Help>
            </InputCheck>
          </FormField>
          {exclude("range") && (
            <>
              <FormField>
                <InputCheck
                  name={name ? `${name}.hasRange` : "hasRange"}
                  testId="addField.hasRange">
                  Provide restricted min-max range&nbsp;
                  <Help>Any values outside this range will not be allowed for submission.</Help>
                </InputCheck>
              </FormField>
              {watchHasRange && (
                <FormGroup>
                  <FormField>
                    <InputNumber
                      name={name ? `${name}.min` : "min"}
                      placeholder="Min"
                      testId="addField.restrictedMin"
                    />
                  </FormField>
                  <FormField>
                    <InputNumber
                      name={name ? `${name}.max` : "max"}
                      placeholder="Max"
                      testId="addField.restrictedMax"
                    />
                    {restrictedError && <StyledErrorText>{restrictedError}</StyledErrorText>}
                  </FormField>

                  <FormField>
                    <InputTextArea
                      name={name ? `${name}.restrictedRangeException` : "restrictedRangeException"}
                      placeholder={defaultRestrictedRangeException}
                      required={false}
                      testId="addField.restrictedRangeException"
                    />
                  </FormField>
                </FormGroup>
              )}
            </>
          )}
          <FormField>
            <InputCheck
              name={name ? `${name}.hasSetPoint` : "hasSetPoint"}
              testId="addField.hasSetPoint">
              <Description>
                Provide set point&nbsp;
                <Help>
                  Show these bounds on charts that are created. Does not directly affect submission.
                </Help>
              </Description>
            </InputCheck>
          </FormField>
          {watch(name ? `${name}.hasSetPoint` : "hasSetPoint") && (
            <FormGroup>
              <FormField>
                <InputNumber
                  name={name ? `${name}.setLow` : "setLow"}
                  placeholder="Min"
                  testId="addField.setPointMin"
                />
              </FormField>
              <FormField>
                <InputNumber
                  name={name ? `${name}.setHigh` : "setHigh"}
                  placeholder="Max"
                  testId="addField.setPointMax"
                />
              </FormField>
            </FormGroup>
          )}
          <FormField>
            <InputCheck name={name ? `${name}.hasARange` : "hasARange"} testId="addField.hasARange">
              <Description>
                Enable unacceptable parameter alert&nbsp;
                <Help>
                  When entry is out of acceptable range a note will be required and an email
                  notification will be sent.
                </Help>
              </Description>
            </InputCheck>
          </FormField>
          {watch(name ? `${name}.hasARange` : "hasARange") && (
            <FormGroup>
              <FormField>
                <Inline>
                  <StrictToggle
                    type="button"
                    onClick={() =>
                      setValue(name ? `${name}.strictMin` : "strictMin", !watchStrictMin)
                    }
                    isStrict={watchStrictMin ? 1 : 0}
                    disabled={!exists(watchAMin)}>
                    <Abbr
                      title={`Toggle ${watchStrictMin ? "Greater Than or Equal" : "Greater Than"}`}>
                      <FontAwesomeIcon icon={watchStrictMin ? faGreaterThan : faGreaterThanEqual} />
                    </Abbr>
                  </StrictToggle>
                  <InputNumber
                    name={name ? `${name}.aMin` : "aMin"}
                    placeholder="Lower Bound"
                    testId="addField.acceptableMin"
                  />
                </Inline>
              </FormField>
              <FormField>
                <Inline>
                  <StrictToggle
                    type="button"
                    onClick={() =>
                      setValue(name ? `${name}.strictMax` : "strictMax", !watchStrictMax)
                    }
                    isStrict={watchStrictMax ? 1 : 0}
                    disabled={!exists(watchAMax)}>
                    <Abbr title={`Toggle ${watchStrictMax ? "Less Than or Equal" : "Less Than"}`}>
                      <FontAwesomeIcon icon={watchStrictMax ? faLessThan : faLessThanEqual} />
                    </Abbr>
                  </StrictToggle>
                  <InputNumber
                    name={name ? `${name}.aMax` : "aMax"}
                    placeholder="Upper Bound"
                    testId="addField.acceptableMax"
                  />
                </Inline>
                {acceptableError && <StyledErrorText>{acceptableError}</StyledErrorText>}
              </FormField>
              <FormField>
                <InputTextArea
                  name={name ? `${name}.rangeException` : "rangeException"}
                  label="Provide prompt for explanation of unacceptable parameter?"
                  placeholder={field[name ? `${name}.rangeException` : "rangeException"]}
                  required={false}
                  testId="addField.rangeException"
                />
              </FormField>
            </FormGroup>
          )}

          <FormField>
            <InputCheck name={name ? `${name}.hasLimits` : "hasLimits"} testId="addField.hasLimits">
              Provide limits for plot&nbsp;
              <Help>
                Shows horizontal lines at specified limits on plots. Does not directly affect
                submission.
              </Help>
            </InputCheck>
          </FormField>
          {watch(name ? `${name}.hasLimits` : "hasLimits") && (
            <InputLimitGroup
              name={name ? `${name}.limits` : "limits"}
              testId="addField.limits"
              limit={2}
            />
          )}

          <FormField>
            <InputCheck
              name={name ? `${name}.hasInfinity` : "hasInfinity"}
              testId="addField.hasInfinity">
              Allow infinity?
            </InputCheck>
          </FormField>
        </FormGroup>
      )}

      {include(["multiselect", "radio", "dropdown"]) && (
        <FormGroup>
          <FormField>
            <Label htmlFor={name ? `${name}.options` : "options"}>Provide options below.</Label>
            <InputTextGroup
              name={name ? `${name}.options` : "options"}
              keyPlaceholder="Option"
              testId="addField.options"
            />
          </FormField>
        </FormGroup>
      )}
      {field.type === "radio" && (
        <FormField>
          <InputSwitch
            name={name ? `${name}.orient` : "orient"}
            label="Display"
            prompt="How should the radio buttons be displayed?"
            on="column"
            off="row"
            testId="addField.orient"
          />
        </FormField>
      )}
      {include(["radio", "dropdown"]) && (
        <FormField>
          <InputCheck name={name ? `${name}.other` : "other"} testId="addField.other">
            Provide a &quot;Other&quot; option.
          </InputCheck>
        </FormField>
      )}
      {field.type === "multiselect" && (
        <FormField>
          <InputCheck name={name ? `${name}.all` : "all"} testId="addField.allOfTheAbove">
            Provide a &quot;All of the above&quot; option.
          </InputCheck>
        </FormField>
      )}
      {field.type === "textarea" && (
        <FormField>
          <InputNumber
            name={name ? `${name}.maxLength` : "maxLength"}
            label="Text Box character restriction."
            testId="addField.lengthRestriction"
          />
        </FormField>
      )}
      {field.type === "switch" && (
        <>
          <Label>
            Set the values of the &quot;ON&quot; and &quot;OFF&quot; positions if applicable.
            (Default: &quot;ON&quot; / &quot;OFF&quot;)
          </Label>
          <FormField>
            <InputText
              name={name ? `${name}.on` : "on"}
              placeholder="ON"
              testId="addField.onValue"
            />
          </FormField>
          <FormField>
            <InputText
              name={name ? `${name}.off` : "off"}
              placeholder="OFF"
              testId="addField.offValue"
            />
          </FormField>
        </>
      )}
      {include(["confirm"]) && (
        <>
          <FormField>
            <InputCheck name={name ? `${name}.required` : "required"} checked hidden />
          </FormField>
          <FormField>
            <InputPhone
              name={name ? `${name}.phone` : "phone"}
              label="Provide phone number or email for contact?"
            />
          </FormField>
          <FormField>
            <InputText
              name={name ? `${name}.email` : "email"}
              type="email"
              placeholder="Email..."
              testId="addField.email"
            />
          </FormField>
          <Text>Please note this field type is required by default.</Text>
        </>
      )}
      {include(["checkbox"]) && (
        <FormGroup>
          <FormField>
            <InputCheck name={name ? `${name}.hasAlert` : "hasAlert"}>
              <Description>
                Enable unacceptable parameter alert&nbsp;
                <Help>
                  When entry is out of acceptable range a note will be required and an email
                  notification will be sent.
                </Help>
              </Description>
            </InputCheck>
          </FormField>
          {watch(name ? `${name}.hasAlert` : "hasAlert") && (
            <>
              <FormField standard>
                <InputSelect
                  name={name ? `${name}.alertCondition` : "alertCondition"}
                  label="Option"
                  placeholder="Condition..."
                  options={["checked", "unchecked"]}
                />
              </FormField>
              {conditionError && <StyledErrorText>{conditionError}</StyledErrorText>}
              <FormField>
                <InputTextArea
                  name={name ? `${name}.alertMessage` : "alertMessage"}
                  label="Provide prompt for explanation of unacceptable parameter?"
                  placeholder={field[name ? `${name}.alertMessage` : "alertMessage"]}
                  required={false}
                />
              </FormField>
            </>
          )}
        </FormGroup>
      )}
      {include(["switch"]) && (
        <FormGroup>
          <FormField standard>
            <InputCheck name={name ? `${name}.hasAlert` : "hasAlert"}>
              <Description>
                Enable unacceptable parameter alert&nbsp;
                <Help>
                  When entry is out of acceptable range a note will be required and an email
                  notification will be sent.
                </Help>
              </Description>
            </InputCheck>
          </FormField>
          {watch(name ? `${name}.hasAlert` : "hasAlert") && (
            <>
              <FormField standard>
                <InputSelect
                  name={name ? `${name}.alertCondition` : "alertCondition"}
                  label="Option"
                  placeholder="Condition..."
                  options={[watch(name ? `${name}.on` : "on"), watch(name ? `${name}.off` : "off")]}
                />
              </FormField>
              {conditionError && <StyledErrorText>{conditionError}</StyledErrorText>}
              <FormField>
                <InputTextArea
                  name={name ? `${name}.alertMessage` : "alertMessage"}
                  label="Provide prompt for explanation of unacceptable parameter?"
                  placeholder={field[name ? `${name}.alertMessage` : "alertMessage"]}
                  required={false}
                />
              </FormField>
            </>
          )}
        </FormGroup>
      )}
      {watch(name ? `${name}.options` : "options") && include(["dropdown", "radio"]) && (
        <FormGroup>
          <FormField standard>
            <InputCheck name={name ? `${name}.hasAlert` : "hasAlert"}>
              <Description>
                Enable unacceptable parameter alert&nbsp;
                <Help>
                  When entry matches option selected below a note will be required and an email
                  notification will be sent.
                </Help>
              </Description>
            </InputCheck>
          </FormField>
          {watch(name ? `${name}.hasAlert` : "hasAlert") && (
            <>
              <FormField>
                {watch(name ? `${name}.options` : "options")?.filter(
                  ({option}) => !option && option === ""
                )?.length === 0 ? (
                  <InputCheckGroup
                    name={name ? `${name}.alertCondition` : "alertCondition"}
                    options={watch(name ? `${name}.options` : "options")}
                  />
                ) : (
                  <Text>Please provide options to configure out of service parameter.</Text>
                )}
              </FormField>
              {conditionError && <StyledErrorText>{conditionError}</StyledErrorText>}
              <FormField>
                <InputTextArea
                  name={name ? `${name}.alertMessage` : "alertMessage"}
                  label="Provide prompt for explanation of unacceptable parameter?"
                  placeholder={field[name ? `${name}.alertMessage` : "alertMessage"]}
                  required={false}
                />
              </FormField>
            </>
          )}
        </FormGroup>
      )}
      {include("time") && (
        <FormField>
          <InputCheck name={name ? `${name}.military` : "military"} testId="addField.military">
            Report in 24HR time?
          </InputCheck>
        </FormField>
      )}
      {exclude(["checkbox", "confirm", "switch"]) && (
        <FormField>
          <InputCheck
            name={name ? `${name}.required` : "required"}
            defaultChecked={field ? field.required : true}
            testId="addField.required">
            Required
          </InputCheck>
        </FormField>
      )}

      {children}
    </Builder>
  );
};

FieldBuilder.propTypes = {
  field: PropTypes.objectOf(PropTypes.any).isRequired,
  name: PropTypes.string,
  children: PropTypes.node,
  editElement: PropTypes.bool,
  restrictedError: PropTypes.string,
  acceptableError: PropTypes.string,
  // rangeError: PropTypes.string,
  conditionError: PropTypes.string,
  units: PropTypes.arrayOf(PropTypes.string)
};

// Style Overrides
const Builder = styled(FormGroup)`
  border: ${border} solid ${({theme}) => theme.field};
  border-radius: ${radius};
  padding: ${pad}px;

  ${Error} {
    margin-top: ${pad}px;
  }
`;

const StyledErrorText = styled(Error)`
  margin: ${pad / 2}px 0 ${pad}px 0;
  color: ${({theme}) => theme.error};
  position: relative;
  display: block;
`;

const SelectedContainer = styled(Pill)`
  width: min-content;
  height: min-content;
  max-width: 100%;
  margin: ${pad}px ${pad}px 0 0;
  color: ${({theme}) => theme.tertiary};
`;

const IconButton = styled(Button)`
  ${voice.quiet};
  background-color: transparent;
  width: min-content;
  padding: 0;

  svg {
    fill: ${({theme}) => theme.tertiary};
  }
`;

const SearchWrapper = styled.div`
  max-width: 200px;
`;

const StrictToggle = styled(Button)`
  margin-top: ${pad}px;

  ${({isStrict}) =>
    isStrict
      ? css`
          padding-right: 11px;
          padding-left: 11px;
        `
      : ""}

  ${({disabled}) =>
    disabled
      ? css`
          opacity: 0.5;
        `
      : ""}
`;

const Description = styled.span`
  ${voice.normal};
  display: inline-block;
  text-align: left;
  color: ${({theme}) => theme.secondary};
`;

export default FieldBuilder;
