import React from "react";
import {createRoot} from "react-dom/client";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc.js";
import timezone from "dayjs/plugin/timezone.js";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore.js";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter.js";
import advancedFormat from "dayjs/plugin/advancedFormat.js";

import * as yup from "yup";

import "./index.css";
import App from "./App.js";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(advancedFormat);

// Custom method to validate time values
yup.addMethod(yup.string, "time", function timeValidator(message) {
  return this.test("time", message, function time(value) {
    const {path, createError} = this;
    const timeRegex = /^([01]\d|2[0-3]):([0-5]\d)$/; // Matches HH:MM format (24-hour clock)
    return timeRegex.test(value) || createError({path, message: message || "Invalid time format"});
  });
});

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<App />);
