import React, {useContext, useState, useEffect, useCallback} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faFileExport,
  faSearch,
  faFilter,
  faEdit,
  faTrash,
  faPaperPlane,
  faUserPlus,
  faEyeSlash,
  faEye
} from "@fortawesome/free-solid-svg-icons";
import styled, {css} from "styled-components";

// Contexts
import {AuthContext} from "../../contexts/auth.js";
import {useToast} from "../../contexts/toast.js";

// Hooks
import useApi from "../../hooks/useApi.js";
import useMountedState from "../../hooks/useMountedState.js";

// Components
import BaseTable from "../../components/BaseTable.js";
import Pagination from "../../components/Pagination.js";
import Dropdown from "../../components/Dropdown.js";
import Badge from "../../components/Badge.js";
import Modal from "../../components/Modal.js";
import ModalDelete from "../../components/ModalDelete.js";
import ModalUserFilter from "../general/ModalUserFilter.js";
import ModalExport from "../../components/ModalExport.js";
import ModalManage from "./ModalManage.js";
import ModalAssign from "./ModalAssign.js";
import ModalViewAllUsers from "../general/ModalViewAllUsers.js";

// Style
import {bp} from "../../style/components/breakpoints.js";
import {colors, heroTheme, pad} from "../../style/components/variables.js";
import {
  Button,
  Heading,
  Text,
  Inline,
  TableWrapper,
  TableHeaderWrap,
  TableMenu,
  TableFooter,
  Search,
  SearchIcon,
  SearchWrapper,
  Small,
  Abbr,
  Pill,
  HeadingCenter
} from "../../style/components/general.js";

const TableUsers = () => {
  const isMounted = useMountedState();

  const {atLeast, roleCanAccessResource, roles} = useContext(AuthContext);
  const {addToast} = useToast();

  const [loadingUsers, setLoadingUsers] = useState(true);
  const [original, setOriginal] = useState([]);
  const [users, setUsers] = useState();
  // Edit Options
  const [edit, setEdit] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  // Search
  const [query, setQuery] = useState("");
  // Filters
  const [activeFilters, setActiveFilters] = useState();
  const [showHidden, setShowHidden] = useState(false);
  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [pageTotal, setPageTotal] = useState(1);
  const [orderBy, setOrderBy] = useState("asc");
  const [groupBy, setGroupBy] = useState("firstName");
  const [limits, setLimits] = useState([]);
  const [limit, setLimit] = useState(10);
  // Modals
  const [showModalFilters, setShowModalFilters] = useState(false);
  const [showModalExport, setShowModalExport] = useState(false);
  const [showModalInvite, setShowModalInvite] = useState(false);
  const [showModalManage, setShowModalManage] = useState(false);
  const [showModalAssign, setShowModalAssign] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalViewAll, setShowModalViewAll] = useState(false);

  const {api: apiUsers} = useApi("users");
  const {api: apiSuppressed} = useApi("users", {suppress: {success: true, error: true}});
  const {api: apiSend} = useApi("send-invite");

  const restore = useCallback(
    u => {
      const payload = {restore: true, ids: u.map(({publicId}) => publicId)};
      apiSuppressed.callPatch(null, payload).then(({status}) => {
        if (status === 200) {
          addToast(u.length === 1 ? "User restored" : "Users restored", "success");
          setUsers(null);
        } else
          addToast(u.length === 1 ? "Failed to restore user" : "Failed to restore users", "error");
      });
    },
    [apiSuppressed, addToast]
  );

  const formatUsers = useCallback(
    formattedUsers =>
      formattedUsers.map(user => {
        const {
          publicId,
          role,
          type,
          firstName,
          lastName,
          isInvited,
          isAccepted,
          isActive,
          isDeleted,
          company
        } = user;

        return {
          ...user,
          disabled: !isActive || !isAccepted,
          publicId: publicId,
          firstName: `${firstName} ${lastName}`,
          userCompanyId: company ? company.name : "",
          userRoleId: role?.label?.toUpperCase() || type?.name?.toUpperCase(),
          status: (
            <>
              {isActive && isAccepted && !isDeleted && (
                <Pill color={heroTheme.success}>
                  <Small inverted>Active</Small>
                </Pill>
              )}
              {isDeleted && (
                <Pill color={colors.gray}>
                  <Small inverted>
                    <Abbr title="User is deleted">deleted!</Abbr>
                  </Small>
                </Pill>
              )}
              {isInvited && !isAccepted && !isDeleted && (
                <Pill color={heroTheme.warning}>
                  <Small inverted>
                    <Abbr title="User has not accepted invitation">invited</Abbr>
                  </Small>
                </Pill>
              )}
              {isInvited && isAccepted && !isActive && !isDeleted && (
                <Pill color={heroTheme.error}>
                  <Small inverted>
                    <Abbr title="User is disabled">disabled</Abbr>
                  </Small>
                </Pill>
              )}
              {!isInvited && !isActive && !isDeleted && (
                <Pill color={colors.gray}>
                  <Small inverted>
                    <Abbr title="User has not been invited">contact</Abbr>
                  </Small>
                </Pill>
              )}
            </>
          ),
          invite: (
            <Button
              type="button"
              onClick={() => {
                setSelectedUser(user);
                setShowModalInvite(true);
              }}
              title={isInvited ? "Resend" : "Send"}>
              <FontAwesomeIcon icon={faPaperPlane} />
            </Button>
          ),
          options: (
            <Inline>
              <Button
                type="button"
                onClick={() => {
                  setSelectedUser(user);
                  setShowModalManage(true);
                }}
                title="Edit User">
                <FontAwesomeIcon icon={faEdit} />
              </Button>
              {roleCanAccessResource("user", "delete") && (
                <Delete
                  type="button"
                  color={isDeleted ? heroTheme.success : heroTheme.error}
                  onClick={() => {
                    if (isDeleted) restore([user]);
                    else {
                      setSelectedUser(user);
                      setShowModalDelete(true);
                    }
                  }}
                  title={isDeleted ? "Restore" : "Deleted"}>
                  {isDeleted ? "Restore" : <FontAwesomeIcon icon={faTrash} />}
                </Delete>
              )}
            </Inline>
          )
        };
      }),
    [restore, roleCanAccessResource]
  );

  const getUsers = useCallback(
    p => {
      setLoadingUsers(true);

      const filter = {Roles: activeFilters?.roles || [], IsInvited: true};
      if (query) filter.Search = query;
      if (showHidden) {
        filter.showHidden = true;
        if (activeFilters?.state) filter.State = activeFilters.state;
      }

      apiUsers
        .callGet("", {
          page: p || 1,
          orderBy,
          groupBy,
          limit,
          filter: JSON.stringify(filter)
        })
        .then(({status, data}) => {
          if (status === 200 && data) {
            setCurrentPage(data.page);
            setPageTotal(data.pages);
            setOriginal(data.users);
            const formatted = formatUsers(data.users);
            setUsers(formatted);

            // Page Limit
            const {total: dataTotal} = data;
            setTotal(dataTotal);
            let count = 10;
            const temp = [];
            while (count < dataTotal + 10 && count <= 30) {
              temp.push(count);
              count += 10;
            }

            setLimits(temp);
            setEdit([]);
            setLoadingUsers(false);
          }
        });
    },
    [query, activeFilters, showHidden, apiUsers, orderBy, groupBy, limit, formatUsers]
  );

  // Initial Load
  useEffect(() => {
    if (isMounted() && !users) getUsers();
  }, [isMounted, getUsers, users]);

  // Update users on search, filter, sort or limit
  useEffect(() => {
    if (isMounted()) {
      setUsers(undefined);
      setCurrentPage(1);
    }
  }, [isMounted, query, activeFilters, showHidden, orderBy, groupBy, limit]);

  useEffect(() => {
    if (edit.length === 1 && !selectedUser)
      original.map(user => {
        if (user.publicId === edit[0].publicId) setSelectedUser(user);
      });
    else if (edit.length > 1 && selectedUser) setSelectedUser(null);
  }, [edit, original, setSelectedUser, selectedUser]);

  const handleSearch = e => {
    setCurrentPage(1);
    setQuery(e.target.value);
  };

  const handleReload = () => {
    setCurrentPage(1);
    getUsers();
  };

  return (
    <>
      <TableWrapper>
        <TableHeaderWrap>
          <StyledInline>
            <Heading>Users</Heading>
            <InlineSpaced>
              <SearchWrapper>
                <SearchIcon>
                  <FontAwesomeIcon icon={faSearch} />
                </SearchIcon>
                <Search
                  name="search"
                  type="text"
                  placeholder="Search..."
                  onChange={e => handleSearch(e)}
                />
              </SearchWrapper>
              <TableMenu>
                {edit?.length > 0 ? (
                  <Inline>
                    {edit?.length > 0 && <Small>{edit.length}&nbsp;selected</Small>}
                    {edit?.length > 0 && !edit.some(user => user.isDeleted) && (
                      <Button
                        type="button"
                        title="Assign User(s)"
                        onClick={() => setShowModalAssign(true)}>
                        Assign Users(s)
                      </Button>
                    )}
                    {edit?.length === 1 &&
                      !edit[0].isDeleted &&
                      selectedUser &&
                      roleCanAccessResource("user", "update") && (
                        <Button
                          type="button"
                          title="Edit"
                          onClick={() => selectedUser && setShowModalManage(true)}>
                          <FontAwesomeIcon icon={faEdit} />
                        </Button>
                      )}
                    {roleCanAccessResource("user", "delete") &&
                      (selectedUser ||
                        (edit?.length > 0 &&
                          (edit.every(({isDeleted}) => isDeleted) ||
                            edit.every(({isDeleted}) => !isDeleted)))) && (
                        <Delete
                          type="button"
                          title={
                            selectedUser?.isDeleted || edit[0]?.isDeleted ? "Restore" : "Delete"
                          }
                          onClick={() => {
                            if (selectedUser?.isDeleted || edit[0]?.isDeleted)
                              restore(edit.length ? edit : [selectedUser]);
                            else setShowModalDelete(true);
                          }}>
                          {selectedUser?.isDeleted || edit[0]?.isDeleted ? (
                            "Restore"
                          ) : (
                            <FontAwesomeIcon icon={faTrash} />
                          )}
                        </Delete>
                      )}
                  </Inline>
                ) : (
                  <Inline>
                    {atLeast("admin") && roles?.length > 0 && (
                      <Button type="button" onClick={() => setShowModalFilters(!showModalFilters)}>
                        <Badge
                          count={
                            (activeFilters?.departments ? activeFilters.departments.length : 0) +
                            (activeFilters?.roles ? activeFilters.roles.length : 0) +
                            (showHidden && activeFilters?.state?.length
                              ? activeFilters.state.length
                              : 0) +
                            (showHidden && !activeFilters?.state?.length ? 1 : 0)
                          }
                          offset="14px"
                          color={colors.heroGreen}
                        />
                        <FontAwesomeIcon icon={faFilter} />
                      </Button>
                    )}
                    {atLeast("admin") && roles?.length === 0 && (
                      <Button type="button" onClick={() => setShowHidden(!showHidden)}>
                        <FontAwesomeIcon icon={showHidden ? faEyeSlash : faEye} /> Hidden
                      </Button>
                    )}
                    {roleCanAccessResource("user", "export") && users && users.length > 0 && (
                      <Button type="button" onClick={() => setShowModalExport(true)}>
                        <Abbr title="Export">
                          <FontAwesomeIcon icon={faFileExport} />
                        </Abbr>
                      </Button>
                    )}
                    {roleCanAccessResource("user", "create") && (
                      <Button
                        type="button"
                        aria-label="add user"
                        data-testid="openUserModal"
                        title="Add User"
                        onClick={() => {
                          setSelectedUser(null);
                          setEdit([]);
                          setShowModalManage(true);
                        }}>
                        <FontAwesomeIcon icon={faUserPlus} />
                      </Button>
                    )}
                  </Inline>
                )}
              </TableMenu>
            </InlineSpaced>
          </StyledInline>
        </TableHeaderWrap>
        <BaseTable
          headings={{
            publicId: {header: "", disabled: true},
            firstName: {header: "Name", disabled: false},
            userCompanyId: {header: "Company", disabled: false},
            userRoleId: {header: "Role", disabled: false},
            status: {header: "Status", disabled: true},
            invite: {header: "Invite", disabled: true},
            options: {header: "Options", disabled: true}
          }}
          data={users}
          loading={loadingUsers}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          groupBy={groupBy}
          setGroupBy={setGroupBy}
          items={edit}
          setItems={setEdit}
          showOptions
        />
        <TableFooter>
          {total > 0 && (
            <>
              <Inline>
                {total > 10 && limits.length > 0 && (
                  <PageLimit>
                    <Dropdown
                      options={limits}
                      selection={limit}
                      setSelection={selection => {
                        setLimit(selection);
                        setCurrentPage(1);
                      }}
                    />
                  </PageLimit>
                )}
                <Text quiet>
                  {limit <= 30 && pageTotal > 1 && limits.length > 0 && "per page, "}
                  <ModalViewLink
                    disabled={!total || total <= 10}
                    onClick={!total || total <= 10 ? undefined : () => setShowModalViewAll(true)}>
                    {total} total
                  </ModalViewLink>
                </Text>
              </Inline>
              {limit <= 30 && pageTotal > 1 && (
                <Pagination
                  current={currentPage}
                  setCurrent={setCurrentPage}
                  pageTotal={pageTotal}
                  updateData={() => getUsers(currentPage)}
                  loading={loadingUsers}
                />
              )}
            </>
          )}
        </TableFooter>
      </TableWrapper>

      {showModalFilters && (
        <ModalUserFilter
          visible={showModalFilters}
          setVisible={setShowModalFilters}
          filters={activeFilters}
          setFilters={setActiveFilters}
          showHidden={showHidden}
          setShowHidden={setShowHidden}
        />
      )}

      {showModalExport && (
        <ModalExport
          visible={showModalExport}
          setVisible={setShowModalExport}
          allowRange={false}
          getExport={exportParams => apiUsers.callGet(null, {...exportParams})}
        />
      )}

      {showModalInvite && selectedUser && (
        <Modal
          visible={showModalInvite}
          setVisible={state => {
            if (!state) {
              setSelectedUser(null);
            }
            setShowModalInvite(state);
          }}>
          <HeadingCenter>Send Invite?</HeadingCenter>
          <Text>
            Are you sure you want send a new invite to {selectedUser.firstName}&nbsp;
            {selectedUser.lastName} ({selectedUser.email})?
          </Text>
          <br />
          <Button
            type="button"
            onClick={() =>
              apiSend
                .callPatch(null, {
                  publicId: selectedUser?.publicId
                })
                .then(response => {
                  if (response.status === 200) {
                    setShowModalInvite(false);
                  }
                })
            }>
            Invite
          </Button>
        </Modal>
      )}

      {edit && showModalAssign && (
        <ModalAssign
          visible={showModalAssign}
          setVisible={state => {
            if (!state) {
              setSelectedUser(null);
            }
            setShowModalAssign(state);
          }}
          users={edit}
          setUsers={setEdit}
        />
      )}

      {showModalManage && (
        <ModalManage
          visible={showModalManage}
          setVisible={state => {
            if (!state) {
              setSelectedUser(null);
            }
            setShowModalManage(state);
          }}
          user={selectedUser}
          updateTable={getUsers}
        />
      )}

      {showModalDelete && (
        <ModalDelete
          visible={showModalDelete}
          setVisible={state => {
            if (!state) {
              setSelectedUser(null);
            }
            setShowModalDelete(state);
          }}
          title="Delete Users"
          prompt={`Are your sure you want to delete ${
            selectedUser ? `${selectedUser.firstName}'s account?` : "the selected accounts?"
          } This action cannot be undone.`}
          confirmDelete={() => {
            const ids =
              edit?.length > 1 ? edit.map(current => current.publicId) : [selectedUser.publicId];
            apiUsers.callDelete(null, {data: {ids: ids}}).then(({status}) => {
              if (status === 200) {
                getUsers();
                setShowModalDelete(false);
                setEdit([]);
                setSelectedUser(null);
              }
            });
          }}
        />
      )}

      {showModalViewAll && (
        <ModalViewAllUsers
          visible={showModalViewAll}
          setVisible={setShowModalViewAll}
          reload={handleReload}
        />
      )}
    </>
  );
};

// Style Overrides
const PageLimit = styled.div`
  margin-right: ${pad}px;

  select {
    padding: ${pad / 2}px;
  }
`;

const StyledInline = styled(Inline)`
  gap: ${pad}px;
  display: block;
  width: 100%;

  ${bp(1)} {
    display: flex;
  }
`;

const InlineSpaced = styled(Inline)`
  justify-content: space-between;
  width: 100%;
`;

const Delete = styled(Button)`
  background: ${({theme}) => theme.error};
`;

const ModalViewLink = styled.span`
  ${({disabled}) =>
    !disabled &&
    css`
      text-decoration: underline;
      cursor: pointer;
    `}
`;

export default TableUsers;
