import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {useFormContext} from "react-hook-form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckDouble} from "@fortawesome/free-solid-svg-icons";

// Components
import Help from "../Help.js";
import InputError from "./InputError.js";

// Style
import {pad} from "../../style/components/variables.js";
import {voice} from "../../style/components/typography.js";
import {
  FormFieldWrapper,
  TextArea,
  Label,
  Text,
  Pill,
  Abbr
} from "../../style/components/general.js";

const InputTextArea = ({
  testId = "input-textarea",
  name,
  label,
  tag,
  prompt,
  placeholder,
  required = false,
  disabled = false,
  maxLength,
  condition,
  innerRef,
  spellcheck = false
}) => {
  const {
    watch,
    register,
    formState: {errors}
  } = useFormContext();

  const [length, setLength] = useState(maxLength);
  const [focused, setFocused] = useState(false);
  const value = watch(name);

  useEffect(() => {
    if (value && value.length > 0) {
      const count = maxLength - value.length;
      if (maxLength) setLength(count);
      setFocused(true);
    } else {
      if (maxLength) setLength(maxLength);
      setFocused(false);
    }
  }, [value, length, maxLength]);

  const showPlaceholder = () => {
    if (prompt) return !focused ? prompt : "";
    return placeholder || "";
  };

  return (
    <FormFieldWrapper data-testid={testId}>
      {label && (
        <FieldLabel htmlFor={name} bold inline>
          {label.toUpperCase()}
          {required && "*"}
          {tag && (
            <Pill quiet>
              <Abbr title={tag}>{tag}</Abbr>
            </Pill>
          )}
          {condition && <Help icon={<FontAwesomeIcon icon={faCheckDouble} />}>{condition}</Help>}
        </FieldLabel>
      )}
      <Area>
        <TextArea
          id={name}
          data-testid={`${testId}-textbox`}
          ref={innerRef}
          type="textarea"
          name={name}
          placeholder={showPlaceholder()}
          disabled={disabled}
          maxLength={maxLength || 500000}
          spellCheck={spellcheck ? "true" : undefined}
          {...register(name, {required: required})}
        />
        {maxLength && length > 0 && <MaxLength data-testid={`${testId}-max`}>{length}</MaxLength>}
      </Area>
      <InputError errors={errors} name={name} />
      {focused && <Prompt>{prompt}</Prompt>}
    </FormFieldWrapper>
  );
};

InputTextArea.propTypes = {
  testId: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  tag: PropTypes.string,
  prompt: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  spellcheck: PropTypes.bool,
  maxLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  condition: PropTypes.string,
  innerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({current: PropTypes.instanceOf(Element)})
  ])
};

// Style Overrides
const FieldLabel = styled(Label)`
  white-space: normal;
  gap: ${pad / 2}px;
  margin-bottom: 2px;
`;

const Area = styled.div`
  position: relative;
  width: 100%;
`;

const MaxLength = styled.span`
  position: absolute;
  right: 0;
  bottom: 0;
  padding: ${pad}px;
`;

const Prompt = styled(Text)`
  ${voice.quiet};
  margin-top: 2px;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 100%;
  left: 0;
`;

export default InputTextArea;
