import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

// Components
import Modal from "./Modal.js";

// Style
import {
  HeadingCenter,
  Text,
  Button,
  Form,
  FormGroup,
  ButtonLoader
} from "../style/components/general.js";

const ModalDelete = ({
  visible,
  setVisible,
  confirmDelete,
  title = "",
  prompt = "",
  action = "Delete",
  loading = false,
  hasBackButton,
  goBack = () => {},
  // nested is true if within another form, needs to use type = button and onclick rather than
  // onsubmit
  nested = false,
  width
}) => {
  const handleSubmit = e => {
    e.preventDefault();
    confirmDelete();
  };

  if (nested)
    return (
      <Modal
        visible={visible}
        setVisible={setVisible}
        hasBackButton={hasBackButton}
        goBack={goBack}
        width={width}>
        <HeadingCenter>{title || "Confirm Delete"}</HeadingCenter>
        <FormGroup>
          <Text>{prompt || "Are you sure you want to delete? This action cannot be undone."}</Text>
        </FormGroup>
        <Delete
          data-testid="confirmDelete"
          type="button"
          onClick={confirmDelete}
          loading={loading ? 1 : 0}>
          {action}
          {loading && <ButtonLoader />}
        </Delete>
      </Modal>
    );

  return (
    <Modal
      visible={visible}
      setVisible={setVisible}
      hasBackButton={hasBackButton}
      goBack={goBack}
      width={width}>
      <HeadingCenter>{title || "Confirm Delete"}</HeadingCenter>
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Text>{prompt || "Are you sure you want to delete? This action cannot be undone."}</Text>
        </FormGroup>
        <Delete data-testid="confirmDelete" type="submit" loading={loading ? 1 : 0}>
          {action}
          {loading && <ButtonLoader />}
        </Delete>
      </Form>
    </Modal>
  );
};

ModalDelete.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  hasBackButton: PropTypes.bool,
  goBack: PropTypes.func,
  confirmDelete: PropTypes.func.isRequired,
  title: PropTypes.string,
  prompt: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  loading: PropTypes.bool,
  action: PropTypes.string,
  nested: PropTypes.bool,
  width: PropTypes.string
};

// Style Overrides
const Delete = styled(Button)`
  background: ${({theme}) => theme.error};
  color: ${({theme}) => theme.tertiary};
`;

export default ModalDelete;
