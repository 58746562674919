import * as yup from "yup";

import {exists, generateUniqueKey, getReadableKey, toTitleCase} from "./helpers.js";
import {formatFormulaToSave} from "../pages/checksheet-builder/helpers.js";

// in pixels, for formatting in UI
export const EMPTY_TABLE_HEIGHT = 200;
export const TABLE_ROW_HEIGHT = 36;
export const TABLE_HEADER_HEIGHT = TABLE_ROW_HEIGHT * 2;

export const calculateSectionHeight = item => {
  if (item.type === "table") {
    return item.data.length * TABLE_ROW_HEIGHT + TABLE_HEADER_HEIGHT;
  }
  return 0;
};

export const GROUP = "group";
export const COMPONENT = "component";
export const FIELD = "field";

export const FIELD_TYPES = [
  {
    value: "checkbox",
    label: "Check Box"
  },
  {
    value: "dropdown",
    label: "Drop Down"
  },
  {
    value: "text",
    label: "Text"
  },
  {
    value: "radio",
    label: "Radio"
  },
  {
    value: "multiselect",
    label: "Multi Select"
  },
  {
    value: "number",
    label: "Number"
  },
  {
    value: "textarea",
    label: "Text Box"
  },
  {
    value: "date",
    label: "Date"
  },
  {
    value: "switch",
    label: "Switch"
  },
  {
    value: "confirm",
    label: "Confirm"
  },
  {
    value: "weather",
    label: "Weather"
  },
  {
    value: "upload",
    label: "Upload"
  }
];

export const defaultUnacceptableParameterPrompt =
  "Unacceptable parameter - please provide context.";

// Field Validation
export const initialFieldValues = {
  type: "number",
  label: "",
  prompt: "",
  required: true,
  // Number
  degree: "Number",
  units: "",
  tag: "",
  hasRange: false,
  min: null,
  max: null,
  rangeMin: null,
  rangeMax: null,
  hasARange: false,
  aRangeComment: true,
  aMin: null,
  aMax: null,
  strictMin: false,
  strictMax: false,
  hasSetPoint: false,
  setLow: null,
  setHigh: null,
  hasLimits: false,
  limits: [{value: null}],
  rangeException: defaultUnacceptableParameterPrompt,
  restrictedRangeException: "",
  hasQualifier: false,
  depends: null,
  formula: null,
  // Dropdown
  other: false,
  // Radio
  orient: false,
  // MultiSelect
  all: false,
  // Textarea
  maxLength: 200,
  // Switch
  on: "ON",
  off: "OFF",
  // Dropdown, MultiSelect, Radio
  options: [{option: ""}],
  // Confirm
  phones: null,
  email: null,
  // Time
  military: true,
  // Alert
  hasAlert: false,
  alertCondition: null,
  alertMessage: defaultUnacceptableParameterPrompt
};

/**
 * @param {string} label
 * @param {string} name
 * @param {string} parent
 * @param {object} builder
 * @returns {boolean}
 */
export const labelAlreadyExists = (label, name, parent, builder) => {
  const {allIds, byId} = builder;
  const children = (parent && byId[parent]?.children) || [];
  const compare = parent ? children : allIds;
  return compare
    ?.filter(key => !builder.byId[key].stale)
    ?.map(key => key !== name && builder.byId[key]?.label)
    .includes(label.toUpperCase());
};

/**
 * @param {object} builder
 * @returns {object}
 */
export const fieldValidationSchema = (parent, builder) =>
  yup.object().shape(
    {
      type: yup.string().required(),
      label: yup
        .string()
        .required("Field name is required.")
        .test({
          test: val => !val.match(/.*[.,'"].*/),
          message: "Field name may not contain periods, commas or quotation marks."
        })
        .test({
          name: "label-uniqueness-test",
          test: (value, ctx) => {
            if (!builder) return true;

            const newParent = ctx.parent.linkedComponent || ctx.parent.linkedGroup || parent;

            if (labelAlreadyExists(value, ctx.parent?.name, newParent, builder))
              return ctx.createError({
                message: newParent
                  ? `Field name already exists in ${builder.byId[newParent].label}`
                  : "Field name already exists on top level",
                path: ctx.path
              });

            return true;
          }
        }),
      tag: yup.string().nullable(),
      prompt: yup.string(),
      required: yup.bool(),
      // Number
      degree: yup.lazy((_value, ctx) =>
        ctx.parent.type === "number" || ctx.parent.type === "range"
          ? yup.string().required("Degree is required")
          : yup.mixed().notRequired()
      ),
      units: yup.lazy((_value, ctx) =>
        ctx.parent.type === "number" ||
        ctx.parent.type === "generated" ||
        ctx.parent.type === "range"
          ? yup.string().nullable().required("Units is required.")
          : yup.mixed().notRequired()
      ),
      hasPrecision: yup.boolean(),
      precision: yup
        .string()
        .nullable()
        .when("hasPrecision", {
          is: val => !!val,
          then: () =>
            yup.lazy(value => {
              if (value === "") return yup.string().required("Please provide decimal precision.");
              return yup
                .number()
                .min(1, "Precision must be a value between 1 and 8.")
                .max(8, "Precision must be a value between 1 and 8.");
            })
        }),
      rangeMin: yup
        .mixed()
        .nullable()
        .when("type", {
          is: val => val === "range",
          then: () =>
            yup
              .number()
              .transform((v, o) => (o === "" ? null : v))
              .nullable()
              .typeError("Min must be a number.")
              .required("Min is required.")
        }),
      rangeMax: yup
        .mixed()
        .nullable()
        .when("type", {
          is: val => val === "range",
          then: () =>
            yup
              .number()
              .transform((v, o) => (o === "" ? null : v))
              .nullable()
              .typeError("Max must be a number.")
              .required("Max is required.")
        }),
      hasRange: yup.boolean(),
      min: yup
        .mixed()
        .nullable()
        .when("hasRange", {
          is: val => !!val,
          then: () =>
            yup
              .number()
              .transform((v, o) => (o === "" ? null : v))
              .nullable()
              .typeError("Min must be a number.")
              .test({
                message: "Either min or max must be provided.",
                test: (min, ctx) => exists(min) || exists(ctx.parent.max)
              })
        }),
      max: yup
        .mixed()
        .nullable()
        .when("hasRange", {
          is: val => !!val,
          then: () =>
            yup
              .number()
              .transform((v, o) => (o === "" ? null : v))
              .nullable()
              .typeError("Max must be a number.")
              .test({
                message: "Invalid range, please make sure that max value is greater than the min.",
                test: (max, ctx2) =>
                  exists(max) && exists(ctx2.parent.min) ? max > ctx2.parent.min : true
              })
        }),
      restrictedRangeException: yup.string().nullable(),
      hasARange: yup.boolean(),
      aRangeComment: yup.boolean(),
      aMin: yup
        .mixed()
        .nullable()
        .when("hasARange", {
          is: val => !!val,
          then: () =>
            yup
              .number()
              .transform((v, o) => (o === "" ? null : v))
              .nullable()
              .typeError("Min must be a number.")
              .test({
                message: "Either min or max must be provided.",
                test: (aMin, ctx) => exists(aMin) || exists(ctx.parent.aMax)
              })
        }),
      aMax: yup
        .mixed()
        .nullable()
        .when("hasARange", {
          is: val => !!val,
          then: () =>
            yup
              .number()
              .transform((v, o) => (o === "" ? null : v))
              .nullable()
              .typeError("Max must be a number.")
              .test({
                message: "Invalid range, please make sure that max value is greater than the min.",
                test: (aMax, ctx2) =>
                  exists(aMax) && exists(ctx2.parent.aMin) ? aMax > ctx2.parent.aMin : true
              })
        }),
      hasSetPoint: yup.boolean(),
      setLow: yup
        .string()
        .nullable()
        .when("hasSetPoint", {
          is: val => !!val,
          then: () => yup.number().typeError("Set point min must be a number.").required()
        }),
      setHigh: yup
        .string()
        .nullable()
        .when(["hasSetPoint", "setLow"], {
          is: (hasRange, setLow) => hasRange && !Number.isNaN(setLow),
          then: () =>
            yup
              .number()
              .typeError("Set point max must be a number.")
              .test({
                message:
                  "Invalid range, please make sure that max value is greater than the set point min.",
                test: (setHigh, ctx2) => setHigh > ctx2.parent.setLow
              })
        }),
      hasLimits: yup.boolean(),
      limits: yup
        .mixed()
        .nullable()
        .when("hasLimits", {
          is: val => !!val,
          then: () =>
            yup.array().of(
              yup.object().shape({
                label: yup.string().required("Please enter a label."),
                value: yup
                  .number()
                  .transform((v, o) => (o === "" ? null : v))
                  .nullable()
                  .typeError("Please enter a valid number.")
                  .required("Please enter a value.")
              })
            )
        }),
      hasQualifier: yup.boolean(),
      // Dropdown
      other: yup.lazy((_value, ctx) =>
        ctx.parent.type === "dropdown" ? yup.bool() : yup.mixed().notRequired()
      ),
      // MultiSelect
      all: yup.lazy((_value, ctx) =>
        ctx.parent.type === "multiselect" ? yup.bool() : yup.mixed().notRequired()
      ),
      // Textarea
      maxLength: yup.lazy((_value, ctx) =>
        ctx.parent.type === "textarea"
          ? yup
              .number()
              .transform((v, o) => (o === "" ? null : v))
              .nullable()
              .typeError("Max Length must be a number.")
              .required("Max Length is required.")
          : yup.mixed().notRequired()
      ),
      // Switch
      on: yup.lazy((_value, ctx) =>
        ctx.parent.type === "switch" ? yup.string() : yup.mixed().notRequired()
      ),
      off: yup.lazy((_value, ctx) =>
        ctx.parent.type === "switch" ? yup.string() : yup.mixed().notRequired()
      ),
      // Options
      options: yup.lazy((_value, ctx) =>
        ctx.parent.type === "dropdown" ||
        ctx.parent.type === "multiselect" ||
        ctx.parent.type === "radio"
          ? yup
              .array()
              .nullable()
              .of(
                yup.object().shape({
                  option: yup
                    .string()
                    .nullable()
                    .test({
                      message: "Option cannot be empty.",
                      test: s => !!s
                    })
                })
              )
              .test({
                message: "Please provide at least two options.",
                test: v => Array.isArray(v) && v.length > 1
              })
              .test({
                message: "Options cannot match",
                test: val => {
                  const options = val.map(({option}) => option);
                  return new Set(options).size === options.length;
                }
              })
          : yup.mixed().notRequired()
      ),
      // Confirm
      phone: yup
        .object()
        .shape({
          number: yup
            .string()
            .nullable()
            .matches(/^\(\d{3}\)\s\d{3}-\d{4}/i, {
              message: "Please provide valid phone #.",
              excludeEmptyString: true
            }),
          extension: yup.string().nullable()
        })
        .nullable(),
      email: yup.string().email("Invalid email address").nullable(),
      hasAlert: yup.bool(),
      alertCondition: yup
        .mixed()
        .nullable()
        .test({
          test: (v, ctx) => {
            if (!Array.isArray(v)) return true;
            return v.length < ctx.parent.options.length;
          },
          message: "Cannot include all options in acceptable parameter check"
        }),
      alertMessage: yup
        .string()
        .nullable()
        .when("hasAlert", {
          is: true,
          then: () => yup.string().required("Message is required.")
        })
    },
    [
      ["min", "max"],
      ["aMin", "aMax"]
    ]
  );

// Add Elements

/**
 * @param {object} builder
 * @param {object} field
 * @param {string} referencedFieldId (parent field id of generated formula)
 * @returns {object} copy
 */
export const addField = (builder, values, nameMap, originalName) => {
  const byId = {...builder.byId};
  const allIds = [...builder.allIds];
  const {name, label, parentName, help} = values;
  const field = {...values};

  // Set Field Key
  const sanitized = label.replace(/\s+/g, " ").trim();
  field.name = name || generateUniqueKey(sanitized);
  if (originalName && nameMap !== undefined) nameMap[originalName] = field.name;
  field.label = sanitized.toUpperCase();
  field.element = "field";
  field.parentName = parentName || "";
  field.children = [];

  if (help && parentName && parentName !== "") {
    byId[parentName].hasHelp = true;
    if (
      !byId[parentName].help ||
      (byId[parentName].help.length === 1 && Object.keys(byId[parentName].help[0]).length === 0)
    )
      byId[parentName].help = [];
    byId[parentName].help.push({key: field.label, value: help, id: field.name});
  }
  delete field.help;

  byId[field.name] = field;

  if (parentName) byId[parentName].children.push(field.name);
  else allIds.push(field.name);

  return {...builder, byId, allIds};
};

/**
 * @param {object} builder
 * @param {object} field
 * @returns {object} copy
 */
export const addComponent = (builder, values, nameMap) => {
  let byId = {...builder.byId};
  let allIds = [...builder.allIds];
  const {name, label, base, parentName, children, grid, hasHelp, help, toggle} = values;

  const component = {};

  // Set Component
  component.name = name || generateUniqueKey(label);
  component.label = label.toUpperCase();
  component.base = base || false;
  component.element = "component";
  component.parentName = parentName || "";
  component.children = [];

  component.grid = grid || false;
  component.hasHelp = hasHelp || false;
  component.help = help ? [...help] : [{}];
  if (toggle) component.toggle = toggle;

  if (parentName) byId[parentName].children.push(component.name);
  else allIds.push(component.name);
  byId[component.name] = component;

  // Set Component Fields
  if (children && children.length > 0)
    children.map(childName => {
      const childCopy = {
        ...byId[childName],
        label: `${byId[childName].label}`,
        parentName: component.name
      };
      delete childCopy.name;
      if (childCopy.condition) childCopy.condition = {...byId[childName].condition};
      if (childCopy.formula) childCopy.formula = byId[childName].formula.map(part => ({...part}));
      ({byId, allIds} = addField({...builder, byId, allIds}, {...childCopy}, nameMap, childName));
    });

  return {...builder, byId, allIds};
};

/**
 * @param {object} builder
 * @param {object} field
 * @returns {object} copy
 */
export const addGroup = (builder, values, nameMap) => {
  let byId = {...builder.byId};
  let allIds = [...builder.allIds];
  const {name, label, hasHelp, help, hasGlobalPrompt, globalPrompt, children, cloneIdx, toggle} =
    values;

  const group = {};

  // Set Group
  group.name = name || generateUniqueKey(label);
  group.label = label.toUpperCase();
  group.element = "group";
  group.parentName = "";
  group.children = [];

  group.hasHelp = hasHelp || false;
  group.help = help ? [...help] : [{}];
  if (toggle) group.toggle = toggle;

  group.hasGlobalPrompt = hasGlobalPrompt;
  if (group.hasGlobalPrompt) group.globalPrompt = globalPrompt;

  if (cloneIdx || cloneIdx === 0) allIds.splice(cloneIdx, 0, group.name);
  else allIds.push(group.name);
  byId[group.name] = group;

  if (children && children.length > 0)
    children.map(childName => {
      const childCopy = {
        ...byId[childName],
        label: `${byId[childName].label}`,
        parentName: group.name,
        children: [...byId[childName].children]
      };
      delete childCopy.name;
      if (childCopy.element === COMPONENT)
        ({byId, allIds} = addComponent({...builder, byId, allIds}, {...childCopy}, nameMap));
      else {
        if (childCopy.condition) childCopy.condition = {...byId[childName].condition};
        if (childCopy.formula) childCopy.formula = [...byId[childName].formula];
        ({byId, allIds} = addField({...builder, byId, allIds}, childCopy, nameMap, childName));
      }
    });

  return {...builder, byId, allIds};
};

// Remove Elements

/**
 * Remove field in place from byId
 * @param {object} byId
 * @param {name} string
 */
const removeElementFromBuilderObject = (byId, name) => {
  if (name in byId && "children" in byId[name]) {
    byId[name].children.forEach(childName => {
      removeElementFromBuilderObject(byId, childName);
      delete byId[childName];
    });
  }
  if (name in byId) {
    delete byId[name];
  }
};

/**
 * Remove in place (allIds/children)
 * @param {object} byId
 * @param {array} allIds
 * @param {name} string
 */
const removeNameFromChildrenList = (byId, allIds, name) => {
  const {parentName} = byId[name];

  const ids = parentName ? byId[parentName].children : allIds;
  const removeIndex = ids.indexOf(name);

  if (parentName) byId[parentName].children.splice(removeIndex, 1);

  if (ids[removeIndex] === name) allIds.splice(removeIndex, 1);
};

/**
 * @param {object} builder
 * @param {object} targetElement
 * @returns {object} copy
 */
export const removeElement = (builder, targetElement) => {
  const byId = {...builder.byId};
  const allIds = [...builder.allIds];
  const {name, element} = targetElement;

  if (byId[name]) {
    const oldParentName = byId[name].parentName;
    if (element === FIELD && oldParentName && oldParentName !== "") {
      if (byId[oldParentName].help) {
        byId[oldParentName].help = byId[oldParentName].help.filter(h => h.id !== name);
        if (byId[oldParentName].help.length === 0) {
          byId[oldParentName].hasHelp = false;
          byId[oldParentName].help = [{}];
        }
      }
    }

    // field or component, delete id from parent's children/top-level allIds
    removeNameFromChildrenList(byId, allIds, name);

    // delete all children elements first
    removeElementFromBuilderObject(byId, name);

    // delete current element
    delete byId[name];
  }

  return {...builder, byId, allIds};
};

// Edit Elements

export const sortHelp = (help, children, byId) => {
  if (!help) return help;
  const fieldHelp = help.filter(item => item.id in byId);
  const newHelp = help.filter(item => !(item.id in byId));
  return [...newHelp, ...fieldHelp.sort((a, b) => children.indexOf(a.id) - children.indexOf(b.id))];
};

/**
 * Updates elements
 * @param {object} builder
 * @param {object} values
 * @returns {object} object with builder and notifications
 */
export const updateElement = (
  builder,
  values,
  regenKey = true,
  nameMap = null,
  forceNewKey = false,
  stale = false,
  notifications = null
) => {
  let {byId, allIds} = builder;
  const {name, label, parentName, help, fields, markerContent} = values;

  const oldParentName = byId[name].parentName;
  if (
    values.element === FIELD &&
    oldParentName &&
    oldParentName !== "" &&
    byId[oldParentName].help
  ) {
    byId[oldParentName].help = byId[oldParentName].help.filter(h => h.id !== name);
    if (byId[oldParentName].help.length === 0) {
      byId[oldParentName].hasHelp = false;
      byId[oldParentName].help = [{}];
    }
  }

  let element = byId[name];

  const staleItem = stale ? {...element} : null;

  element = {...values};

  // Field name changed
  if (label !== byId[name].label || forceNewKey) {
    const newKey = generateUniqueKey(label);
    if (regenKey || forceNewKey) {
      if (stale) {
        byId[element.name] = {...staleItem, stale: newKey};
        const matches = Object.keys(byId).filter(key => byId[key].stale === element.name);
        matches.map(match => {
          byId[match].stale = newKey;
        });
      }

      element.name = newKey;
    }
    element.label = label.toUpperCase();
    element.parentName = parentName;

    // Update children's parentName
    const currentChildren = byId[name].children;
    currentChildren.forEach(id => {
      if (byId[id]) byId[id].parentName = element.name;
    });

    // Update parent's children value
    const ids = parentName ? byId[parentName].children : allIds;
    const updateIndex = ids.indexOf(name);

    if (stale) {
      if (parentName) byId[parentName].children.splice(updateIndex, 0, element.name);
      else allIds.splice(updateIndex, 0, element.name);
    } else {
      if (parentName) byId[parentName].children[updateIndex] = element.name;
      else allIds[updateIndex] = element.name;
      delete byId[name];
    }
  }

  // Linked to different group or component
  if (parentName !== null && parentName !== undefined && parentName !== oldParentName) {
    // New Parent Set
    if (parentName !== "" && byId[parentName]) {
      byId[parentName].children.push(element.name);

      if (oldParentName === "") {
        // Remove key from allIds
        const updateAll = allIds.indexOf(name);
        allIds.splice(updateAll, 1);
      } else {
        // Remove key from children
        const childIndex = byId[oldParentName] && byId[oldParentName].children.indexOf(name);
        byId[oldParentName].children.splice(childIndex, 1);
      }
    } else {
      allIds.push(element.name);
      // Remove key from children
      const updateChildren = byId[oldParentName] && byId[oldParentName].children.indexOf(name);
      byId[oldParentName].children.splice(updateChildren, 1);
    }
  }

  element.parentName = parentName;

  if (element.element === FIELD && help && help !== "" && parentName && parentName !== "") {
    byId[parentName].hasHelp = true;
    if (
      !byId[parentName].help ||
      (byId[parentName].help.length === 1 && Object.keys(byId[parentName].help[0]).length === 0)
    )
      byId[parentName].help = [];

    byId[parentName].help.push({key: label, value: help, id: element.name});

    byId[parentName].help = sortHelp(byId[parentName].help, byId[parentName].children, byId);
  }

  if (element.element === FIELD) delete element.help;

  if (markerContent) element.markerContent = markerContent;

  byId[element.name] = element;

  const updatedById = {...byId};
  const updatedAllIds = [...allIds];

  Object.entries(byId)
    .filter(
      ([
        ,
        {
          formula: dependentFormula,
          trueFormula: dependentTrueFormula,
          condition: dependentCondition
        }
      ]) =>
        dependentFormula?.some(({value}) => value === name) ||
        dependentTrueFormula?.some(({value}) => value === name) ||
        dependentCondition?.list?.some(({depends}) => depends === name)
    )
    .map(([dependentName, dependentField]) => {
      if (dependentField.formula) {
        const updatedBuilder = {byId, allIds};
        const dependentFormulaAdjusted = dependentField.formula.map(item =>
          item.value === name
            ? {
                ...item,
                value: element.name
              }
            : item
        );

        const formatted = formatFormulaToSave(updatedBuilder, "edit", {
          ...dependentField,
          formula: dependentFormulaAdjusted
        });
        updatedById[dependentName] = formatted;
      }

      if (dependentField.condition?.list) {
        updatedById[dependentName].condition = {
          ...dependentField.condition,
          list: dependentField.condition.list.map(item => ({
            ...item,
            depends: item.depends === name ? element.name : item.depends
          }))
        };
      }
    });

  byId = updatedById;
  allIds = updatedAllIds;

  // Update notifications that reference field
  const nById = notifications?.byId ? {...notifications.byId} : {};

  if (notifications?.byId)
    Object.keys(notifications?.byId).map(key => {
      const {trigger, conditionArray, depends} = notifications.byId[key];

      if (trigger === "Unacceptable Parameter") {
        nById[key].conditionArray = conditionArray.map(({depends: condDepends, ...rest}) => ({
          ...rest,
          depends: condDepends === name ? element.name : condDepends
        }));
      }

      if (depends === name) nById[key].depends = element.name;
    });

  // fields is only used by apply template
  if (fields) {
    byId[element.name].children = [];
    fields.forEach(field => {
      ({byId, allIds} = addField(
        {...builder, byId, allIds},
        {...field, parentName: element.name, name: null, toggle: true},
        nameMap,
        field.name
      ));
    });
  }

  delete byId[element.name].fields;

  const updatedBuilder = {...builder, byId, allIds};
  const updatedNotifications = notifications
    ? {
        byId: nById,
        allIds: notifications?.allIds || []
      }
    : null;

  return {builder: updatedBuilder, notifications: updatedNotifications};
};

/**
 * @param {Array.<string>} ids
 * @param {object} builder
 * @param {object} result
 * @returns {Array.<string>}
 */
export const flattenBuilderHelper = (ids, builder, result = []) => {
  if (ids && ids.length > 0)
    ids.map(id => {
      const item = builder.byId[id];
      result.push(id);

      if (item && item.children && item.children.length > 0) {
        flattenBuilderHelper(item.children, builder, result);
      }
    });

  return result;
};

/**
 * @param {object} builder
 * @returns {Array.<string>}
 */
export const flattenBuilder = builder => flattenBuilderHelper(builder.allIds, builder);

/**
 * Filters toggle and preview
 * @param {array} ids
 * @param {object} builder
 * @param {object} newBuilder
 * @returns {object}
 */
export const newFromBuilder = (ids, builder, newBuilder) => {
  const {byId} = builder;
  ids.map(id => {
    const target = byId[id];
    if (id in byId && target.toggle) {
      newBuilder.byId[target.name] = {...target, children: []};
      delete newBuilder.byId[target.name].toggle;
      if ("preview" in target) delete newBuilder.byId[target.name].preview;

      if (target.children.length > 0) newFromBuilder(target.children, builder, newBuilder);

      if (target.parentName && target.parentName !== "" && newBuilder.byId[target.parentName])
        newBuilder.byId[target.parentName].children.push(target.name);
      else newBuilder.allIds.push(target.name);
    }
  });

  return newBuilder;
};

/**
 * @param {array} ids
 * @param {object} builder
 * @returns {boolean}
 */
export const validBuilder = (ids, builder) => {
  if (!builder) return false;

  const {allIds, byId} = builder;

  if (!byId || !allIds || allIds.length === 0) return false;

  for (let i = 0; i < ids.length; i++) {
    const id = ids[i];
    // remove orphan reference
    if (!(id in byId)) ids.splice(i, 1);

    if (byId[id].element !== FIELD && byId[id].children.length === 0) return false;
    if (byId[id].children.length > 0) {
      const result = validBuilder(byId[id].children, builder);
      if (!result) return false;
    }
  }

  return true;
};

/**
 * @param {array} ids
 * @param {object} builder
 * @param {array} invalid
 * @returns {boolean}
 */
export const whichElementInvalid = (ids, builder, invalid) => {
  if (!builder) return false;

  const {allIds, byId} = builder;

  if (!byId || !allIds || allIds.length === 0) return false;

  for (let i = 0; i < ids.length; i++) {
    const id = ids[i];
    // remove orphan reference
    if (!(id in byId)) ids.splice(i, 1);
    else {
      const element = byId[id];

      if (element.element !== FIELD && (!element.children || element.children.length === 0)) {
        invalid.push(id);
        if (element.parentName && element.parentName !== "") invalid.push(element.parentName);
      } else if (element.element !== FIELD) {
        whichElementInvalid(element.children, builder, invalid);
      }
    }
  }
  return true;
};

/**
 * @param {string} fieldName
 * @returns {object}
 */
export const replacePseudoFields = fieldName => {
  if (!fieldName) return {sanitized: fieldName, suffix: ""};
  let sanitized = fieldName.replace(/_comment$/, "");
  sanitized = sanitized.replace(/_infinite$/, "");
  sanitized = sanitized.replace(/_qualifier$/, "");
  sanitized = sanitized.replace(/_qualifierEnabled$/, "");
  sanitized = sanitized.replace(/_rainfall$/, "");

  let suffix = "";
  if (sanitized !== fieldName) {
    const temp = fieldName.replace(sanitized, "").replace("_", "");
    suffix = getReadableKey(temp).toUpperCase();
  }
  return {sanitized, suffix};
};

/**
 * @param {string} parentName
 * @param {object} byId
 * @returns {string}
 */
export const getAncestors = (parentName, byId) => {
  const {sanitized, suffix} = replacePseudoFields(parentName);

  const parent = sanitized && byId[sanitized];
  const grandParent = parent && parent.parentName && byId[parent.parentName];

  let overrideLabel = parent?.label;
  if (suffix && overrideLabel) overrideLabel = `${overrideLabel} ${suffix}`;

  let ancestors = "";
  if (parent && grandParent) ancestors = `${grandParent.label} ${overrideLabel}`;
  if (parent && !grandParent) ancestors = overrideLabel;

  return ancestors;
};

/**
 * @param {Array.<string>} filter
 * @param {Object} field
 * @returns {Array}
 */
const filtered = (filters, field) => {
  if (field.stale) return false;
  if (!filters) return true;
  return filters.includes(field.type);
};

/**
 * @param {Array} ids
 * @param {Object} byId
 * @param {Array} filters field type filters
 * @param {Array.<object>} result initial array
 * @returns {Array.<object>}
 */
export const getFields = (ids, byId, filters = null, result = []) => {
  ids.map(id => {
    if (byId[id] && byId[id].element === "field" && filtered(filters, byId[id])) {
      const field = byId[id];

      const ancestors = getAncestors(field.parentName, byId);
      const value = ancestors ? `${ancestors} ${field.label}` : field.label;

      // case for rainfall
      if (field.type === "weather")
        result.push({
          ...field,
          name: `${field.name}_rainfall`,
          label: "Rainfall",
          ancestry: `${toTitleCase(value)} Rainfall`
        });

      result.push({
        ...field,
        ancestry: toTitleCase(value)
      });
    }

    if (byId[id] && byId[id].children.length > 0)
      getFields(byId[id].children, byId, filters, result);
  });
  return result;
};

/**
 * @param {object} field
 * @param {object} responses
 * @returns {object}
 */
const formatFieldResponse = (field, responses) => {
  if (!field) return "";
  const {name, type, on, off} = field;
  let response = responses[name];
  if (response === null || response === undefined) return "";
  if (response === "_CONDITION_UNSATISFIED") return response;
  if (type === "number") response = `${response}`;
  if (type === "generated") response = response ? `${response}` : "NO PREVIOUS RECORD";
  if (type === "checkbox") response = response ? "CHECKED" : "UNCHECKED";
  if (type === "confirm") response = response ? response.toUpperCase() : "";
  if (type === "switch") response = response ? on.toUpperCase() : off.toUpperCase();
  if (response?.constructor === Array) response = response.join(", ");

  return response;
};

/**
 * Format responses from form based on builder field types
 * @param {object} responses
 * @param {object} builder
 * @returns {object}
 */
export const formatSubmissions = (responses, builder) => {
  const result = {};

  Object.keys(responses).map(fieldName => {
    const field = builder.byId[fieldName];

    if (
      fieldName.match(/.*_comment$/) ||
      fieldName.match(/.*_qualifier$/) ||
      fieldName.match(/.*_qualifierEnabled$/) ||
      fieldName.match(/.*_rainfall$/)
    )
      result[fieldName] = responses[fieldName];
    else result[fieldName] = formatFieldResponse(field, responses);
  });

  return result;
};

/**
 * Check for unique 'key' within builder element
 * @param {object} builder
 * @param {string} key Element key to compare
 * @param {string} value Value to compare against
 * @param {string} elementType GROUP, COMPONENT or FIELD
 * @returns {boolean} is unique
 */
const isUniqueKey = (builder, key, value, elementType = null) => {
  // remove duplicate spaces
  const sanitized = value.replace(/\s+/g, " ").trim();

  return (
    !Object.keys(builder.byId).filter(current => {
      const targetElement = builder.byId[current];
      if (elementType && elementType === targetElement.element) return false;
      return sanitized.toLowerCase() === targetElement[key];
    }).length > 0
  );
};

/**
 * Check for unique label within builder element
 * @param {object} builder
 * @param {string} value Value to compare against
 * @param {string} elementType GROUP, COMPONENT or FIELD
 */
export const isUniqueLabel = (builder, value, elementType) =>
  isUniqueKey(builder, "label", value, elementType);

/**
 * Return true if field existed on checksheet at time of record
 * @param {string} id
 * @param {object} byId
 * @param {string} dateDue
 * @param {object} responses
 * @returns {boolean}
 */
export const filterNonexistentField = (
  id,
  byId,
  responses,
  allowStale = false,
  allowNew = false
) => {
  const item = byId[id];
  if (!item || item.type === "weather") return false;
  if (item.element !== "field") return true;
  if (!allowStale && item.stale) return false;
  // if responses is null, we are in an active checksheet, so always show (provided above checks passed)
  // otherwise, hide field if id not in responses
  if (item.stale) return !responses || !!responses[id];
  return allowNew || !responses || id in responses;
};
