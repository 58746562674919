import React from "react";
import PropTypes from "prop-types";
import {FormProvider, useForm} from "react-hook-form";
// eslint-disable-next-line import/extensions
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";

// Components
import Modal from "../../components/Modal.js";
import InputText from "../../components/form/InputText.js";

// Style
import {HeadingCenter, Button, FormField, Form} from "../../style/components/general.js";
import {newFromBuilder} from "../../utils/builder.js";

const ModalEditChecksheetMeta = ({visible, setVisible, checksheet, edit, setName}) => {
  const schema = yup.object().shape({
    name: yup.string().required("Please provide checksheet name"),
    frequency: yup.string().nullable()
  });

  const form = useForm({
    defaultValues: {name: checksheet.name, frequency: checksheet.frequency},
    resolver: yupResolver(schema)
  });
  const {watch, handleSubmit, reset} = form;
  const name = watch("name");

  const submitChanges = () => {
    const builder = newFromBuilder(
      checksheet.builder.allIds,
      {...checksheet.builder},
      {allIds: [], byId: {}}
    );
    edit("", {...checksheet, name, builder}).then(response => {
      if (response.status === 200) {
        reset({name});
        setName(name);
        setVisible(false);
      }
    });
  };

  return (
    <Modal visible={visible} setVisible={setVisible}>
      <HeadingCenter>Edit Name</HeadingCenter>

      <FormProvider {...form}>
        <Form onSubmit={handleSubmit(submitChanges)} noValidate>
          <FormField>
            <InputText
              name="name"
              placeholder="Name"
              testId="checksheet.editName"
              required
              {...form}
            />
          </FormField>

          <Button type="submit" data-testid="checksheet.saveMeta">
            Save
          </Button>
        </Form>
      </FormProvider>
    </Modal>
  );
};

ModalEditChecksheetMeta.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  checksheet: PropTypes.objectOf(PropTypes.any).isRequired,
  edit: PropTypes.func.isRequired,
  setName: PropTypes.func.isRequired
};

export default ModalEditChecksheetMeta;
