import React, {useEffect} from "react";
import PropTypes from "prop-types";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import styled from "styled-components";
import * as yup from "yup";

// Utils
import useMountedState from "../../hooks/useMountedState.js";
import useApi from "../../hooks/useApi.js";

// Components
import Modal from "../../components/Modal.js";
import InputText from "../../components/form/InputText.js";
import InputCheck from "../../components/form/InputCheck.js";
import InputSelect from "../../components/form/InputSelect.js";
import InputDate from "../../components/form/InputDate.js";
import InputNumber from "../../components/form/InputNumber.js";
import InputTime from "../../components/form/InputTime.js";

// Style
import {border, pad, radius} from "../../style/components/variables.js";
import {
  HeadingCenter,
  Button,
  FormField,
  Form,
  FormGroup,
  Label,
  Warning
} from "../../style/components/general.js";

const ModalEditMeta = ({visible, setVisible, reportMeta, save}) => {
  const isMounted = useMountedState();

  const {api: apiFrequency, data: frequencies} = useApi("frequencies");

  const schema = yup.object().shape({
    name: yup.string().required("Please provide name"),
    interval: yup
      .number()
      .transform((v, o) => (o === "" ? null : v))
      .nullable()
      .required("Please provide interval"),
    base: yup.string().nullable().required("Please provide base"),
    hasFrequency: yup.bool(),
    frequency: yup.string().when("hasFrequency", {
      is: true,
      then: () => yup.string().required("Frequency is required.")
    }),
    startDate: yup
      .string()
      .nullable()
      .when("hasFrequency", {
        is: true,
        then: () => yup.date().typeError("Start date is required.")
      }),
    startTime: yup
      .string()
      .nullable()
      .when("hasFrequency", {
        is: true,
        then: () => yup.string().time().required("Start time is required.")
      }),
    start: yup
      .string()
      .nullable()
      .when("template", {
        is: false,
        then: () => yup.date().typeError("Start date is required.")
      }),
    end: yup.string().when("template", {
      is: false,
      then: () => yup.date().typeError("End date is required.")
    })
  });

  const form = useForm({resolver: yupResolver(schema)});
  const {watch, handleSubmit, reset} = form;
  const hasHeader = watch("hasHeader");
  const hasFrequency = watch("hasFrequency");

  useEffect(() => {
    if (isMounted()) {
      apiFrequency.callGet();
      const {header} = reportMeta;
      reset({...reportMeta, hasHeader: header !== null});
    }
  }, [isMounted, apiFrequency, reportMeta, reset]);

  return (
    <Modal visible={visible} setVisible={setVisible}>
      <HeadingCenter>Edit Report Metadata</HeadingCenter>

      <FormProvider {...form}>
        <Form onSubmit={handleSubmit(save)} noValidate>
          <FormField>
            <InputText name="name" placeholder="Name" label="Name" required />
          </FormField>

          <SubMenu>
            <Label htmlFor="interval" bold>
              DATA FROM LAST
            </Label>
            <FormGroup inline>
              <FormField inline>
                <InputNumber name="interval" placeholder="Interval..." />
              </FormField>
              <FormField inline>
                <InputSelect
                  name="base"
                  placeholder="Base..."
                  options={[
                    {label: `Day${watch("interval") > 1 ? "s" : ""}`, name: "days"},
                    {label: `Week${watch("interval") > 1 ? "s" : ""}`, name: "weeks"},
                    {label: `Month${watch("interval") > 1 ? "s" : ""}`, name: "months"},
                    {label: `Year${watch("interval") > 1 ? "s" : ""}`, name: "years"}
                  ]}
                />
              </FormField>
            </FormGroup>
          </SubMenu>

          <FormField>
            <InputCheck name="hasHeader">Include a page header?</InputCheck>
          </FormField>

          {hasHeader && (
            <SubMenu>
              <FormField>
                <InputText name="header.name" label="Report Header" />
              </FormField>

              <FormField>
                <InputCheck name="header.address">Include an address?</InputCheck>
              </FormField>

              <FormField>
                <InputText name="header.topLeft" label="Top Left" maxLength={100} />
              </FormField>

              <FormField>
                <InputText name="header.bottomLeft" label="Bottom Left" maxLength={100} />
              </FormField>

              <FormField>
                <InputText name="header.topRight" label="Top Right" maxLength={100} />
              </FormField>

              <FormField>
                <Label htmlFor="header.bottomRight" bold>
                  BOTTOM RIGHT
                </Label>
                <Warning>
                  Warning! This is a generated date, custom values will replace date.
                </Warning>
                <InputText name="header.bottomRight" maxLength={100} />
              </FormField>
            </SubMenu>
          )}

          <FormField>
            <InputCheck name="hasFrequency">Generate report on frequency?</InputCheck>
          </FormField>

          {hasFrequency && frequencies && (
            <SubMenu>
              <InlineWrapper>
                <span>Generate</span>
                <InputSelect
                  name="frequency"
                  placeholder="Frequency..."
                  options={frequencies.map(({label, name: freqName}) => ({
                    label: label,
                    name: freqName
                  }))}
                />
                <StyledSpan>Starting</StyledSpan>
                <InputDate name="startDate" label="Starting" />
                <InputTime name="startTime" label="At" />
              </InlineWrapper>
            </SubMenu>
          )}

          <Button type="submit" data-testid="checksheet.saveMeta">
            Save
          </Button>
        </Form>
      </FormProvider>
    </Modal>
  );
};

ModalEditMeta.propTypes = {
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
  reportMeta: PropTypes.objectOf(PropTypes.any),
  save: PropTypes.func
};

ModalEditMeta.defaultProps = {
  reportMeta: {},
  visible: false,
  setVisible: () => {},
  save: () => {}
};

// Style Overrides

const SubMenu = styled(FormGroup)`
  position: relative;
  padding: ${pad}px;
  margin-bottom: ${pad * 2}px;
  width: 100%;
  border: ${border} solid ${props => props.theme.secondary};
  border-radius: ${radius};
`;

const InlineWrapper = styled(FormField)`
  width: 100%;
  gap: ${pad}px;
  align-self: flex-start;

  :nth-child(n) {
    max-width: max-content;
  }
`;

const StyledSpan = styled.span`
  margin-top: ${pad}px;
`;

export default ModalEditMeta;
