import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import {useParams, Navigate} from "react-router";
import styled, {css} from "styled-components";
import {useForm, FormProvider} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {DragDropContext, Droppable} from "react-beautiful-dnd";
import {faEdit, faFilter, faPlus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import dayjs from "dayjs";

// Hooks
import useApi from "../hooks/useApi.js";
import useMountedState from "../hooks/useMountedState.js";

// Contexts
import {AuthContext} from "../contexts/auth.js";
import {NotificationContext} from "../contexts/notify.js";
import {FacilityNavContext} from "../contexts/facilitynav.js";
import {useToast} from "../contexts/toast.js";
import {SettingsContext} from "../contexts/settings.js";

// Utils
import {
  getSnakeCase,
  setWithExpiry,
  getWithExpiry,
  generateUniqueKey,
  prettyDateInUserTimezone,
  exists,
  toTitleCase,
  convertToUserTimezone
} from "../utils/helpers.js";
import {
  BASE_OPTIONS,
  BASE_OPTIONS_PLURAL,
  DAY_IDX,
  DAY_NAMES_DAYJS,
  DAY_TO_IDX_DAYJS,
  getNumberSuffix,
  IDX_TO_DAY,
  IDX_TO_DAY_DAYJS
} from "./schedule/helpers.js";
import {
  addComponent,
  addField,
  removeElement,
  updateElement,
  newFromBuilder,
  validBuilder,
  addGroup,
  FIELD,
  sortHelp,
  labelAlreadyExists
} from "../utils/builder.js";
import {
  recurseToggle,
  getToggleState,
  resolveDependencyMismatches
} from "./checksheet-builder/helpers.js";

// Components
import Room from "./general/Room.js";
import FacilityPageHeader from "./general/FacilityPageHeader.js";
import RenderChecksheetBuilder from "./checksheet-builder/RenderChecksheetBuilder.js";
import MemberRestrict from "./checksheet-builder/MemberRestrict.js";
import Badge from "../components/Badge.js";
import Notifications from "./checksheet-builder/Notifications.js";
import ChecksheetRestrictions from "./checksheet-builder/ChecksheetRestrictions.js";
import ComponentTemplates from "./checksheet-builder/ComponentTemplates.js";
import ModalEditElement from "./checksheet-builder/ModalEditElement.js";
import ModalAddField from "./checksheet-builder/ModalAddField.js";
import ModalDeleteElement from "./checksheet-builder/ModalDeleteElement.js";
import ModalRedirect from "./general/ModalRedirect.js";
import ModalFormula from "./checksheet-builder/ModalFormula.js";
import ModalElapsed from "./checksheet-builder/ModalElapsed.js";
import ModalConditionals from "./checksheet-builder/ModalConditionals.js";
import ModalHelp from "./checksheet-builder/ModalHelp.js";
import ModalApplyTemplate from "./checksheet-builder/ModalApplyTemplate.js";
import ModalSaveTemplate from "./checksheet-builder/ModalSaveTemplate.js";
import ModalChecksheetPreview from "./checksheet-builder/ModalChecksheetPreview.js";
import ModalNotifications from "./checksheet-builder/ModalNotifications.js";
import ModalChecksheetRestrictions from "./checksheet-builder/ModalChecksheetRestrictions.js";
import ModalFilter from "./checksheet-builder/ModalFilter.js";
import ModalMemberRestrict from "./checksheet-builder/ModalMemberRestrict.js";
import {
  InputText,
  InputSelect,
  InputDay,
  InputCalendar,
  InputNumber,
  InputError
} from "../components/form/FormInputs.js";

// Style
import {pad, border, radius, colors} from "../style/components/variables.js";
import {flex} from "../style/components/mixins.js";
import {bp} from "../style/components/breakpoints.js";
import {
  Page,
  Loader,
  Heading,
  Form,
  FormField,
  ButtonFull,
  Inline,
  ButtonLoader,
  Button,
  StickyWrapper,
  Abbr,
  Pill,
  Warning,
  Error,
  HeadingMedium,
  Label,
  FormFieldWrapper,
  Input
} from "../style/components/general.js";

const NEVER = "Never";

const CUSTOM_OPTION = {
  name: "custom",
  label: "Custom..."
};

const NO_LAST_DAY_OPTION = ["daily", "weekly", "bi-weekly", "work-week", "semi-monthly"];

const ChecksheetBuilder = () => {
  const {slug} = useParams();

  const isMounted = useMountedState();

  const {addToast} = useToast();

  const {roleCanAccessResource} = useContext(AuthContext);
  const {getNotifications} = useContext(NotificationContext);
  const {facility, setFacility} = useContext(FacilityNavContext);
  const {settings} = useContext(SettingsContext);

  const [active, setActive] = useState(null);
  const [activeDroppableId, setActiveDroppableId] = useState("");
  const [builder, setBuilder] = useState({allIds: [], byId: {}});
  const [hasElements, setHasElements] = useState(null);
  const [error, setError] = useState(null);
  const [frequencies, setFrequencies] = useState([]);
  const [units, setUnits] = useState([]);
  const [unitsToAdd, setUnitsToAdd] = useState([]);
  const [loadingPublish, setLoadingPublish] = useState(false);
  const [loadingDraft, setLoadingDraft] = useState(false);
  const [startPreview, setStartPreview] = useState("");
  // Menu Left
  const [restrictTo, setRestrictTo] = useState([]);
  const [external, setExternal] = useState(false);
  const [fieldNotifications, setFieldNotifications] = useState({allIds: [], byId: {}});
  const [checksheetRestrictions, setChecksheetRestrictions] = useState([]);
  const [refreshTemplates, setRefreshTemplates] = useState(false);
  const [template, setTemplate] = useState(null);
  const [appliedTemplates, setAppliedTemplates] = useState({});
  const [templateList, setTemplateList] = useState([]);
  const [skipDayCorrection, setSkipDayCorrection] = useState(true);
  const [skipDateCorrection, setSkipDateCorrection] = useState(true);
  // Menu Right
  const [showPreview, setShowPreview] = useState(false);
  const [preview, setPreview] = useState(null);
  const [loadingToggleAll, setLoadingToggleAll] = useState(false);
  const [hasToggledOff, setHasToggledOff] = useState(null);
  const [hasToggledOn, setHasToggledOn] = useState(null);
  // Filters
  const [filters, setFilters] = useState(null);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [filterResult, setFilterResult] = useState(null);
  // Modals
  const [target, setTarget] = useState(null);
  const [showModalAdd, setShowModalAdd] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalHelp, setShowModalHelp] = useState(false);
  const [showModalRedirectFormula, setShowModalRedirectFormula] = useState(false);
  const [showModalRedirectElapsed, setShowModalRedirectElapsed] = useState(false);
  const [formulaMode, setFormulaMode] = useState("create");
  const [showModalFormula, setShowModalFormula] = useState(false);
  const [elapsedMode, setElapsedMode] = useState("create");
  const [showModalElapsed, setShowModalElapsed] = useState(false);
  const [showModalConditional, setShowModalConditional] = useState(false);
  const [showModalTemplate, setShowModalTemplate] = useState(false);
  const [showModalSaveTemplate, setShowModalSaveTemplate] = useState(false);
  const [showModalNotification, setShowModalNotification] = useState(false);
  const [showModalRestriction, setShowModalRestriction] = useState(false);
  const [showModalMemberRestrict, setShowModalMemberRestrict] = useState(false);

  const {api: apiFacility, loading: loadingFacility} = useApi(`facilities/${slug}`);
  const {api: apiFrequency} = useApi("frequencies");
  const {api: apiChecksheet} = useApi("checksheets");
  const {api: apiBase} = useApi("base-components");
  const {api: apiUnits} = useApi("units");

  const schema = yup.object().shape({
    name: yup.string().required("Please provide a name."),
    frequency: yup.string().required("Please provide a frequency."),
    recurrence: yup
      .object()
      .nullable()
      .when("frequency", ([freq], s) => {
        if (freq === CUSTOM_OPTION.name)
          return yup.object().shape({
            interval: yup
              .number()
              .transform((v, o) => (o === "" ? null : v))
              .required("Please provide interval."),
            days: yup
              .array()
              .of(yup.string())
              .when("base", ([base]) => {
                if (base !== "weeks") return yup.mixed().nullable();
                return yup
                  .array()
                  .of(yup.string())
                  .test({
                    test: v => Array.isArray(v) && v.length > 0,
                    message: "Please provide weekday."
                  });
              })
          });

        return s;
      })
  });

  const form = useForm({
    defaultValues: {
      name: "",
      frequency: "",
      recurrence: {
        interval: 1,
        base: "days",
        endDate: null,
        ends: NEVER,
        days: null
      }
    },
    resolver: yupResolver(schema)
  });

  const {
    watch,
    setValue,
    handleSubmit,
    reset,
    formState: {errors}
  } = form;

  const watchName = watch("name");
  const watchFrequency = watch("frequency");
  const watchStartDate = watch("start.date");
  const watchLastDay = watch("start.lastDay");
  const watchDueDays = watch("recurrence.days");
  const watchBase = watch("recurrence.base");
  const watchInterval = watch("recurrence.interval");
  const watchAvailability = watch("recurrence.availability");

  const filterCount = useMemo(() => {
    let count = 0;
    if (filters?.types?.length) count += filters.types.length;
    if (filters?.tags?.length) count += filters.tags.length;
    return count;
  }, [filters]);

  const getTemplateLabel = id => {
    const filtered = templateList.filter(t => t.id === id);
    if (filtered.length) return filtered[0].label;
    return "Template";
  };

  const getFacility = useCallback(
    () =>
      apiFacility.callGet().then(({status, data}) => {
        if (status === 200) setFacility(data);
      }),
    [apiFacility, setFacility]
  );

  // Initial Load
  useEffect(() => {
    if (isMounted()) {
      apiFrequency.callGet().then(({status, data}) => {
        if (status === 200 && data) setFrequencies(data);
      });
      apiUnits.callGet().then(({status, data}) => {
        if (status === 200 && data) setUnits(data);
      });

      if (facility === null) getFacility();
    }
  }, [isMounted, apiFrequency, apiUnits, facility, getFacility]);

  // Set preview based on selected frequency
  useEffect(() => {
    if (watchFrequency !== "none" && watchStartDate) {
      let params;

      if (watchFrequency !== "custom") params = {start: watchStartDate, name: watchFrequency};

      if (watchFrequency === "custom" && watchInterval && watchBase) {
        params = {
          start: watchStartDate,
          interval: Number.parseInt(watchInterval, 10),
          base: watchBase,
          exclude:
            watchDueDays && watchDueDays.length > 1
              ? DAY_IDX.filter(idx => !watchDueDays.includes(IDX_TO_DAY[idx]))
              : null
        };
        if (watchAvailability) {
          params.availableBase =
            watchBase.includes("month") || watchInterval === 1 ? "months" : "years";
          params.availableIndex = watchAvailability;
        }
      }

      if (params)
        apiFrequency.callGet(null, params).then(({status, data}) => {
          if (status === 200 && data) setStartPreview(data);
        });
    }
  }, [
    apiFrequency,
    watchStartDate,
    watchLastDay,
    watchFrequency,
    watchInterval,
    watchBase,
    watchDueDays,
    watchAvailability
  ]);

  const hasLastDayOption = useMemo(() => {
    if (!watchFrequency) return false;
    if (watchFrequency === "custom")
      return watchBase?.includes("month") || watchBase?.includes("year");
    return !NO_LAST_DAY_OPTION.includes(watchFrequency);
  }, [watchBase, watchFrequency]);

  const availableOptions = useMemo(() => {
    if (
      watchFrequency !== CUSTOM_OPTION.name ||
      !["month", "months", "year", "years"].includes(watchBase)
    )
      return [];

    const options = [];

    const parsedInterval = parseInt(watchInterval, 10);

    if (exists(parsedInterval) && !Number.isNaN(parsedInterval)) {
      if (watchBase?.includes("month") && parsedInterval > 1) {
        for (let i = 1; i < parsedInterval + 1; i++) {
          options.push({name: i, label: `${i}${getNumberSuffix(i)} Month`});
        }
      }

      if (watchBase?.includes("year")) {
        if (parsedInterval === 1) {
          for (let i = 1; i < 13; i++) {
            options.push({name: i, label: `${i}${getNumberSuffix(i)} Month`});
          }
        } else {
          for (let i = 1; i < parsedInterval + 1; i++) {
            options.push({name: i, label: `${i}${getNumberSuffix(i)} Year`});
          }
        }
      }
    }

    return options;
  }, [watchFrequency, watchBase, watchInterval]);

  const showAvailable = useMemo(() => {
    if (watchFrequency !== CUSTOM_OPTION.name) return false;
    if (watchBase?.includes("month"))
      return watchInterval && watchInterval > 1 && watchInterval < 24;
    if (watchBase?.includes("year")) return watchInterval && watchInterval < 10;
    return false;
  }, [watchBase, watchFrequency, watchInterval]);

  const frequencyWarning = useMemo(() => {
    if (
      (watchFrequency === "monthly" || watchFrequency === "bi-monthly" || watchBase === "months") &&
      dayjs(watchStartDate).date() > 28
    )
      return `For months with less or greater than 
          ${dayjs(watchStartDate).date()} days, this checksheet will be due on the last day of the
          month`;

    if (watchFrequency === "semi-monthly")
      return "Due date defaults to the middle or last day of the month for semi-monthly tasks.";

    if (watchFrequency === "quarterly")
      return "Due date defaults to the last day of the quarter for quarterly tasks.";

    if (watchFrequency === "quadrimesterly")
      return "Start date defaults to the first day of the year and is due every four months.";

    if (watchFrequency === "semi-annually")
      return "Start date defaults to the first day of the year and is due every six months.";

    if (watchFrequency === CUSTOM_OPTION.name && watchBase?.includes("month"))
      if (watchInterval <= 1)
        return "Availability window not supported for intervals of less than 2 months.";
      else if (watchInterval >= 24)
        return "Availability window not supported for intervals of 24 months or longer.";

    if (watchFrequency === CUSTOM_OPTION.name && watchBase?.includes("year"))
      if (watchInterval > 10)
        return "Availability window not supported for intervals of longer than 10 years.";

    return "";
  }, [watchFrequency, watchBase, watchStartDate, watchInterval]);

  const toggledElements = updated => {
    for (let i = 0; i < updated.allIds.length; i++) {
      if (updated.byId[updated.allIds[i]].toggle) return true;
    }
    return false;
  };

  const saveBuilder = useCallback(
    (updated, newUnits, templates, previousById) => {
      setError(null);
      // Maintain list of templates that have been applied
      const tempAppliedTemplates = {...appliedTemplates};
      if (templates && Object.keys(templates).length > 0 && previousById) {
        const newTemplate = Object.keys(templates)[0];
        const components = templates[newTemplate];
        // Link if template is applied or component is manually linked
        if (components && newTemplate && newTemplate !== "unlink") {
          components.forEach(component => {
            const oldTemplate =
              component in previousById ? previousById[component].templateId : null;

            // Template link needs to be overrided in favor of new link
            if (oldTemplate && oldTemplate !== newTemplate && oldTemplate in tempAppliedTemplates) {
              const temp = tempAppliedTemplates[oldTemplate].filter(c => c !== component);
              if (temp.length > 0) tempAppliedTemplates[oldTemplate] = temp;
              else delete tempAppliedTemplates[oldTemplate];
            }

            // Inlitialize/Add component list on Template
            if (
              newTemplate in tempAppliedTemplates &&
              !(component in tempAppliedTemplates[newTemplate])
            )
              tempAppliedTemplates[newTemplate].push(component);
            else tempAppliedTemplates[newTemplate] = [component];
          });
        }

        // Unlink if user manually unlinks a template
        if (components && newTemplate && newTemplate === "unlink")
          components.forEach(component => {
            const oldTemplate =
              component in previousById ? previousById[component].templateId : null;
            if (oldTemplate && oldTemplate !== newTemplate && oldTemplate in tempAppliedTemplates) {
              const temp = tempAppliedTemplates[oldTemplate].filter(c => c !== component);
              if (temp.length > 0) tempAppliedTemplates[oldTemplate] = temp;
              else delete tempAppliedTemplates[oldTemplate];
            }
          });
      }

      if (newUnits && newUnits.length > 0) {
        const temp = newUnits.filter(unit => !unitsToAdd.includes(unit));
        setUnitsToAdd(prev => [...prev, ...temp]);
      }

      const key = `${getSnakeCase(facility.slug)}.temp_checksheet.builder`;
      setWithExpiry(
        key,
        JSON.stringify(updated),
        (process.env.REACT_APP_EXPIRATION_IN_MINUTES ?? 10) * 60 // 10 minute default
      );

      setHasElements(!!updated.allIds && updated.allIds.length > 0);

      setHasToggledOn(!!toggledElements(updated));

      if (templates) setAppliedTemplates(tempAppliedTemplates);

      setBuilder(updated);
    },
    [appliedTemplates, facility, unitsToAdd]
  );

  const saveNotifications = useCallback(
    notifications => {
      const key = `${getSnakeCase(facility.slug)}.temp_checksheet.notifications`;
      setWithExpiry(
        key,
        JSON.stringify(notifications),
        (process.env.REACT_APP_EXPIRATION_IN_MINUTES ?? 10) * 60 // 10 minute default
      );

      setFieldNotifications(notifications);
    },
    [facility]
  );

  // Handle locally stored states
  useEffect(() => {
    if (isMounted() && facility?.name) {
      // if (setFacilityInContext) setFacilityInContext(facility);

      // Checksheet Builder
      const builderKey = `${getSnakeCase(facility.slug)}.temp_checksheet.builder`;
      const storedBuilder = JSON.parse(getWithExpiry(builderKey));
      if (storedBuilder?.allIds?.length > 0) {
        saveBuilder(storedBuilder);
        setHasToggledOff(getToggleState(storedBuilder.allIds, storedBuilder));
      } else saveBuilder({allIds: [], byId: {}});

      // Checksheet Notifications
      const notificationsKey = `${getSnakeCase(facility.slug)}.temp_checksheet.notifications`;
      const storedNotifications = JSON.parse(getWithExpiry(notificationsKey));
      if (storedNotifications?.allIds?.length > 0) saveNotifications(storedNotifications);
    }
  }, [isMounted, facility, saveBuilder, saveNotifications]);

  // Handle filtering
  useEffect(() => {
    const {byId} = builder;

    if (filters?.types || filters?.tags)
      setFilterResult(
        Object.fromEntries(
          Object.entries(byId).filter(
            ([, {tag, type}]) =>
              filters.types?.includes(type) || (tag && filters.tags?.includes(tag))
          )
        )
      );
    else {
      setFilters(null);
      setFilterResult(null);
    }
  }, [filters, builder]);

  const handleClear = () => {
    setFilters(null);
    setFilterResult(null);
    setShowFilterModal(false);
  };

  const onDragStart = provided => {
    const {droppableId} = provided.source;
    setActiveDroppableId(droppableId);
  };

  // re-ordering list
  const onDragEnd = result => {
    const {destination, source, type} = result;

    if (!destination) return;

    let updatedBuilder;
    const builderCopy = {...builder};

    if (type === "DEFAULT") {
      const allIds = [...builder.allIds];

      const [removed] = allIds.splice(source.index, 1);
      allIds.splice(destination.index, 0, removed);

      updatedBuilder = {...builderCopy, allIds};

      saveBuilder(updatedBuilder);
    }

    if (type === source.droppableId) {
      const byId = {...builder.byId};

      const parent = byId[type];
      const {children} = parent;

      const [removed] = children.splice(source.index, 1);
      children.splice(destination.index, 0, removed);

      const help = sortHelp(parent.help, children, byId);

      updatedBuilder = {...builderCopy, byId: {...byId, [type]: {...parent, children, help}}};

      saveBuilder(updatedBuilder);
    }
  };

  const recursePreview = (elementName, updatedById) => {
    if (
      updatedById[elementName] === undefined ||
      (!updatedById[elementName].toggle && updatedById[elementName].element !== FIELD)
    )
      return updatedById;

    updatedById[elementName].preview = !updatedById[elementName].preview;

    const {children} = updatedById[elementName];
    if (children && children.length > 0)
      children.forEach(child => {
        updatedById = recursePreview(child, updatedById);
      });

    return updatedById;
  };

  const togglePreview = elementName => {
    const updatedById = recursePreview(elementName, {...builder.byId});
    setBuilder({...builder, byId: {...updatedById}});
  };

  const addTemplate = (componentName, templateLabel) => {
    const component = builder.byId[componentName];
    const fields = builder.byId[componentName].children.map(child => ({...builder.byId[child]}));
    const nameMap = {};
    const tempBuilder = {byId: {}};
    const extern = {};
    let extIdx = 0;

    // iterate over each field to add it to the temporary builder.
    // this is for resolving mismatched dependencies due to the template
    // being removed from the context of the current builder
    fields.forEach(field => {
      delete field.preview;
      delete field.toggle;
      if ("linkedGroup" in field) delete field.linkedGroup;
      if ("linkedComponent" in field) delete field.linkedComponent;
      const name = generateUniqueKey(field.label);
      nameMap[field.name] = name;
      field.name = name;

      // if a field has a formula, iterate over each part to set up
      // nameMap and the temporary builder (for resolving dependencies)
      if (field.formula)
        field.formula
          .filter(({value}) => value in builder.byId)
          .map(({value}) => {
            const item = builder.byId[value];
            // any items in formula that fall outside component
            // are reassigned to name ext_ where _ represents a unique integer
            if (item.parentName !== componentName) {
              let key = nameMap[value];
              // if field name already exists in nameMap, use that mapping instead of
              // generating a new ext_ key
              if (!key) {
                nameMap[value] = `ext${extIdx}`;
                key = `ext${extIdx}`;
                extIdx++;
              }
              if (!(key in extern)) {
                tempBuilder.byId[key] = {
                  ...item,
                  name: key,
                  formula: undefined,
                  condition: undefined
                };
                extern[key] = {
                  label: item.label,
                  type: item.type
                };
              }
            }
          });

      // if a field has conditions, iterate over each condition in list to set up
      // nameMap and the temporary builder (for resolving dependencies)
      if (field.condition && field.condition.list)
        field.condition.list
          .filter(({depends}) => depends in builder.byId)
          .map(({depends}) => {
            const item = builder.byId[depends];

            // any items in condition that fall outside component
            // are reassigned to name ext_ where _ represents a unique integer
            if (item.parentName !== componentName) {
              let key = nameMap[depends];
              // if field name already exists in nameMap, use that mapping instead of
              // generating a new ext_ key
              if (!key) {
                nameMap[depends] = `ext${extIdx}`;
                key = `ext${extIdx}`;
                extIdx++;
              }

              if (!(key in extern)) {
                tempBuilder.byId[key] = {
                  ...item,
                  name: key,
                  formula: undefined,
                  condition: undefined
                };
                extern[key] = {
                  label: item.label,
                  type: item.type
                };
              }
            }
          });

      tempBuilder.byId[field.name] = {
        ...field,
        formula: field.formula ? [...field.formula.map(part => ({...part}))] : null,
        condition: field.condition
          ? {...field.condition, list: [...field.condition.list.map(item => ({...item}))]}
          : null
      };
    });

    resolveDependencyMismatches({byId: {...builder.byId, ...tempBuilder.byId}}, nameMap);
    const templateToAdd = {
      label: templateLabel,
      options: {
        grid: component.grid,
        help: component?.help?.map(item => ({
          ...item,
          id: item.id in nameMap ? nameMap[item.id] : ""
        })),
        external: extern
      },
      fields: Object.values(tempBuilder.byId).filter(({name}) => !name.match("^ext[0-9]*$"))
    };
    apiBase.callPost({...templateToAdd}).then(() => {
      setRefreshTemplates(true);
      setTarget(null);
    });
  };

  const handleEdit = (update, unlinkTemplate, updatedBuilder) => {
    const currentBuilder = updatedBuilder ?? builder;
    const newUnits = [];
    if (update.units && !units.includes(update.units)) {
      setUnits(prev => [...prev, update.units].sort());
      newUnits.push(update.units);
    }
    if (update.type !== builder.byId[update.name].type) handleClear();
    const {builder: updated, notifications: updatedNotifications} = updateElement(
      currentBuilder,
      update,
      true,
      undefined,
      true,
      false,
      fieldNotifications
    );
    saveBuilder(
      updated,
      newUnits,
      unlinkTemplate ? {unlink: [update.name]} : null,
      currentBuilder.byId
    );
    saveNotifications(updatedNotifications);
    setShowModalEdit(false);
  };

  const handleDelete = (element, notificationsToDelete) => {
    // Handle builder element removal
    const updatedBuilder = removeElement(builder, element);
    saveBuilder(updatedBuilder);

    // Handle notification delete
    if (notificationsToDelete && notificationsToDelete.length > 0) {
      const updatedNotifications = {...fieldNotifications};
      notificationsToDelete.map(id => {
        if (Object.keys(updatedNotifications.byId).includes(id) && updatedNotifications.byId[id])
          // Delete field notification object
          delete updatedNotifications.byId[id];

        const temp = updatedNotifications.allIds.indexOf(id);
        updatedNotifications.allIds.splice(temp, 1);
      });
      saveNotifications(updatedNotifications);
    }

    setShowModalDelete(false);
  };

  const handleAddField = (field, updatedBuilder) => {
    const currentBuilder = updatedBuilder ?? builder;
    handleClear();
    const newUnits = [];
    if (field.units && !units.includes(field.units)) {
      setUnits(prev => [...prev, field.units].sort());
      newUnits.push(field.units);
    }
    const temp = {...field, toggle: true};
    const updated = addField(currentBuilder, temp);
    saveBuilder(updated, newUnits);
  };

  const handleAddMultipleFields = fields => {
    handleClear();
    const newUnits = [];
    let updated = {...builder};
    fields.map(field => {
      if (field.units && !units.includes(field.units)) {
        setUnits(prev => [...prev, field.units].sort());
        newUnits.push(field.units);
      }
      const temp = {...field, toggle: true};
      updated = addField(updated, temp);
    });
    saveBuilder(updated, newUnits);
  };

  const handleFormula = (field, updatedBuilder) => {
    if (field.type === "generated") handleEdit(field, false, updatedBuilder);
    else
      handleAddField(
        {
          ...field,
          type: "generated",
          disabled: true
        },
        updatedBuilder
      );
  };

  const handleConditional = values => {
    const {parentName} = target;
    const update = {
      ...target,
      condition: values.condition
    };
    if (parentName) {
      const parentElement = builder.byId[parentName];
      const parentHelp = parentElement.help;
      const helpItem = parentHelp?.find(({id}) => id === target.name);
      if (helpItem?.value) update.help = helpItem.value;
    }
    handleEdit(update);
  };

  const cloneElement = targetElement => {
    const {element, label, name, parentName} = targetElement;

    const newLabel = `${label} COPY`;
    if (labelAlreadyExists(newLabel, name, parentName, builder)) {
      const parentLabel = parentName ? builder.byId[parentName]?.label : null;
      addToast(
        parentLabel
          ? `Field name already exists in ${parentLabel}`
          : "Field name already exists on top level",
        "error"
      );
    } else {
      const clone = {
        ...builder.byId[name],
        label: `${label} COPY`,
        name: null,
        toggle: true,
        condition: null
      };
      const nameMap = {};

      let updated;
      if (element === "field") updated = addField(builder, clone);
      if (element === "component") updated = addComponent(builder, clone, nameMap);
      if (element === "group") {
        const cloneIdx = builder.allIds.indexOf(name) + 1;
        updated = addGroup(builder, {...clone, cloneIdx}, nameMap);
      }

      resolveDependencyMismatches(updated, nameMap);
      saveBuilder(updated);
    }
  };

  const draft = useRef(false);
  const handlePublish = () => {
    // validate builder structure
    const {allIds} = builder;
    const checksheetBuilder = newFromBuilder(allIds, {...builder}, {allIds: [], byId: {}});

    const errorState = !validBuilder(checksheetBuilder.allIds, checksheetBuilder);
    const errorMessage =
      "Invalid builder, please make sure all Components and Groups have at least one field or there is one checksheet level field.";
    setError(errorState ? errorMessage : null);

    if (!errorState) {
      const checksheet = {
        facilityId: facility.id,
        name: watchName,
        builder: {...checksheetBuilder},
        templates: appliedTemplates,
        notifications: {...fieldNotifications},
        external,
        checksheetRestrictions
      };

      if (watchFrequency === "custom") {
        checksheet.interval = Number.parseInt(watchInterval, 10);
        checksheet.base = watchBase;

        if (watchDueDays?.length > 1)
          checksheet.exclude = DAY_IDX.filter(idx => !watchDueDays.includes(IDX_TO_DAY[idx]));

        if (showAvailable && checksheet.base && watchAvailability) {
          checksheet.availableBase =
            checksheet.base.includes("month") || checksheet.interval === 1 ? "months" : "years";
          checksheet.availableIndex = watchAvailability;
        }
      }

      if (watchFrequency !== "none") {
        checksheet.startDate = watchStartDate;
        checksheet.lastDay = watchLastDay;

        if (watchFrequency !== "custom") checksheet.frequency = watchFrequency;
      }

      if (draft.current === true) setLoadingDraft(true);
      else setLoadingPublish(true);

      if (unitsToAdd && unitsToAdd.length > 0) checksheet.units = unitsToAdd;

      if (draft.current === true) checksheet.draft = true;

      if (restrictTo?.length > 0) {
        if (restrictTo[0]?.publicId)
          checksheet.restrictToUser = restrictTo.map(({publicId}) => publicId);
        else checksheet.restrictToRole = restrictTo.map(({id}) => id);
      }

      apiChecksheet
        .callPost({...checksheet})
        .then(({status}) => {
          if (status === 201) {
            getNotifications(slug);
            // reset states
            reset({
              name: "",
              type: "",
              role: "",
              frequency: "",
              draft: false,
              recurrence: {
                interval: 1,
                base: "days",
                endDate: null,
                ends: NEVER,
                days: null
              }
            });
            setHasToggledOff(true);
            saveBuilder({allIds: [], byId: {}});
            saveNotifications({allIds: [], byId: {}});
            setUnitsToAdd([]);
            setAppliedTemplates({});
            setRestrictTo([]);
            setExternal(false);
            setChecksheetRestrictions([]);
          }
        })
        .finally(() => {
          setLoadingPublish(false);
          setLoadingDraft(false);
        });
    }
  };

  const toggleAll = () => {
    if (builder && builder.allIds && builder.allIds.length > 0) {
      setLoadingToggleAll(true);
      const updatedBuilder = recurseToggle(builder.allIds, builder, hasToggledOff);
      setHasToggledOff(!hasToggledOff);
      saveBuilder({...updatedBuilder});
      setLoadingToggleAll(false);
    }
  };

  const defaultDate = useMemo(() => {
    let date = dayjs();
    if (["weekly", "bi-weekly"].includes(watchFrequency)) date = date.endOf("week").add(1, "day");
    if (["monthly", "bi-monthly"].includes(watchFrequency)) date = date.endOf("month");
    if (["annually", "biennially", "triennially"].includes(watchFrequency))
      date = date.endOf("year");

    if (watchFrequency === "semi-monthly") {
      const midCurrentMonth = Math.floor(date.daysInMonth() / 2);

      if (date.date() > midCurrentMonth) {
        date = date.date(date.daysInMonth());
      } else {
        date = date.date(midCurrentMonth);
      }
    }

    if (watchFrequency === "quarterly") {
      const currentMonth = date.month();
      let nextMonth = currentMonth;

      if (nextMonth >= 0 && nextMonth < 3) nextMonth = 2;
      if (nextMonth >= 3 && nextMonth < 6) nextMonth = 5;
      if (nextMonth >= 6 && nextMonth < 9) nextMonth = 8;
      else nextMonth = 11;

      date = date.date(1);
      date = date.month(nextMonth);

      const quarterEnd = date.daysInMonth();
      date = date.date(quarterEnd);
    }

    if (watchFrequency === "quadrimesterly") {
      const currentMonth = date.month();
      let nextMonth = currentMonth;

      if (nextMonth >= 0 && nextMonth < 4) nextMonth = 3;
      if (nextMonth >= 4 && nextMonth < 8) nextMonth = 7;
      else nextMonth = 11;

      date = date.date(1);
      date = date.month(nextMonth);

      const quarterEnd = date.daysInMonth();
      date = date.date(quarterEnd);
    }

    if (watchFrequency === "semi-annually") {
      const currentMonth = date.month();
      let nextMonth = currentMonth;

      if (nextMonth >= 0 && nextMonth < 6) nextMonth = 5;
      else nextMonth = 11;

      date = date.date(1);
      date = date.month(nextMonth);

      const quarterEnd = date.daysInMonth();
      date = date.date(quarterEnd);
    }

    return {
      date: date.format("YYYY-MM-DD"),
      visibleDate: date.format("MM/DD/YYYY"),
      lastDay: false
    };
  }, [watchFrequency]);

  // Reset Frequency when switching from custom frequency
  useEffect(() => {
    setValue("recurrence", {
      interval: 1,
      base: "days",
      endDate: null,
      ends: NEVER,
      days: null
    });
  }, [watchFrequency, setValue]);

  // Initial values on frequency change
  useEffect(() => {
    if (watchFrequency) {
      setExternal(false);

      if (watchFrequency === "monthly") setValue("end", true);
      else setValue("end", undefined);

      if (["weekly", "bi-weekly"].includes(watchFrequency)) setValue("recurrence.days", ["Sun"]);

      setValue("start", defaultDate);
    }
  }, [watchFrequency, defaultDate, setValue]);

  // Custom Frequency (allows multiple day selection) -> update dueDay depending on startDate
  useEffect(() => {
    const startDateWeekday = dayjs(watchStartDate).day();
    if (
      (watchBase === "weeks" || watchFrequency === "weekly" || watchFrequency === "bi-weekly") &&
      startDateWeekday &&
      DAY_NAMES_DAYJS[startDateWeekday] &&
      !skipDayCorrection
    ) {
      const targetWeekday = toTitleCase(DAY_NAMES_DAYJS[startDateWeekday]);
      setSkipDateCorrection(true);

      if (
        (!watchDueDays || watchFrequency === "weekly" || watchFrequency === "bi-weekly") &&
        exists(startDateWeekday)
      )
        setValue("recurrence.days", [targetWeekday]);
      else if (targetWeekday && watchDueDays && !watchDueDays.includes(targetWeekday)) {
        setValue(
          "recurrence.days",
          [...watchDueDays, targetWeekday].sort(day => DAY_TO_IDX_DAYJS[day.toLowerCase()])
        );
      }
    } else setSkipDayCorrection(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchBase, watchStartDate, skipDayCorrection, setValue]);

  // Custom Frequency (allows multiple day selection) -> updates startDate depending on dueDays
  useEffect(() => {
    if (
      (watchBase === "weeks" || watchFrequency === "weekly" || watchFrequency === "bi-weekly") &&
      watchDueDays?.length > 0 &&
      !skipDateCorrection
    ) {
      const startDateWeekday = dayjs(watchStartDate).day();
      if (!watchDueDays.includes(IDX_TO_DAY_DAYJS[startDateWeekday])) {
        let i = dayjs().day();
        let hasResetToSunday = false;
        while (!watchDueDays.includes(IDX_TO_DAY_DAYJS[i])) {
          if (i === 6) {
            // prevents infinite loop if for whatever reason watchDueDays does not contain any valid days
            if (hasResetToSunday) break;
            hasResetToSunday = true;
            i = 0;
          } else i++;
        }
        let start = dayjs(watchStartDate).day(i);

        if (start.isBefore(dayjs())) start = start.add(1, "week");

        setSkipDayCorrection(true);
        setValue("start.date", start.format("YYYY-MM-DD"));
        setValue("start.visibleDate", start.format("MM/DD/YYYY"));
      }
    } else setSkipDateCorrection(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchBase, watchDueDays, skipDateCorrection, setValue]);

  const buildPreview = () => {
    const checksheetBuilder = newFromBuilder(builder.allIds, {...builder}, {allIds: [], byId: {}});
    setPreview({builder: checksheetBuilder, name: watchName, frequency: watchFrequency});
    setShowPreview(true);
  };

  const handleShowModalFormula = element => {
    if (element.type === "generated") setShowModalRedirectFormula(true);
    else setShowModalFormula(true);
  };

  const handleShowModalElapsed = element => {
    if (element.type === "generated") setShowModalRedirectElapsed(true);
    else {
      setElapsedMode("create");
      setShowModalElapsed(true);
    }
  };

  const firstDueDate = useMemo(() => {
    if (startPreview && watchAvailability && watchFrequency === CUSTOM_OPTION.name) {
      let due = convertToUserTimezone(startPreview, settings.timezone)
        .add(1, watchBase.includes("month") || watchInterval === 1 ? "month" : "year")
        .subtract(1, "minute");

      if (watchLastDay) due = due.date(due.daysInMonth());

      return due.format("MM/DD/YYYY, h:mm A");
    }
    return null;
  }, [
    startPreview,
    watchAvailability,
    watchBase,
    watchFrequency,
    watchInterval,
    watchLastDay,
    settings
  ]);

  const minDateFromAvailabilityWindow = useMemo(() => {
    if (watchInterval && watchAvailability && watchBase) {
      let due = dayjs();
      if (watchInterval === 1 && watchBase.includes("year"))
        due = due.add(
          12 - watchAvailability,
          watchBase.includes("month") || watchInterval === 1 ? "month" : "year"
        );
      else
        due = due.add(
          watchInterval - watchAvailability,
          watchBase.includes("month") || watchInterval === 1 ? "month" : "year"
        );

      if (watchLastDay) due = due.date(due.daysInMonth());

      return due.format("YYYY-MM-DD");
    }
    return null;
  }, [watchBase, watchInterval, watchLastDay, watchAvailability]);

  const minDate = useMemo(() => {
    if (minDateFromAvailabilityWindow) return minDateFromAvailabilityWindow;
    return dayjs().format("YYYY-MM-DD");
  }, [minDateFromAvailabilityWindow]);

  useEffect(() => {
    if (dayjs(watchStartDate).isBefore(dayjs(minDate))) {
      setValue("start.date", minDate);
      setValue("start.visibleDate", dayjs(minDate).format("MM/DD/YYYY"));
      setValue("start.lastDay", false);
    }
  }, [watchStartDate, minDate, setValue]);

  if (!roleCanAccessResource("facility_checksheet", "create"))
    return <Navigate to="/unauthorized" />;

  return (
    <Page hasMenu>
      <Room name="checksheet-builder" active={active} setActive={setActive} />

      {facility?.name && (
        <FacilityPageHeader
          facility={facility}
          path="/checksheet-builder"
          reloadData={() => setFacility(null)}
        />
      )}

      <HeadingMedium>Checksheet Builder</HeadingMedium>

      <Wrapper>
        <PageLeft>
          {loadingFacility ? (
            <Loader data-testid="checksheet.loader" />
          ) : (
            <>
              <FormProvider {...form}>
                <Form onSubmit={handleSubmit(handlePublish)} noValidate>
                  <InlineWrapper inline>
                    <InputText name="name" placeholder="Name" testId="checksheet.name" required />
                    {frequencies?.length > 0 && (
                      <InputSelect
                        name="frequency"
                        placeholder="Frequency..."
                        testId="checksheet.frequency"
                        options={[
                          {name: "none", label: "None"},
                          ...frequencies,
                          {name: "custom", label: "Custom..."}
                        ]}
                        required
                      />
                    )}
                  </InlineWrapper>

                  {watchFrequency === CUSTOM_OPTION.name && (
                    <CustomGroup fitcontent>
                      <FormField>
                        <Label bold>REPEAT EVERY</Label>
                        <CustomGroup>
                          <InputNumber
                            testId="interval"
                            name="recurrence.interval"
                            min={1}
                            max={10}
                            maxWidth
                            required={false}
                            disableInverse
                            hideError
                          />
                          <BaseSelectWrapper>
                            <InputSelect
                              testId="base"
                              fullWidth
                              name="recurrence.base"
                              options={watchInterval > 1 ? BASE_OPTIONS_PLURAL : BASE_OPTIONS}
                            />
                          </BaseSelectWrapper>
                        </CustomGroup>
                      </FormField>
                      {showAvailable && (
                        <InputSelect
                          name="recurrence.availability"
                          label="AVAILABLE"
                          options={availableOptions}
                          placeholder="Always"
                        />
                      )}
                      <InputError errors={errors} name="recurrence.interval" />
                    </CustomGroup>
                  )}

                  {(watchFrequency === "weekly" ||
                    watchFrequency === "bi-weekly" ||
                    watchBase === "weeks") && (
                    <InputDay
                      name="recurrence.days"
                      restrictOne={watchFrequency !== CUSTOM_OPTION.name}
                      restrictWarning={
                        watchFrequency === CUSTOM_OPTION.name
                          ? "Multiple day selection not currently supported for intervals longer than 1 week"
                          : null
                      }
                    />
                  )}

                  {watchFrequency && watchFrequency !== "none" && (
                    <DatesWrapper>
                      <Inline>
                        <DateWrapper selfInline>
                          <Label bold>AVAILABLE DATE</Label>
                          <Input
                            type="text"
                            value={
                              startPreview &&
                              prettyDateInUserTimezone(startPreview, null, "MM/DD/YYYY, h:mm A")
                            }
                            disabled
                          />
                        </DateWrapper>
                        {watchAvailability && (
                          <DateWrapper selfInline>
                            <Label bold>FIRST DUE DATE</Label>
                            <Input type="text" value={firstDueDate} disabled />
                          </DateWrapper>
                        )}
                      </Inline>
                      <DateWrapper selfInline>
                        <InputCalendar
                          name="start"
                          label={watchAvailability ? "INTERVAL END DATE" : "FIRST DUE DATE"}
                          showLastDay={hasLastDayOption}
                          min={minDate}
                          disabled={
                            watchFrequency === "semi-monthly" ||
                            watchFrequency === "quarterly" ||
                            watchFrequency === "quadrimesterly" ||
                            watchFrequency === "semi-annually"
                          }
                        />
                      </DateWrapper>
                    </DatesWrapper>
                  )}

                  {frequencyWarning && (
                    <WarningWrapper>
                      <WarningHeader>Warning!</WarningHeader>&nbsp;{frequencyWarning}
                    </WarningWrapper>
                  )}

                  <Options>
                    <SaveDraft
                      type="submit"
                      data-testid="checksheet.saveDraft"
                      onClick={() => {
                        draft.current = true;
                      }}
                      loading={loadingDraft ? 1 : 0}>
                      {loadingDraft && <ButtonLoader />}
                      Create as Draft
                    </SaveDraft>
                    <Submit
                      type="submit"
                      data-testid="checksheet.submit"
                      onClick={() => {
                        draft.current = false;
                      }}
                      loading={loadingPublish ? 1 : 0}>
                      {loadingPublish && <ButtonLoader />}
                      Create and Publish
                    </Submit>
                  </Options>
                </Form>
              </FormProvider>
              {facility && (
                <Sticky>
                  <MemberRestrict
                    restrictTo={restrictTo}
                    external={external}
                    showModalEdit={setShowModalMemberRestrict}
                  />
                  <ChecksheetRestrictions
                    showModalEdit={setShowModalRestriction}
                    restrictions={checksheetRestrictions}
                  />
                  <Notifications
                    showModalEdit={setShowModalNotification}
                    notifications={fieldNotifications}
                  />
                  {roleCanAccessResource("component", "view") && (
                    <ComponentTemplates
                      setTemplate={setTemplate}
                      setShowModalTemplate={setShowModalTemplate}
                      setRefresh={setRefreshTemplates}
                      refresh={refreshTemplates}
                      setTemplateList={setTemplateList}
                      templateList={templateList}
                      hasPrimaryAddress={!!facility?.primaryAddress}
                    />
                  )}
                </Sticky>
              )}
            </>
          )}
        </PageLeft>
        <PageRight>
          <Builder data-testid="checksheet.preview">
            <Menu>
              <MenuLeft isEmpty={builder?.allIds?.length > 0 ? 0 : 1}>
                <Heading>
                  <Abbr title={watchName.toUpperCase()}>
                    {watchName.length > 0 ? watchName.toUpperCase() : "Name..."}
                  </Abbr>
                </Heading>
                {watchFrequency !== "None" && watchFrequency.length > 0 && (
                  <Pill>{watchFrequency}</Pill>
                )}
              </MenuLeft>
              <MenuRight>
                <Filters>
                  <FilterButton type="button" onClick={() => setShowFilterModal(true)}>
                    <Badge count={filterCount} offset="14px" color={colors.heroGreen} />
                    <FontAwesomeIcon icon={faFilter} />
                  </FilterButton>
                </Filters>

                {builder && (
                  <Apply
                    type="button"
                    onClick={() => {
                      if (hasElements) {
                        setHasToggledOff(true);
                        saveBuilder({allIds: [], byId: {}});
                      } else if (facility?.builder) {
                        setHasToggledOff(
                          !!facility.builder.allIds && facility.builder.allIds.length > 0
                        );
                        saveBuilder({
                          byId: {
                            ...Object.fromEntries(
                              Object.entries(facility.builder.byId).map(([key, val]) => [
                                key,
                                {...val, children: val.children ? [...val.children] : val.children}
                              ])
                            )
                          },
                          allIds: [...facility.builder.allIds]
                        });
                      }
                    }}
                    hasElements={hasElements}
                    data-testid={hasElements ? "checksheet.reset" : "checksheet.facilityBuilder"}>
                    {hasElements ? "Reset" : "Use Facility Builder"}
                  </Apply>
                )}
                {hasToggledOff !== null && hasElements && (
                  <Button
                    type="button"
                    onClick={toggleAll}
                    data-testid="checksheet.toggleAll"
                    loading={loadingToggleAll ? 1 : 0}>
                    {loadingToggleAll && <ButtonLoader />}
                    <Abbr title={hasToggledOff ? "Toggle On" : "Toggle Off"}>
                      {hasToggledOff ? "Toggle On" : "Toggle Off"}
                    </Abbr>
                  </Button>
                )}
                {hasToggledOn && (
                  <Button onClick={buildPreview}>
                    <Abbr title="Preview">Preview All</Abbr>
                  </Button>
                )}
              </MenuRight>
            </Menu>

            <Errors>
              {external && restrictTo?.length > 0 && (
                <Warning>
                  Warning:&nbsp;
                  <span>
                    Checksheet available for completion to&nbsp;
                    {restrictTo
                      .map(({firstName, lastName, label}) =>
                        firstName ? `${firstName} ${lastName}` : label
                      )
                      .join(", ")}
                    &nbsp;via email only.
                  </span>
                </Warning>
              )}
              {watchFrequency === "None" && (
                <Warning>
                  Warning:
                  <span>
                    Checksheet will be created without due date. Completed on an as needed basis.
                  </span>
                </Warning>
              )}
              {error && <Error>{error}</Error>}
            </Errors>

            <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
              <Droppable
                droppableId="facility.builder"
                isDropDisabled={activeDroppableId !== "facility.builder"}>
                {provided => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    <RenderChecksheetBuilder
                      builder={builder}
                      filterResult={filterResult}
                      setBuilder={saveBuilder}
                      activeDroppableId={activeDroppableId}
                      setTarget={setTarget}
                      cloneElement={cloneElement}
                      setShowModalSaveTemplate={setShowModalSaveTemplate}
                      togglePreview={togglePreview}
                      setHasToggledOff={setHasToggledOff}
                      templates={templateList}
                      setShowModalHelp={setShowModalHelp}
                      setShowModalEdit={setShowModalEdit}
                      setShowModalAdd={setShowModalAdd}
                      setShowModalDelete={setShowModalDelete}
                      setShowModalConditional={setShowModalConditional}
                      setShowModalFormula={handleShowModalFormula}
                      setShowModalElapsed={handleShowModalElapsed}>
                      {provided.placeholder}
                    </RenderChecksheetBuilder>
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </Builder>
        </PageRight>
      </Wrapper>

      {/* Modals */}
      {showModalHelp && target && (
        <ModalHelp visible={showModalHelp} setVisible={setShowModalHelp} target={target} />
      )}
      {showModalEdit && target && (
        <ModalEditElement
          visible={showModalEdit}
          setVisible={setShowModalEdit}
          targetElement={target}
          persistEdit={handleEdit}
          builder={builder}
          units={units}
          templateLabel={
            target.templateId && templateList ? getTemplateLabel(target.templateId) : null
          }
          editing={false}
        />
      )}

      {showModalAdd && target && (
        <ModalAddField
          visible={showModalAdd}
          setVisible={setShowModalAdd}
          addFields={handleAddMultipleFields}
          parent={target}
          builder={builder}
          units={units}
        />
      )}

      {showModalRedirectFormula && (
        <ModalRedirect
          visible={showModalRedirectFormula}
          setVisible={setShowModalRedirectFormula}
          buttonOptions={[
            {
              action: () => {
                setFormulaMode("create");
                setShowModalFormula(true);
                setShowModalRedirectFormula(false);
              },
              description: "Create formula based on this field",
              icon: faPlus
            },
            {
              action: () => {
                setFormulaMode("edit");
                setShowModalFormula(true);
                setShowModalRedirectFormula(false);
              },
              description: "Edit existing formula field",
              icon: faEdit
            }
          ]}
        />
      )}

      {showModalRedirectElapsed && (
        <ModalRedirect
          visible={showModalRedirectElapsed}
          setVisible={setShowModalRedirectElapsed}
          buttonOptions={[
            {
              action: () => {
                setFormulaMode("create");
                setShowModalFormula(true);
                setShowModalRedirectElapsed(false);
              },
              description: "Create formula based on this field",
              icon: faPlus
            },
            {
              action: () => {
                setElapsedMode("edit");
                setShowModalElapsed(true);
                setShowModalRedirectElapsed(false);
              },
              description: "Edit existing elapsed time field",
              icon: faEdit
            }
          ]}
        />
      )}

      {showModalFormula && target && (
        <ModalFormula
          visible={showModalFormula}
          setVisible={state => {
            if (!state) setFormulaMode("create");
            setShowModalFormula(state);
          }}
          targetElement={target}
          mode={formulaMode}
          page="builder"
          builder={builder}
          units={units}
          hasPrimaryAddress={!!facility?.primaryAddress}
          save={handleFormula}
        />
      )}

      {showModalElapsed && target && (
        <ModalElapsed
          visible={showModalElapsed}
          setVisible={state => {
            if (!state) setElapsedMode("create");
            setShowModalElapsed(state);
          }}
          targetElement={target}
          mode={elapsedMode}
          builder={builder}
          save={handleFormula}
        />
      )}

      {showModalConditional && target && (
        <ModalConditionals
          visible={showModalConditional && !!target}
          setVisible={state => {
            if (!state) setTarget(null);
            setShowModalConditional(state);
          }}
          targetElement={target}
          save={handleConditional}
          builder={builder}
          hasPrimaryAddress={!!facility?.primaryAddress}
        />
      )}

      {showModalDelete && target && (
        <ModalDeleteElement
          visible={showModalDelete}
          setVisible={setShowModalDelete}
          facilityId={facility.id}
          builder={builder}
          notifications={fieldNotifications}
          persistDelete={handleDelete}
          targetElement={target}
        />
      )}

      {showModalTemplate && (
        <ModalApplyTemplate
          builder={builder}
          visible={showModalTemplate}
          setShowModal={setShowModalTemplate}
          template={template}
          saveBuilder={saveBuilder}
          recursePreview={recursePreview}
          units={units}
          setUnits={setUnits}
        />
      )}

      {showModalSaveTemplate && (
        <ModalSaveTemplate
          visible={showModalSaveTemplate}
          setShowModal={setShowModalSaveTemplate}
          targetName={target ? target.name : null}
          targetLabel={target ? target.label : null}
          saveTemplate={addTemplate}
        />
      )}

      {showPreview && (
        <ModalChecksheetPreview
          checksheet={preview}
          visible={showPreview}
          setVisible={setShowPreview}
        />
      )}

      {showModalNotification && (
        <ModalNotifications
          visible={showModalNotification}
          setVisible={setShowModalNotification}
          checksheetBuilder={builder}
          notifications={fieldNotifications}
          setNotifications={setFieldNotifications}
          handleSave={saveNotifications}
        />
      )}

      {showModalRestriction && (
        <ModalChecksheetRestrictions
          visible={showModalRestriction}
          setVisible={setShowModalRestriction}
          checksheetRestrictions={checksheetRestrictions}
          handleSave={setChecksheetRestrictions}
          builder={builder}
          facilityId={facility.id}
        />
      )}

      {showModalMemberRestrict && (
        <ModalMemberRestrict
          visible={showModalMemberRestrict}
          setVisible={setShowModalMemberRestrict}
          restrictTo={restrictTo}
          setRestrictTo={setRestrictTo}
          external={external}
          setExternal={watchFrequency && watchFrequency !== "None" ? setExternal : null}
          facilityId={facility.id}
        />
      )}

      {showFilterModal && (
        <ModalFilter
          visible={showFilterModal}
          setVisible={setShowFilterModal}
          filters={filters}
          setFilters={setFilters}
        />
      )}
    </Page>
  );
};

// Style Overrides
const Wrapper = styled.article`
  ${flex("row", "wrap", "start", "stretch")};
`;

const PageLeft = styled.div`
  position: relative;
  width: 100%;
  flex: 1;

  ${bp(3)} {
    width: 35%;
    padding: 0 ${pad}px 0 0;
  }
`;

const PageRight = styled.div`
  ${flex("column", "nowrap", "start", "stretch")};
  width: 100%;

  ${bp(3)} {
    position: relative;
    padding: ${pad}px 0 0 ${pad}px;
    width: 65%;
  }
`;

const InlineWrapper = styled(FormField)`
  ${flex("row", "nowrap", "start", "space-between")};
  width: 100%;
  gap: ${pad}px;
  margin-bottom: ${pad / 2}px;

  &:first-child {
    padding: 0;
  }

  select {
    max-width: 100%;
  }
`;

const Apply = styled(Button)`
  background: ${({hasElements, theme}) => hasElements && theme.error};
`;

const Submit = styled(ButtonFull)`
  position: relative;
  width: calc(50% - 5px);
  color: ${({theme}) => theme.primary};
  margin: ${pad}px 0 ${pad * 2}px;
`;

const SaveDraft = styled(Submit)`
  color: ${({theme}) => theme.warning};
  flex: 1;
`;

const Builder = styled.div`
  position: relative;
  min-height: 300px;
  padding: ${pad}px;
  border: ${border} solid ${({theme}) => theme.secondary};
  border-radius: ${radius};
`;

const Menu = styled.div`
  ${flex("column", "nowrap")};

  margin-bottom: ${pad}px;

  ${bp(1)} {
    ${flex("row", "nowrap", "space-between", "center")};
  }
`;

const MenuLeft = styled(Inline)`
  width: 100%;
  margin-bottom: ${pad / 2}px;

  ${bp(1)} {
    margin-bottom: 0;
    ${({isEmpty}) =>
      isEmpty
        ? css`
            width: calc(100% - 200px);
          `
        : css`
            width: calc(100% - 317px);
          `}
  }

  ${bp(2)} {
    ${({isEmpty}) =>
      isEmpty
        ? css`
            width: calc(100% - 215px);
          `
        : css`
            width: calc(100% - 345px);
          `}
  }

  ${Heading} {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  ${Pill} {
    background: ${({theme}) => theme.primary};
  }
`;

const MenuRight = styled(Inline)`
  width: min-content;
  align-self: end;

  ${bp(1)} {
    align-self: unset;
  }

  ${Button} {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    &:last-child {
      margin: 0;
    }
  }
`;

const Options = styled(Inline)`
  gap: ${pad}px;
  width: 100%;
  align-items: stretch;
`;

const Sticky = styled(StickyWrapper)`
  top: ${pad * 9}px;
  flex: 1;
  margin: 0;
`;

const CustomGroup = styled(Inline)`
  margin-top: ${pad / 2}px;
  width: 100%;
  align-items: end;
  justify-content: start;

  div {
    margin: 0;

    ${({fitcontent}) =>
      fitcontent &&
      css`
        width: max-content;
      `}
  }

  input {
    min-width: 35px;
    width: 35px;
  }

  select {
    width: 105px;
  }

  ${bp(2)} {
    margin-right: 0;
  }
`;

const BaseSelectWrapper = styled.div`
  select {
    max-width: 88px;
  }
`;

const DateWrapper = styled(FormFieldWrapper)`
  max-width: 180px;
  > div {
    width: 100%;
    max-width: 180px;
  }
`;

const DatesWrapper = styled(Inline)`
  flex-wrap: wrap;
`;

const Errors = styled.div`
  margin-bottom: ${pad}px;

  ${Warning}, ${Error} {
    margin-bottom: ${pad / 2}px;
  }
`;

const Filters = styled.div`
  position: relative;
`;

const FilterButton = styled(Button)`
  overflow: initial;
`;

const WarningWrapper = styled(Warning)`
  margin-top: ${pad / 2}px;
  color: ${({theme}) => theme.secondary};
`;

const WarningHeader = styled.strong`
  color: ${({theme}) => theme.warning};
`;

export default ChecksheetBuilder;
