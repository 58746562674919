import React, {useContext} from "react";
import {useForm, FormProvider} from "react-hook-form";
import PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons";

// Contexts
import {AuthContext} from "../../contexts/auth.js";

// Components
import Modal from "../../components/Modal.js";
import InputCheckGroup from "../../components/form/InputCheckGroup.js";

// Style
import {HeadingCenter, Button, Form, Inline} from "../../style/components/general.js";

const ModalUserFilter = ({
  visible,
  setVisible,
  filters,
  setFilters,
  showHidden = false,
  setShowHidden,
  hasBackButton = false,
  goBack
}) => {
  const {roles} = useContext(AuthContext);

  const form = useForm({defaultValues: {...filters}});
  const {handleSubmit, reset} = form;

  const applyFilters = values => {
    const updated = {};

    if (values?.roles?.length > 0) updated.roles = values.roles;
    if (showHidden && values?.state?.length > 0) updated.state = values.state;

    setFilters(updated);
    setVisible(false);
  };

  return (
    <Modal visible={visible} setVisible={setVisible} hasBackButton={hasBackButton} goBack={goBack}>
      <HeadingCenter>User Filters</HeadingCenter>
      <Form onSubmit={handleSubmit(applyFilters)}>
        <FormProvider {...form}>
          {roles?.length > 0 && (
            <InputCheckGroup
              name="roles"
              label="Roles"
              options={[
                ...roles.map(role => ({
                  value: role.name,
                  label: role.label.toUpperCase()
                }))
              ]}
              defaultSelection={filters}
              setSelection={setFilters}
            />
          )}

          {showHidden && (
            <InputCheckGroup
              name="state"
              label="State"
              options={["Disabled", "Deleted"]}
              defaultSelection={filters}
              setSelection={setFilters}
            />
          )}

          <Inline>
            <Button type="submit">Apply</Button>
            <Button
              type="button"
              onClick={() => {
                setFilters(undefined);
                reset();
                setVisible(false);
              }}>
              Clear
            </Button>
            <Button
              type="button"
              onClick={() => {
                setShowHidden(prev => !prev);
                setVisible(false);
              }}>
              <FontAwesomeIcon icon={showHidden ? faEyeSlash : faEye} />
              &nbsp;Hidden
            </Button>
          </Inline>
        </FormProvider>
      </Form>
    </Modal>
  );
};

ModalUserFilter.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  filters: PropTypes.objectOf(PropTypes.any),
  setFilters: PropTypes.func.isRequired,
  showHidden: PropTypes.bool,
  setShowHidden: PropTypes.func.isRequired,
  hasBackButton: PropTypes.bool,
  goBack: PropTypes.func
};

export default ModalUserFilter;
