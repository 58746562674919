import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef
} from "react";
import PropTypes from "prop-types";
import axios from "axios";
import dayjs from "dayjs";

// Contexts
import {SettingsContext} from "./settings.js";
import {useSocket} from "./socket.js";
import {useToast} from "./toast.js";

// Hooks
import useApi from "../hooks/useApi.js";

export const AuthContext = createContext();

const AuthProvider = ({children}) => {
  const {getSettings} = useContext(SettingsContext);

  const socket = useSocket();

  const {addToast} = useToast();

  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [currentUser, setCurrentUser] = useState(undefined);
  const [types, setTypes] = useState();
  const [typeIds, setTypeIds] = useState();
  const [roles, setRoles] = useState();
  const [departments, setDepartments] = useState();
  const [maintenance, setMaintenance] = useState({
    status: "off"
  });

  const {api: apiUserTypes} = useApi("user-types", {suppress: {success: true, error: true}});
  const {api: apiUserRoles, loading: rolesLoading} = useApi("user-roles", {
    suppress: {success: true, error: true}
  });
  const {api: apiUserDepartments} = useApi("departments", {suppress: {success: true, error: true}});
  const {api: apiUsers} = useApi("users", {suppress: {success: true, error: true}});
  const {api: apiAuth} = useApi("authorized", {suppress: {success: false, error: true}});
  const {api: apiSignin} = useApi("signin", {suppress: {success: true, error: false}});
  const {api: apiSignOut} = useApi("signout", {suppress: {success: true, error: true}});
  const {api: apiResetPassword} = useApi("reset-password", {
    suppress: {success: true, error: true}
  });

  const showConnectError = useRef(false);

  const atLeast = useCallback(
    (type, ignoreAssumedRole = false) => {
      if (!currentUser || !typeIds || !(type in typeIds)) return false;

      const activeType = ignoreAssumedRole
        ? currentUser.type
        : currentUser.role?.type || currentUser.type;

      if (!activeType) return false;

      return activeType.order >= typeIds[type];
    },
    [currentUser, typeIds]
  );

  const atMost = useCallback(
    (type, ignoreAssumedRole = false) => {
      if (!currentUser || !typeIds || !(type in typeIds)) return false;

      const activeType = ignoreAssumedRole
        ? currentUser.type
        : currentUser.role?.type || currentUser.type;

      if (!activeType) return false;

      return activeType.order <= typeIds[type];
    },
    [currentUser, typeIds]
  );

  const isRole = useCallback(label => currentUser?.role?.label === label, [currentUser]);

  /**
   * @param {string} page Page key specified in api
   * @returns {bool} Whether role can access page
   */
  const roleCanAccessPage = useCallback(
    page => {
      if (atLeast("super") && !currentUser.role.id) return true;
      if (!(page in currentUser.role.permissions.page.byId)) return true;
      return (
        currentUser && currentUser.role && currentUser.role.permissions.page.byId[page].visible
      );
    },
    [atLeast, currentUser]
  );

  /**
   * Check wich pages a User Role can access
   * @param {array} allIds
   * @param {object} byId
   * @param {array} pages
   * @returns {array}
   */
  const getAccessablePages = useCallback((allIds, byId, pages = []) => {
    allIds.forEach(page => {
      if (byId[page].visible) {
        if (byId[page].children) getAccessablePages(byId[page].children, byId, pages);
        pages.push(page);
      }
    });

    return pages;
  }, []);

  /**
   * @param {string} page Page key specified in the api
   * @param {string} slug
   * @returns {string} First available child path
   */
  const getRolesDefaultPageFrom = useCallback(
    (target, slug) => {
      if (currentUser && currentUser.role) {
        const {page} = currentUser.role.permissions;
        const root = page.byId[target];
        const {path, parent} = root;
        if (parent && parent in page.byId && "children" in page.byId[parent]) {
          const available = page.byId[parent].children.filter(child => page.byId[child].visible);
          if (available.length > 0)
            // First available child path to role
            return `${path.replace("*", slug)}/${available[0]}`;
        }
      }

      return "/unauthorized";
    },
    [currentUser]
  );

  /**
   * @param {string} resource Resource key specified in api (ex: facility) ~ lowercase and singular
   * @param {string} operation Resource key specified in resource on the api (view, export, create, update, delete)
   * @returns {bool} Whether role can access page
   */
  const roleCanAccessResource = useCallback(
    (resource, operation = "view") => {
      if (currentUser && atLeast("super") && !currentUser.role?.id) return true;

      return (
        currentUser &&
        currentUser.role &&
        resource in currentUser.role.permissions.resource.byId &&
        currentUser.role.permissions.resource.byId[resource][operation] === "enabled"
      );
    },
    [atLeast, currentUser]
  );

  const getUserTypes = useCallback(
    () =>
      apiUserTypes.callGet(null, {orderBy: "desc"}).then(({status, data}) => {
        if (status === 200 && data) {
          setTypes(data);
          const userTypes = {};
          data.map(userType => {
            userTypes[userType.name] = userType.order;
          });
          setTypeIds(userTypes);
        }
      }),
    [apiUserTypes]
  );

  const getUserRoles = useCallback(
    () =>
      apiUserRoles.callGet(null, {orderBy: "desc"}).then(({status, data}) => {
        if (status === 200 && data) setRoles(data);
      }),
    [apiUserRoles]
  );

  const getUserDepartments = useCallback(
    () =>
      apiUserDepartments.callGet(null, {orderBy: "desc"}).then(({status, data}) => {
        if (status === 200 && data) setDepartments(data);
      }),
    [apiUserDepartments]
  );

  const handleAuthenticated = useCallback(
    async user => {
      // Attaches to all requests
      axios.defaults.withCredentials = true;

      socket.connect();

      socket.on("connect", () => {
        setCurrentUser(user);
        setIsAuthenticated(true);
        getUserTypes();
        getUserRoles();
        getUserDepartments();
        getSettings();

        showConnectError.current = true;
      });

      socket.on("connect_error", () => {
        if (showConnectError.current) addToast("Error establishing connection.");
        showConnectError.current = false;
      });
    },
    [socket, getUserTypes, getUserRoles, getUserDepartments, getSettings, addToast]
  );

  const signin = useCallback(
    (username, password, remember) =>
      apiSignin
        .callPost(
          {
            save: remember
          },
          {
            auth: {
              username: username,
              password: password
            }
          }
        )
        .then(({status, data}) => {
          if (status === 200 && data?.currentUser) {
            handleAuthenticated(data.currentUser);
          } else {
            setCurrentUser(undefined);
            setIsAuthenticated(false);
          }
        })
        .catch(() => {
          // TODO: save email in local storage
          addToast("Error occurred with sign in, please report to your system admin.", "error");
          setCurrentUser(undefined);
          setIsAuthenticated(false);
        }),
    [apiSignin, handleAuthenticated, addToast]
  );

  const signout = useCallback(
    () =>
      apiSignOut.callPost().then(() => {
        setCurrentUser(undefined);
        setIsAuthenticated(false);
        socket.disconnect();
      }),
    [apiSignOut, socket]
  );

  const resetPassword = useCallback(
    password =>
      apiResetPassword
        .callPut(null, {
          publicId: currentUser.publicId,
          new: password
        })
        .then(({status, data}) => status === 200 && data && setCurrentUser(data.currentUser)),
    [apiResetPassword, currentUser]
  );

  const getCurrentUser = useCallback(() => {
    apiUsers.callGet(currentUser.publicId).then(({data, status}) => {
      if (status === 200) setCurrentUser(data);
    });
  }, [apiUsers, currentUser]);

  // Maintenance counter
  const checkStart = useRef(null);

  useEffect(() => {
    const update = () => {
      const [hour, min] = maintenance?.start?.split(":") || [];
      const start = dayjs()
        .set("hour", Number.parseInt(hour, 10))
        .set("minute", Number.parseInt(min, 10))
        .set("second", 0);
      const delayPassed = start.isSameOrBefore(dayjs());
      if (delayPassed) clearInterval(checkStart.current);
      const newStatus = delayPassed ? "on" : "off";
      if (maintenance.status !== newStatus) setMaintenance(prev => ({...prev, status: newStatus}));
    };

    if (maintenance?.start && maintenance?.status !== "on") {
      clearInterval(checkStart.current);
      checkStart.current = setInterval(update, 1000);
    }
  }, [maintenance]);

  // Check for authorization on page load
  useEffect(() => {
    apiAuth.callGet().then(({data, status}) => {
      if (status === 200) {
        const {message, currentUser: user} = data || {};

        if (message === "Authorized.") handleAuthenticated(user);
      }
    });
  }, [apiAuth, handleAuthenticated]);

  // Handle sign out on 401
  useEffect(() => {
    axios.interceptors.response.use(undefined, error => {
      if (error.response && error.response.status === 401) {
        setIsAuthenticated(false);
        setCurrentUser(undefined);
      }

      return Promise.reject(error);
    });
  }, []);

  // Make the provider update only when it should.
  // We only want to force re-renders on dependencies
  const memoedValue = useMemo(
    () => ({
      signin,
      signout,
      resetPassword,
      currentUser,
      getCurrentUser,
      setCurrentUser,
      types: types || [],
      atLeast,
      atMost,
      roles: roles || [],
      rolesLoading,
      getUserRoles,
      isRole,
      roleCanAccessPage,
      departments: departments || [],
      getUserDepartments,
      getAccessablePages,
      getRolesDefaultPageFrom,
      roleCanAccessResource,
      isAuthenticated,
      maintenance,
      setMaintenance
    }),
    [
      signin,
      signout,
      resetPassword,
      currentUser,
      getCurrentUser,
      types,
      atLeast,
      atMost,
      roles,
      rolesLoading,
      getUserRoles,
      isRole,
      roleCanAccessPage,
      departments,
      getUserDepartments,
      getAccessablePages,
      getRolesDefaultPageFrom,
      roleCanAccessResource,
      isAuthenticated,
      maintenance
    ]
  );

  return <AuthContext.Provider value={memoedValue}>{children}</AuthContext.Provider>;
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthProvider;
