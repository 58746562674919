import React, {useEffect, useMemo, useState} from "react";
import {FormProvider, useFieldArray, useForm} from "react-hook-form";
import {useLocation, useNavigate} from "react-router-dom";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {faArrowsUpDown, faClose} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";

// Hooks
import useApi from "../../hooks/useApi.js";
import usePrevious from "../../hooks/usePrevious.js";

// Utils
import {exists} from "../../utils/helpers.js";

// Components
import Modal from "../../components/Modal.js";
import Contacts from "./Contacts.js";
import SearchSelect from "../../components/SearchSelect.js";
import {InputSelect, InputError} from "../../components/form/FormInputs.js";

// Style
import {border, pad, radius} from "../../style/components/variables.js";
import {
  Button,
  Form,
  ButtonLoader,
  HeadingCenter,
  Text,
  FormField,
  Inline,
  Abbr
} from "../../style/components/general.js";

const schema = yup.object().shape({
  details: yup.object().shape({
    contacts: yup.array().test({
      message: "At least one email is required.",
      test: arr => Array.isArray(arr) && arr?.length > 0
    })
  })
});

const ModalEditContacts = ({visible, setVisible, facility, setFacility}) => {
  const navigate = useNavigate();
  const {pathname} = useLocation();

  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [availableDepartments, setAvailableDepartments] = useState([]);
  const [availableCompanies, setAvailableCompanies] = useState([]);

  const {api} = useApi("facilities");
  const {api: apiUsers} = useApi("facility-users");

  const getContactDepartments = curr => {
    const list = [];
    const added = [];
    curr?.map(({department}) => {
      if (department?.name && !added.includes(department.name)) {
        added.push(department.name);
        list.push({key: department.id, name: department.name});
      } else if (!department?.name && !added.includes("Contact")) {
        added.push("Contact");
        list.push({key: 0, name: "Contact"});
      }
    });
    return list;
  };

  const getContactCompanies = (curr, currDepartment) => {
    const list = [];
    const added = [];
    curr?.map(({company, department}) => {
      if ((department?.name || "Contact") === currDepartment)
        if (company?.name && !added.includes(company.name)) {
          added.push(company.name);
          list.push({key: company.id, name: company.name});
        } else if (!company?.name && !added.includes("No Company")) {
          added.push("No Company");
          list.push({key: 0, name: "No Company"});
        }
    });
    return list;
  };

  const form = useForm({
    defaultValues: {
      order: "User Type",
      ...facility,
      details: {
        departments: getContactDepartments(facility.details.contactsExtra),
        companies: [],
        contacts: facility.details.contactsExtra
      }
    },
    resolver: yupResolver(schema)
  });
  const {
    control,
    watch,
    setValue,
    handleSubmit,
    formState: {errors}
  } = form;
  const watchOrder = watch("order");
  const watchDepartment = watch("department");
  const watchCompany = watch("company");
  const watchContacts = watch("details.contacts");

  const {
    fields: departments,
    insert: insertDepartment,
    remove: removeDepartment
  } = useFieldArray({control, name: "details.departments"});

  const {
    fields: companies,
    insert: insertCompany,
    remove: removeCompany
  } = useFieldArray({
    control,
    name: "details.companies"
  });

  const {fields: contacts, remove, insert} = useFieldArray({control, name: "details.contacts"});

  const handleSearch = query => {
    const filter = {HasDepartment: true};

    if (query) filter.Search = query;

    apiUsers
      .callGet("", {
        facilityId: facility.id,
        limit: 20,
        filter: JSON.stringify(filter)
      })
      .then(({status, data}) => {
        if (status === 200 && data)
          setUsers(data.filter(({email}) => !contacts.some(contact => contact.email === email)));
      });
  };

  const prevOrder = usePrevious(watchOrder);
  const prevDepartment = usePrevious(watchDepartment);

  useEffect(() => {
    const departmentList = getContactDepartments(contacts);
    const departmentNames = departmentList.map(({name}) => name);
    const companiesList = watchDepartment ? getContactCompanies(contacts, watchDepartment) : [];
    const companyNames = companiesList.map(({name}) => name);

    if (prevOrder !== watchOrder || prevDepartment !== watchDepartment)
      setValue("company", companyNames[0]);
    setValue("details.departments", departmentList);
    setAvailableDepartments(departmentNames);
    setValue("details.companies", companiesList);
    setAvailableCompanies(companyNames);
  }, [watchOrder, contacts, setValue, prevOrder, watchDepartment, prevDepartment]);

  const availableUsers = useMemo(() => {
    const list = [];

    contacts?.map(({id, department, company}) => {
      if (
        !list?.includes(id) &&
        (department?.name || "Contact") === watchDepartment &&
        (company?.name || "No Company") === watchCompany
      )
        list.push(id);
    });

    return list;
  }, [contacts, watchCompany, watchDepartment]);

  const reorderByDepartment = result => {
    const {destination, source} = result;

    if (!destination || source.index === destination.index) return;

    const target = watch(`details.departments.${source.index}`);
    let sourceOffset = 0;
    let destinationOffset = 1;
    if (destination.index < source.index) {
      sourceOffset = 1;
      destinationOffset = 0;
    }
    insertDepartment(destination.index + destinationOffset, {...target});
    removeDepartment(source.index + sourceOffset);

    const reordered = [];
    watch("details.departments")?.map(department => {
      contacts.map(contact => {
        if (department.name === contact?.department?.name) reordered.push(contact);
        if (department.name === "Contact" && !contact?.department?.name) reordered.push(contact);
      });
    });
    setValue("details.contacts", reordered);
  };

  const reorderByCompany = result => {
    const {destination, source} = result;

    if (!destination || source.index === destination.index) return;

    // Reorder Companies
    const target = watch(`details.companies.${source.index}`);
    let sourceOffset = 0;
    let destinationOffset = 1;
    if (destination.index < source.index) {
      sourceOffset = 1;
      destinationOffset = 0;
    }

    let minIndex = null;
    let maxIndex = null;

    for (let i = 0; i < contacts.length; i++) {
      const curr = contacts[i];
      const {department} = curr;
      if ((department?.name || "Contact") === watchDepartment) {
        if (!exists(minIndex)) minIndex = i;
        maxIndex = i;
      }
    }

    const left = contacts.slice(0, minIndex);
    const middle = contacts.slice(minIndex, maxIndex + 1);
    const right = contacts.slice(maxIndex + 1, contacts.length);

    insertCompany(destination.index + destinationOffset, {...target});
    removeCompany(source.index + sourceOffset);

    const reordered = [];
    watch("details.companies")?.map(company => {
      middle.map(contact => {
        if (company.name === contact.company?.name) reordered.push(contact);
        if (company.name === "No Company" && !contact.company?.name) reordered.push(contact);
      });
    });
    setValue("details.contacts", [...left, ...reordered, ...right]);
  };

  const reorderByUser = result => {
    const {destination, source} = result;

    if (!destination || source.index === destination.index) return;

    let minIndex = null;
    let maxIndex = null;

    for (let i = 0; i < contacts.length; i++) {
      const curr = contacts[i];
      const {department, company} = curr;
      if (
        (department?.name || "Contact") === watchDepartment &&
        (company?.name || "No Company") === watchCompany
      ) {
        if (!exists(minIndex)) minIndex = i;
        maxIndex = i;
      }
    }

    const target = watch(`details.contacts.${source.index}`);
    let sourceOffset = 0;
    let destinationOffset = 1;
    if (destination.index < source.index) {
      sourceOffset = 1;
      destinationOffset = 0;
    }

    let destinationIndex = destination.index;
    if (exists(maxIndex) && destinationIndex > maxIndex) destinationIndex = maxIndex;
    if (exists(minIndex) && destinationIndex < minIndex) destinationIndex = minIndex;

    insert(destinationIndex + destinationOffset, {...target});
    remove(source.index + sourceOffset);
  };

  const editFacility = ({details}) => {
    setLoading(true);

    const contactList = [];
    const contactSortObj = {};
    if (contacts?.length > 0) {
      contacts?.map(contact => {
        const company = contact.company?.name || "No Company";
        const department = contact?.department?.name || "Contact";

        if (!contactSortObj[department]) contactSortObj[department] = {[company]: [contact]};
        else if (!contactSortObj[department][company])
          contactSortObj[department][company] = [contact];
        else contactSortObj[department][company].push(contact);
      });

      Object.keys(contactSortObj).map(department => {
        const departmentCompanies = contactSortObj[department];
        Object.keys(departmentCompanies).map(company => {
          const companyContacts = departmentCompanies[company];
          companyContacts.map(contact => {
            contactList.push(contact.email);
          });
        });
      });
    }

    delete details.companies;
    delete details.departments;

    api
      .callPut(facility.id, {details: {contacts: contactList}})
      .then(({status, data}) => {
        if (status === 200 && data) {
          const updated = data.facility;
          setFacility(prev => ({
            ...prev,
            details: updated.details
          }));

          window.history.pushState({}, "Facility", updated.slug);

          // when changing facility name, need to update corresponding paths in facility dashboard tabs
          const matchedPathName = pathname.match(/^\/.+\/.+\/(.+)\/?/);
          const tabPathName = matchedPathName ? `/${matchedPathName[1]}` : "";
          navigate(`/facilities/${updated.slug}${tabPathName}`);
        }
      })
      .finally(() => {
        setLoading(false);
        setVisible(false);
      });
  };

  return (
    facility && (
      <Modal visible={visible} setVisible={setVisible}>
        <ModalTitle>Edit Contacts</ModalTitle>

        <FormProvider {...form}>
          <Form noValidate>
            <FormField id="contacts">
              <SearchSelect
                label="Contacts"
                search={handleSearch}
                results={users}
                setResults={setUsers}
                placeholder="Add user..."
                add={contact => {
                  if (!contacts || !contacts.map(({email}) => email).includes(contact.email)) {
                    let lastOfCategory = null;

                    for (let i = 0; i < contacts.length; i++) {
                      const curr = contacts[i];
                      const {department, company} = curr;
                      if (
                        department?.name === contact.department?.name &&
                        company?.name === contact.company?.name
                      )
                        lastOfCategory = i;
                    }

                    insert(exists(lastOfCategory) ? lastOfCategory + 1 : contacts.length, contact);
                  }
                }}
                showAll
              />
            </FormField>
            <InputError errors={errors} name="details.contacts" />

            <OrderOptions>
              {watchContacts?.length > 1 && (
                <div>
                  <FormField>
                    <InputSelect
                      name="order"
                      label="Order"
                      options={["User Type", "Company", "User"]}
                      showAll
                    />
                  </FormField>

                  {(watchOrder === "Company" || watchOrder === "User") &&
                    availableDepartments?.length > 0 && (
                      <FormField>
                        <InputSelect
                          name="department"
                          label="Within User Type"
                          options={availableDepartments}
                          showAll
                        />
                      </FormField>
                    )}

                  {watchOrder === "User" && availableCompanies?.length > 0 && (
                    <FormField>
                      <InputSelect
                        name="company"
                        label="Within Company"
                        options={availableCompanies}
                        showAll
                      />
                    </FormField>
                  )}

                  {watchOrder === "User Type" && availableDepartments && (
                    <DragDropContext onDragEnd={reorderByDepartment}>
                      <Droppable droppableId="departments">
                        {outerProvided => (
                          <ListWrapper
                            {...outerProvided.droppableProps}
                            ref={outerProvided.innerRef}>
                            {departments?.map(({key, name}, i) => (
                              <Draggable
                                draggableId={`${name}.${i}`}
                                index={i}
                                key={key}
                                isDragDisabled={availableDepartments.length <= 1}>
                                {innerProvided => (
                                  <div
                                    {...innerProvided.draggableProps}
                                    {...innerProvided.dragHandleProps}
                                    ref={innerProvided.innerRef}>
                                    <Selection key={name} {...innerProvided.dragHandleProps}>
                                      <SelecttionText disabled={availableDepartments.length <= 1}>
                                        <FontAwesomeIcon icon={faArrowsUpDown} />
                                        &nbsp;{name}
                                      </SelecttionText>
                                    </Selection>
                                    {innerProvided.placeholder}
                                  </div>
                                )}
                              </Draggable>
                            ))}
                            {outerProvided.placeholder}
                          </ListWrapper>
                        )}
                      </Droppable>
                    </DragDropContext>
                  )}

                  {watchOrder === "Company" && availableCompanies && (
                    <DragDropContext onDragEnd={reorderByCompany}>
                      <Droppable droppableId="company">
                        {outerProvided => (
                          <ListWrapper
                            {...outerProvided.droppableProps}
                            ref={outerProvided.innerRef}>
                            {companies?.map(({key, name}, i) => (
                              <Draggable
                                draggableId={`${name}.${i}`}
                                index={i}
                                key={key}
                                isDragDisabled={
                                  availableCompanies.length <= 1 ||
                                  !availableCompanies?.includes(name)
                                }>
                                {innerProvided => (
                                  <Option
                                    hide={!availableCompanies?.includes(name)}
                                    {...innerProvided.draggableProps}
                                    {...innerProvided.dragHandleProps}
                                    ref={innerProvided.innerRef}>
                                    <Selection key={name} {...innerProvided.dragHandleProps}>
                                      <SelecttionText disabled={availableCompanies.length <= 1}>
                                        <FontAwesomeIcon icon={faArrowsUpDown} />
                                        &nbsp;{name}
                                      </SelecttionText>
                                    </Selection>
                                    {innerProvided.placeholder}
                                  </Option>
                                )}
                              </Draggable>
                            ))}
                            {outerProvided.placeholder}
                          </ListWrapper>
                        )}
                      </Droppable>
                    </DragDropContext>
                  )}

                  {watchOrder === "User" && (
                    <DragDropContext onDragEnd={reorderByUser}>
                      <Droppable droppableId="contacts">
                        {outerProvided => (
                          <ListWrapper
                            {...outerProvided.droppableProps}
                            ref={outerProvided.innerRef}>
                            {contacts?.map(
                              ({firstName, lastName, email, department, company}, i) => (
                                <Draggable
                                  draggableId={`${email}.${i}`}
                                  index={i}
                                  key={email}
                                  isDragDisabled={
                                    availableUsers.length <= 1 ||
                                    (department?.name || "Contact") !== watchDepartment ||
                                    (company?.name || "No Company") !== watchCompany
                                  }>
                                  {innerProvided => (
                                    <Option
                                      hide={
                                        (department?.name || "Contact") !== watchDepartment ||
                                        (company?.name || "No Company") !== watchCompany
                                      }
                                      {...innerProvided.draggableProps}
                                      {...innerProvided.dragHandleProps}
                                      ref={innerProvided.innerRef}>
                                      <Selection key={email} {...innerProvided.dragHandleProps}>
                                        <SelecttionText disabled={availableUsers.length <= 1}>
                                          <FontAwesomeIcon icon={faArrowsUpDown} />
                                          &nbsp;
                                          <Abbr title={`${firstName} ${lastName}`}>
                                            {firstName} {lastName}
                                          </Abbr>
                                        </SelecttionText>
                                        <FontAwesomeIcon icon={faClose} onClick={() => remove(i)} />
                                      </Selection>
                                      {innerProvided.placeholder}
                                    </Option>
                                  )}
                                </Draggable>
                              )
                            )}
                            {outerProvided.placeholder}
                          </ListWrapper>
                        )}
                      </Droppable>
                    </DragDropContext>
                  )}
                </div>
              )}

              <ContactsWrapper>
                <Contacts details={{contactsExtra: watchContacts}} />
              </ContactsWrapper>
            </OrderOptions>

            <FormField>
              <Submit type="button" onClick={handleSubmit(editFacility)} loading={loading ? 1 : 0}>
                Save {loading && <ButtonLoader />}
              </Submit>
            </FormField>
          </Form>
        </FormProvider>
      </Modal>
    )
  );
};

ModalEditContacts.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  facility: PropTypes.objectOf(PropTypes.any).isRequired,
  setFacility: PropTypes.func.isRequired,
  setRefreshFacility: PropTypes.func.isRequired
};

// Style Overrides
const ModalTitle = styled(HeadingCenter)`
  margin: ${pad}px 0;
`;

const Option = styled.div`
  ${({hide}) =>
    hide &&
    css`
      height: 0;
      overflow: hidden;
      opacity: 0;
    `}
`;

const Selection = styled(Inline)`
  justify-content: space-between;
  position: relative;
  border-radius: ${radius};
  border: ${border} solid ${({theme}) => theme.secondary};
  background: ${({theme}) => theme.secondary};
  padding: ${pad}px ${pad / 2}px;
  width: 165px;
  margin-bottom: ${pad}px;

  svg {
    fill: ${({theme}) => theme.tertiary};
  }
`;

const SelecttionText = styled(Text)`
  color: ${({theme}) => theme.tertiary};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${({disabled}) =>
    disabled &&
    css`
      cursor: not-allowed;
    `}
`;

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const OrderOptions = styled(Inline)`
  align-items: start;
  justify-content: start;
  width: 100%;
  gap: ${pad * 2}px;
`;

const ContactsWrapper = styled.div`
  > div {
    min-width: 200px;
  }
`;

const Submit = styled(Button)`
  margin: ${pad}px 0;
`;

export default ModalEditContacts;
