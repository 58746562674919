import React, {useState, useEffect, useRef, useMemo, useCallback} from "react";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";
import {useFormContext} from "react-hook-form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faCheckDouble, faClock, faRefresh} from "@fortawesome/free-solid-svg-icons";

// Utils
import {isMobile} from "../../utils/responsive.js";

// Components
import InputError from "./InputError.js";
import Help from "../Help.js";

// Style
import {voice} from "../../style/components/typography.js";
import {flex, z} from "../../style/components/mixins.js";
import {border, colors, pad, radius, shadow} from "../../style/components/variables.js";
import {
  Abbr,
  FormFieldWrapper,
  Inline,
  Input,
  Label,
  Pill,
  scrollbar,
  Text
} from "../../style/components/general.js";

const InputTime = ({
  testId = "input-time",
  label,
  name,
  prompt,
  disabled = false,
  hidden = false,
  condition,
  tag,
  required = false,
  military = false
}) => {
  const hourOptions = useMemo(() => {
    if (military) return Array.from({length: 24}, (_v, i) => (i < 10 ? `0${i}` : `${i}`));
    return Array.from({length: 12}, (_v, i) => (i + 1 < 10 ? `0${i + 1}` : `${i + 1}`));
  }, [military]);

  const minuteOptions = useMemo(
    () => Array.from({length: 60}, (_v, i) => (i < 10 ? `0${i}` : `${i}`)),
    []
  );

  const {
    setValue,
    watch,
    formState: {errors, submitCount, isDirty}
  } = useFormContext();
  const watchValue = watch(name);

  const [focused, setFocused] = useState(false);
  const [shouldInvert, setShouldInvert] = useState(false);
  const [keyInputs, setKeyInputs] = useState([]);
  const [selectedHour, setSelectedHour] = useState();
  const [selectedMinute, setSelectedMinute] = useState();
  const [selectedMeridian, setSelectedMeridian] = useState();

  const outside = useRef();
  const wrapperRef = useRef();
  const innerWrapper = useRef();
  const inputRef = useRef();
  const hourRef = useRef();
  const minuteRef = useRef();

  const defaultPlaceholder = useMemo(() => (military ? "--:--" : "--:-- --"), [military]);

  const scrollToActive = useCallback(
    (hour, minute) => {
      const centerOffset = 3;
      if (hourRef.current && hour) {
        const buttonHeight = hourRef.current.scrollHeight / (military ? 24 : 12);
        const idx = hourOptions.indexOf(hour);
        hourRef.current.scrollTop = buttonHeight * Math.max(idx - centerOffset, 0);
      }

      if (minuteRef.current && minute) {
        const buttonHeight = minuteRef.current.scrollHeight / 60;
        const idx = minuteOptions.indexOf(minute);
        minuteRef.current.scrollTop = buttonHeight * Math.max(idx - centerOffset, 0);
      }
    },
    [hourOptions, military, minuteOptions]
  );

  // Parse and set the initial time value before form changes (is dirty)
  useEffect(() => {
    if (!isDirty && watchValue) {
      const [hour, min] = watchValue.split(":");

      let parsedHour = Number(hour);
      let meridian = "am";

      if (!military) {
        if (parsedHour === 0) parsedHour = 12;
        else if (parsedHour === 12) {
          meridian = "pm";
        } else if (parsedHour > 12) {
          parsedHour -= 12;
          meridian = "pm";
        }
      }

      parsedHour = Number(parsedHour) < 10 ? `0${parsedHour}` : `${parsedHour}`;

      setSelectedHour(parsedHour);
      setSelectedMinute(min);
      setSelectedMeridian(meridian);

      scrollToActive(parsedHour, min);
    }
  }, [watchValue, isDirty, military, scrollToActive]);

  // Update the time value whenever the selected time values change
  useEffect(() => {
    if (selectedHour && selectedMinute && (military || selectedMeridian)) {
      let parsedHour = Number.parseInt(selectedHour, 10);

      if (!military) {
        if (selectedMeridian === "pm" && parsedHour !== 12) parsedHour += 12;
        else if (selectedMeridian === "am" && parsedHour === 12) parsedHour = 0;
      }

      parsedHour = parsedHour < 10 ? `0${parsedHour}` : parsedHour;

      setValue(name, `${parsedHour || "--"}:${selectedMinute || "--"}`, {
        shouldDirty: true,
        shouldValidate: !!submitCount
      });
    }
  }, [selectedHour, selectedMinute, selectedMeridian, military, name, submitCount, setValue]);

  useEffect(() => {
    const handleClickOutside = ({target}) => {
      if (
        focused &&
        outside &&
        outside.current !== null &&
        !outside.current.contains(target) &&
        !outside.current.contains(target.nextSibling)
      ) {
        setFocused(false);
        if (isMobile()) document.body.style.overflow = "initial";
        inputRef.current.blur();
        setKeyInputs([]);
      }
    };

    if (outside) document.addEventListener("mousedown", handleClickOutside);

    // Cleanup
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [focused]);

  const getInverted = () => {
    const rect = wrapperRef.current?.getBoundingClientRect();
    const screenMidpoint = window.innerHeight / 2;

    setShouldInvert(!!rect && rect?.top > screenMidpoint + 120);
  };

  // Scroll selected time into view - value has changed
  useEffect(() => {
    const ref = innerWrapper.current;

    const handleWheel = event => {
      event.stopPropagation();

      const {deltaY, clientX} = event;

      const hour = hourRef.current;
      const minute = minuteRef.current;

      const hourRect = hour.getBoundingClientRect();
      const minuteRect = minute.getBoundingClientRect();

      if (clientX < hourRect.right) {
        hour.scrollTop += deltaY;
      } else if (clientX < minuteRect.right) {
        minute.scrollTop += deltaY;
      }

      event.preventDefault();
    };

    const touchStart = event => {
      event.stopPropagation();

      const {touches} = event;

      const hour = hourRef.current;
      const minute = minuteRef.current;

      const hourRect = hour.getBoundingClientRect();
      const minuteRect = minute.getBoundingClientRect();

      if (
        Array.from(touches).some(
          touch =>
            (touch.clientX >= hourRect.right - 2 && touch.clientX <= minuteRect.left + 2) ||
            (touch.clientX >= minuteRect.right - 2 && touch.clientX <= minuteRect.right + 2)
        )
      )
        event.preventDefault();
    };

    ref?.addEventListener("wheel", handleWheel, {passive: false});
    ref?.addEventListener("touchstart", touchStart, {passive: false});

    return () => {
      ref?.removeEventListener("wheel", handleWheel);
      ref?.removeEventListener("touchstart", touchStart);
    };
  }, []);

  useEffect(() => {
    const handleKeyDown = ({key}) => {
      if (keyInputs.length < 4 && key.match(/^[0-9]$/)) setKeyInputs(prev => [...prev, key]);
      else if (key.match(/^[ap]$/)) setSelectedMeridian(`${key}m`);
    };

    const inputElement = inputRef.current;
    inputElement.addEventListener("keydown", handleKeyDown);

    // Cleanup event listener on component unmount
    return () => {
      inputElement.removeEventListener("keydown", handleKeyDown);
    };
  }, [keyInputs]);

  useEffect(() => {
    let hour = "00";
    let minute = "00";
    if (keyInputs.length === 1) {
      if ((!military && Number(keyInputs[0]) > 1) || (military && Number(keyInputs[0]) > 1))
        setKeyInputs(["0", keyInputs[0]]);

      setSelectedHour(`0${keyInputs[0]}`);
      scrollToActive(hour);
    } else if (keyInputs.length === 2) {
      hour = keyInputs.join("");
      if (Number(hour) >= 0 && ((military && Number(hour) <= 23) || Number(hour) <= 12)) {
        setSelectedHour(hour);
        scrollToActive(hour);
      } else {
        setSelectedHour(`0${keyInputs[0]}`);
        setSelectedMinute(`0${keyInputs[1]}`);
        scrollToActive(`0${keyInputs[0]}`, `0${keyInputs[1]}`);
      }
    } else if (keyInputs.length === 3) {
      const part = keyInputs.slice(0, 2).join();
      const hourFull = military ? Number(part) <= 23 : Number(part) <= 12;
      minute = hourFull ? keyInputs.slice(1).join() : keyInputs.slice(2).join();
      if (Number(minute) >= 0 && Number(minute) <= 59) {
        setSelectedMinute(Number(minute) < 10 ? `0${minute}` : `${minute}`);
        scrollToActive(undefined, minute);
      }
    } else if (keyInputs.length === 4) {
      minute = keyInputs.slice(2).join("");
      if (Number(minute) >= 0 && Number(minute) <= 59) {
        setSelectedMinute(minute);
        scrollToActive(undefined, minute);
      }
      setKeyInputs([]);
    }
  }, [keyInputs, military, scrollToActive]);

  // Reset time states when value is cleared
  useEffect(() => {
    if (!watchValue) {
      setSelectedHour(undefined);
      setSelectedMinute(undefined);
      setSelectedMeridian(undefined);
    }
  }, [watchValue]);

  return (
    <FormFieldWrapper data-testid={testId}>
      {label && (
        <FieldLabel htmlFor={name} bold inline>
          {label.toUpperCase()}
          {required && "*"}
          {tag && (
            <Pill quiet>
              <Abbr title={tag}>{tag}</Abbr>
            </Pill>
          )}
          {condition && <Help icon={<FontAwesomeIcon icon={faCheckDouble} />}>{condition}</Help>}
        </FieldLabel>
      )}
      {prompt && <Prompt quiet>{prompt}</Prompt>}

      {!hidden && (
        <InputWrapper disabled={disabled} ref={wrapperRef}>
          <FontAwesomeIcon icon={faClock} />
          <InputInnerWrapper
            onClick={
              disabled
                ? undefined
                : () => {
                    getInverted();
                    setFocused(true);
                    inputRef.current.focus();
                    if (isMobile()) document.body.style.overflow = "hidden";
                  }
            }
          />
          <TimeInput
            ref={inputRef}
            type="text"
            placeholder={defaultPlaceholder}
            value={`${selectedHour || "--"}:${selectedMinute || "--"} ${
              !military ? selectedMeridian || "--" : ""
            }`}
            disabled={disabled}
            readOnly
          />
          <Picker
            military={military}
            show={focused}
            ref={outside}
            invert={shouldInvert}
            onClick={e => e.preventDefault()}>
            <Row ref={innerWrapper}>
              <Col military={military} ref={hourRef} scrollable>
                {hourOptions.map(option => (
                  <Option
                    key={`hour-${option}`}
                    selected={selectedHour === option}
                    onClick={() => setSelectedHour(option)}>
                    {option}
                  </Option>
                ))}
              </Col>
              <Col military={military} ref={minuteRef} scrollable>
                {minuteOptions.map(option => (
                  <Option
                    key={`minute-${option}`}
                    selected={selectedMinute === option}
                    onClick={() => setSelectedMinute(option)}>
                    {option}
                  </Option>
                ))}
              </Col>
              {!military && (
                <Col military={false} scrollable={false}>
                  <Option
                    selected={selectedMeridian === "am"}
                    onClick={() => setSelectedMeridian("am")}>
                    AM
                  </Option>
                  <Option
                    selected={selectedMeridian === "pm"}
                    onClick={() => setSelectedMeridian("pm")}>
                    PM
                  </Option>
                </Col>
              )}
            </Row>
            <ButtonBar>
              <TimeButton
                onClick={() => {
                  setValue(name, "");
                  setSelectedHour(undefined);
                  setSelectedMinute(undefined);
                  setSelectedMeridian(undefined);
                  setFocused(false);
                  if (isMobile()) document.body.style.overflow = "initial";
                }}>
                <FontAwesomeIcon icon={faRefresh} />
              </TimeButton>
              <TimeButton
                onClick={() => {
                  setFocused(false);
                  if (isMobile()) document.body.style.overflow = "initial";
                }}>
                <FontAwesomeIcon icon={faCheck} />
              </TimeButton>
            </ButtonBar>
          </Picker>
        </InputWrapper>
      )}

      <InputError errors={errors} name={name} />
    </FormFieldWrapper>
  );
};

InputTime.propTypes = {
  testId: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  prompt: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  hidden: PropTypes.bool,
  condition: PropTypes.string,
  tag: PropTypes.string,
  military: PropTypes.bool
};

// Style Overrides
const FieldLabel = styled(Label)`
  white-space: normal;
  gap: ${pad / 2}px;
`;

const Prompt = styled(Text)`
  margin-bottom: ${pad}px;
`;

const InputWrapper = styled.div`
  position: relative;
  width: 120px;

  > svg {
    position: absolute;
    right: 10px;
    top: 9px;
    fill: ${({theme}) => theme.secondary};
  }

  ${({disabled}) =>
    !disabled &&
    css`
      cursor: pointer;
    `}
`;

const InputInnerWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  cursor: inherit;
`;

const TimeInput = styled(Input)`
  font-family: monospace;
  padding-left: 12px;

  &:disabled {
    cursor: inherit;
    background: ${({theme}) => theme.tertiary};
  }
`;

const Row = styled.div`
  ${flex("row", "nowrap")};
  height: 220px;
  overflow: hidden;
  pointer-events: none;

  :before {
    content: "";
    height: 221px;
  }
`;

const Picker = styled.span`
  ${voice.quiet};
  cursor: auto;
  position: absolute;
  left: 0;
  color: ${({theme}) => theme.secondary};
  background: ${({theme}) => theme.tertiary};
  border-radius: ${radius};
  height: 250px;
  box-shadow: ${shadow};
  overflow: auto;
  overscroll-behavior: contain;
  pointer-events: none;
  z-index: ${z("top")};

  ${({military}) =>
    military
      ? css`
          width: 160px;
        `
      : css`
          width: 200px;
        `}

  ${({invert}) =>
    invert
      ? css`
          bottom: calc(100% + 5px);
        `
      : css`
          top: calc(100% + 5px);
        `}

  ${({show}) =>
    show
      ? css`
          visibility: visible;
        `
      : css`
          visibility: hidden;
          height: 0;
        `}
`;

const Col = styled.div`
  width: ${({military}) => 100 / (military ? 2 : 3)}%;
  border-right: ${border} solid ${colors.grayLight};
  scrollbar-width: none;
  -ms-overflow-style: none;
  overscroll-behavior: contain;
  pointer-events: auto;

  ::-webkit-scrollbar {
    display: none;
  }

  ${({scrollable}) =>
    scrollable
      ? css`
          ${scrollbar};
          overflow-y: scroll;
          overflow-x: hidden;
        `
      : css`
          overflow: hidden;
        `}

  :last-child {
    border: none;
  }
`;

const Option = styled.button`
  position: relative;
  padding: 8px ${pad}px;
  width: 100%;
  height: fit-content;
  line-height: 1.5em;
  text-align: center;

  ${({selected}) =>
    selected &&
    css`
      background-color: ${({theme}) => theme.secondary};
      color: ${({theme}) => theme.tertiary};
    `}

  ${voice.small};
`;

const ButtonBar = styled(Inline)`
  pointer-events: auto;
  justify-content: space-between;
  width: 100%;
  padding: 0 ${pad * 2}px;
  height: 30px;
`;

const TimeButton = styled.div`
  width: max-content;
  height: max-content;
  cursor: pointer;
`;

export default InputTime;
