import React, {Fragment, useCallback, useContext} from "react";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faCheckDouble,
  faClockRotateLeft,
  faClone,
  faEdit,
  faMinus,
  faPlus,
  faTrash
} from "@fortawesome/free-solid-svg-icons";
import {Draggable} from "react-beautiful-dnd";
import {FormProvider, useForm} from "react-hook-form";

// Utils
import {FIELD, COMPONENT} from "../../utils/builder.js";
import {AuthContext} from "../../contexts/auth.js";

// Components
import ElementOptions from "../../components/ElementOptions.js";
import Element from "../../components/Element.js";

// Style
import {flex, z} from "../../style/components/mixins.js";
import {voice} from "../../style/components/typography.js";
import {border, pad, radius, status} from "../../style/components/variables.js";
import {
  Form,
  Button,
  Section,
  Text,
  SubText,
  RelativeWrapper,
  Label,
  Abbr,
  Small,
  Warning,
  Inline
} from "../../style/components/general.js";
import {exists, generateUniqueKey} from "../../utils/helpers.js";

const RenderChecksheetBuilder = ({
  builder,
  setBuilder,
  filterResult,
  activeDroppableId,
  readOnly,
  modifications,
  setTarget,
  setShowModalEdit,
  setShowModalAdd,
  setShowModalDelete,
  setShowModalFormula,
  setShowModalConditional,
  setShowModalElapsed,
  setShowModalSaveTemplate,
  handleBulk,
  cloneElement,
  setShowModalHelp = () => {},
  children,
  togglePreview,
  setHasToggledOff,
  error,
  editing = false,
  templates,
  toggleOnly = false,
  inChecksheetHistory = false,
  inTemplateBuilder = false,
  inEventBuilder = false
}) => {
  const form = useForm();
  const {atLeast} = useContext(AuthContext);

  const getTemplateLabel = id => {
    const filtered = templates ? templates.filter(template => template.id === id) : null;
    if (filtered?.length) return filtered[0].label;
    return "Template";
  };

  const recurseToggle = (element, byId, setTo) => {
    byId[element.name].toggle = setTo;
    if (!byId[element.name].toggle) byId[element.name].preview = false;
    if (byId[element.name].children.length > 0)
      byId[element.name].children
        .filter(child => child in byId)
        .map(child => {
          byId = recurseToggle(byId[child], byId, setTo);
        });
    return byId;
  };

  const updatedToggle = element => {
    const temp = {...builder};
    const {allIds} = temp;
    let {byId} = temp;
    let hasToggledOff = false;
    byId = recurseToggle(element, byId, !element.toggle || false);

    if (byId[element.name].parentName !== "" && element.element === COMPONENT) {
      byId[byId[element.name].parentName].toggle = true;
    }

    for (let i = 0; i < allIds.length; i++) {
      if (!byId[allIds[i]].toggle) {
        hasToggledOff = true;
        break;
      }
    }

    if (setHasToggledOff) setHasToggledOff(hasToggledOff);

    setBuilder({...temp, byId});
  };

  const handleEdit = element => {
    setShowModalEdit(true);
    setTarget(element);
  };

  const handleAdd = element => {
    setShowModalAdd(true);
    setTarget(element);
  };

  const handleDelete = element => {
    setShowModalDelete(true);
    setTarget(element);
  };

  const handleConditional = element => {
    setShowModalConditional(true);
    setTarget(element);
  };

  const handleFormula = element => {
    setShowModalFormula(element);
    setTarget(element);
  };

  const handleElapsed = element => {
    setShowModalElapsed(element);
    setTarget(element);
  };

  const isTimeFormula = element => {
    if (!element?.formula?.length) return false;
    if (!element.formula[0].value) return false;
    if (builder.byId[element.formula[0].value]?.type === "time") return true;
    return false;
  };

  const showElapsed = useCallback(
    ({type, depends}) =>
      type === "time" || depends?.filter(key => builder.byId[key]?.type === "time")?.length > 0,
    [builder]
  );

  const renderOptions = target =>
    target.toggle && atLeast("creator") ? (
      <Options className="renderOptions" data-testid="element.options">
        {!toggleOnly && (
          <>
            {target.element === "field" && showElapsed(target) && (
              <Option
                data-testid="checksheetBuilder.elapsed"
                type="button"
                title="Elapsed Time"
                onClick={() => handleElapsed(target)}>
                <FontAwesomeIcon icon={faClockRotateLeft} />
              </Option>
            )}
            {target.element === "field" &&
              !isTimeFormula(target) &&
              ["number", "range", "generated"].includes(target.type) && (
                <Option
                  data-testid="checksheetBuilder.formula"
                  type="button"
                  title="Add/Edit Formula"
                  onClick={() => handleFormula(target)}>
                  f<SubText>(x)</SubText>
                </Option>
              )}
            {target.element === "field" && (
              <Option
                data-testid="checksheetBuilder.condition"
                type="button"
                title="Add Condition"
                onClick={() => handleConditional(target)}>
                <FontAwesomeIcon icon={faCheckDouble} />
              </Option>
            )}
            {target.element === "field" && target.type !== "generated" && (
              <Option
                data-testid="checksheetBuilder.clone"
                type="button"
                title="Clone"
                onClick={() => cloneElement(target)}>
                <FontAwesomeIcon icon={faClone} />
              </Option>
            )}
            {target.type !== "generated" && (
              <Option
                data-testid="element.edit"
                type="button"
                title="Edit"
                onClick={() => handleEdit(target)}>
                <FontAwesomeIcon icon={faEdit} />
              </Option>
            )}
          </>
        )}
        {(target.element === FIELD || editing) && !toggleOnly ? (
          <Option
            data-testid="element.edit"
            type="button"
            title="delete"
            onClick={() => handleDelete(target)}>
            <FontAwesomeIcon icon={faTrash} />
          </Option>
        ) : (
          <RelativeWrapper>
            {!inTemplateBuilder && (
              <Option type="button" title="toggle off" onClick={() => updatedToggle(target)}>
                <FontAwesomeIcon icon={faMinus} />
              </Option>
            )}
          </RelativeWrapper>
        )}
      </Options>
    ) : (
      <RelativeWrapper>
        {(target.element !== FIELD || toggleOnly) && (!editing || !target.toggle) && (
          <Options className="renderOptions">
            <Option
              type="button"
              title="toggle on"
              onClick={() => updatedToggle(target)}
              data-testid="element.toggle">
              <FontAwesomeIcon icon={faPlus} />
            </Option>
          </Options>
        )}
      </RelativeWrapper>
    );

  const numOptions = target => {
    if (target.toggle && atLeast("creator")) {
      if (toggleOnly) return 1;
      if (target.element !== "field" && inTemplateBuilder) return 1;
      if (target.element !== "field" && !inTemplateBuilder) return 2;
      if (target.type === "number" || target.type === "range") return 5;
      if (target.type === "generated") return 3;
      return 4;
    }

    return 1;
  };

  const modificationHasAttr = (id, allowAdded, ...attrs) =>
    attrs.some(
      attr =>
        modifications &&
        modifications[id] &&
        typeof modifications[id] !== "string" &&
        modifications[id].operation !== "removed" &&
        attr in modifications[id] &&
        (modifications[id][attr] !== "added" || allowAdded)
    );

  const buildRangeDisplay = (units, min, max, strictMin, strictMax, state) => (
    <div>
      {exists(min) && (
        <RegularText isOld={state === "old"} isNew={state === "new"}>
          {strictMin ? ">" : "≥"} {min} {units}
        </RegularText>
      )}
      {exists(max) && (
        <RegularText isOld={state === "old"} isNew={state === "new"}>
          {strictMax ? "<" : "≤"} {max} {units}
        </RegularText>
      )}
    </div>
  );

  const combineAllIds = () => {
    if (!modifications) return builder.allIds;
    return [
      ...Object.keys(modifications).filter(
        key => modifications[key].operation === "removed" && !modifications[key].parentName
      ),
      ...builder.allIds
    ];
  };

  const combineChildren = id => {
    const itemChildren = builder.byId[id]?.children || [];
    if (!modifications) return itemChildren;
    return [
      ...Object.keys(modifications).filter(
        key => modifications[key].operation === "removed" && modifications[key].parentName === id
      ),
      ...itemChildren
    ];
  };

  const getRenderedElements = ids => {
    if (ids && ids.length > 0) {
      return ids
        .filter(id => builder.byId[id])
        .map((id, index) => {
          let item = builder.byId[id];
          let removedItem = false;
          let modification = modifications ? modifications[id] : undefined;

          if (!item && modifications && modifications[id]?.operation === "removed") {
            item = modifications[id];
            removedItem = true;
            modification = "removed";
          }

          if (
            !item ||
            item.type === "weather" ||
            (item.element === FIELD && filterResult && !filterResult[id])
          )
            return null;

          const renderedChildren = item.children ? getRenderedElements(combineChildren(id)) : null;

          if (!item.preview)
            return (
              (item.element === FIELD ||
                !filterResult ||
                (renderedChildren && renderedChildren.some(child => !!child))) && (
                <Draggable
                  draggableId={id}
                  index={index}
                  key={item.name}
                  isDragDisabled={
                    !item.toggle ||
                    (inTemplateBuilder && item.element === "component") ||
                    !!filterResult ||
                    readOnly ||
                    toggleOnly ||
                    !!item.stale
                  }>
                  {provided => (
                    <Wrapper
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      ref={provided.innerRef}
                      key={id}
                      id={id}
                      adjustMargin={item.parentName === "" || inChecksheetHistory ? 1 : 0}
                      hidden={!!item.stale}>
                      <ElementOptions
                        builder={builder}
                        element={item}
                        key={id}
                        readOnly={readOnly}
                        inHistory={inChecksheetHistory}
                        renderOptions={readOnly ? () => {} : renderOptions}
                        activeDroppableId={activeDroppableId}
                        handleAdd={readOnly ? () => {} : handleAdd}
                        togglePreview={togglePreview}
                        setTarget={setTarget}
                        setShowModalSaveTemplate={setShowModalSaveTemplate}
                        error={error}
                        numOptions={readOnly ? 0 : numOptions(item)}
                        templateLabel={
                          item.templateId && !readOnly ? getTemplateLabel(item.templateId) : null
                        }
                        disableDrag={!!filterResult || readOnly || toggleOnly}
                        modification={modification}
                        toggleOnly={toggleOnly}>
                        {inChecksheetHistory && (
                          <>
                            {(modificationHasAttr(id, false, "globalPrompt") ||
                              (inChecksheetHistory && item.hasGlobalPrompt)) && (
                              <Label bold>Global Prompt</Label>
                            )}

                            {modificationHasAttr(id, false, "globalPrompt") &&
                              modifications[id].hasGlobalPrompt !== false &&
                              modifications[id].globalPrompt && (
                                <Detail isOld>
                                  <DetailLabel bold={!inChecksheetHistory}>
                                    {modifications[id].globalPrompt}
                                  </DetailLabel>
                                </Detail>
                              )}

                            {readOnly && item.hasGlobalPrompt && item.globalPrompt && (
                              <Detail
                                isNew={
                                  !removedItem &&
                                  !!modifications &&
                                  !!modifications[id] &&
                                  (!!modifications[id].globalPrompt ||
                                    modifications[id].hasGlobalPrompt === false)
                                }>
                                <DetailLabel bold={!inChecksheetHistory}>
                                  {item.globalPrompt}
                                </DetailLabel>
                              </Detail>
                            )}

                            {((item.hasAddress &&
                              item.address &&
                              Object.keys(item.address).length > 0) ||
                              item.hasHelp ||
                              item.internalIds?.length > 0 ||
                              modificationHasAttr(id, false, "help") ||
                              modificationHasAttr(id, false, "internalIds") ||
                              modificationHasAttr(id, false, "address")) && (
                              <>
                                {(modificationHasAttr(id, false, "help") ||
                                  (inChecksheetHistory &&
                                    item?.help?.filter(
                                      helpItem => !helpItem.id && helpItem.key && helpItem.value
                                    )?.length > 0)) && <Label bold>Help</Label>}

                                {modificationHasAttr(id, false, "help") &&
                                  modifications[id].hasHelp !== false &&
                                  modifications[id].help
                                    ?.filter(
                                      helpItem => !helpItem.id && helpItem.key && helpItem.value
                                    )
                                    ?.map(({key, value}) => (
                                      <Detail
                                        key={`${id}-help-old-${generateUniqueKey(key)}`}
                                        isOld>
                                        <DetailLabel>
                                          <Abbr title={key}>{key}</Abbr>
                                        </DetailLabel>
                                        <StyledNotes>
                                          <StyledNoteText bold={!inChecksheetHistory}>
                                            {value}
                                          </StyledNoteText>
                                        </StyledNotes>
                                      </Detail>
                                    ))}

                                {readOnly &&
                                  item?.hasHelp &&
                                  item?.help
                                    ?.filter(helpItem => !helpItem.id)
                                    ?.map(({key, value}) => (
                                      <Detail
                                        key={`${id}-help-${generateUniqueKey(key)}`}
                                        isNew={
                                          !removedItem &&
                                          !!modifications &&
                                          !!modifications[id] &&
                                          (!!modifications[id].help ||
                                            modifications[id].hasHelp === false)
                                        }>
                                        <DetailLabel>
                                          <Abbr title={key}>{key}</Abbr>
                                        </DetailLabel>
                                        <StyledNotes>
                                          <StyledNoteText bold={!inChecksheetHistory}>
                                            {value}
                                          </StyledNoteText>
                                        </StyledNotes>
                                      </Detail>
                                    ))}

                                {(modificationHasAttr(id, false, "internalIds") ||
                                  (inChecksheetHistory &&
                                    item?.internalIds?.filter(({key, value}) => key && value)
                                      ?.length > 0)) && <Label bold>Internal IDs</Label>}

                                {modificationHasAttr(id, false, "internalIds") &&
                                  modifications[id].internalIds?.map(
                                    ({key, value}) =>
                                      key &&
                                      value && (
                                        <Detail
                                          key={`${id}-id-old-${generateUniqueKey(key)}`}
                                          isOld>
                                          <DetailLabel>
                                            <Abbr title={key}>{key}</Abbr>
                                          </DetailLabel>
                                          <StyledNotes>
                                            <StyledNoteText bold={!inChecksheetHistory}>
                                              {value}
                                            </StyledNoteText>
                                          </StyledNotes>
                                        </Detail>
                                      )
                                  )}

                                {item?.internalIds?.map(
                                  ({key, value}) =>
                                    key &&
                                    value && (
                                      <Detail
                                        key={`${id}-id-${generateUniqueKey(key)}`}
                                        isNew={
                                          !removedItem &&
                                          !!modifications &&
                                          !!modifications[id] &&
                                          !!modifications[id].internalIds
                                        }>
                                        <DetailLabel>
                                          <Abbr title={key?.toUpperCase()}>
                                            {key?.toUpperCase()}
                                          </Abbr>
                                        </DetailLabel>
                                        <StyledNotes>
                                          <StyledNoteText bold={!inChecksheetHistory}>
                                            {value}
                                          </StyledNoteText>
                                        </StyledNotes>
                                      </Detail>
                                    )
                                )}

                                {(modificationHasAttr(id, false, "address") ||
                                  (inChecksheetHistory && item?.address)) && (
                                  <Label bold>Address</Label>
                                )}

                                {modificationHasAttr(id, false, "address") &&
                                  modifications[id].hasAddress !== false &&
                                  modifications[id].address && (
                                    <Detail isOld noFlex>
                                      <Label data-testid="edit.location" bold>
                                        {modifications[id].address.primary && <span>Primary</span>}
                                      </Label>
                                      <Text>
                                        <span>{modifications[id].address.line1}</span>
                                        <br />
                                        {modifications[id].address.line2 &&
                                          modifications[id].address.line2 !== "" && (
                                            <>
                                              <span>{modifications[id].address.line2}</span>
                                              <br />
                                            </>
                                          )}
                                        <span>
                                          {modifications[id].address.city}{" "}
                                          {modifications[id].address.state}
                                          {modifications[id].address.zipCode &&
                                            `, ${modifications[id].address.zipCode}`}
                                        </span>
                                        <br />
                                        <Small>
                                          {modifications[id].address.details &&
                                            modifications[id].address.details.map(
                                              detail =>
                                                Object.keys(detail).length > 0 && (
                                                  <Fragment key={detail.key}>
                                                    {`${detail.key}: ${detail.value}`}
                                                    <br />
                                                  </Fragment>
                                                )
                                            )}
                                        </Small>
                                      </Text>
                                    </Detail>
                                  )}

                                {inChecksheetHistory && item?.hasAddress && item?.address && (
                                  <Detail
                                    isNew={
                                      !removedItem &&
                                      !!modifications &&
                                      !!modifications[id] &&
                                      (!!modifications[id].address ||
                                        modifications[id].hasAddress === false)
                                    }
                                    noFlex>
                                    <Label data-testid="edit.location" bold>
                                      {item.address.primary && <span>Primary</span>}
                                    </Label>
                                    <Text>
                                      <span>{item.address.line1}</span>
                                      <br />
                                      {item.address.line2 && item.address.line2 !== "" && (
                                        <>
                                          <span>{item.address.line2}</span>
                                          <br />
                                        </>
                                      )}
                                      <span>
                                        {item.address.city} {item.address.state}
                                        {item.address.zipCode && `, ${item.address.zipCode}`}
                                      </span>
                                      <br />
                                      <Small>
                                        {item.address.details &&
                                          item.address.details.map(
                                            detail =>
                                              Object.keys(detail).length > 0 && (
                                                <Fragment key={detail.key}>
                                                  {`${detail.key}: ${detail.value}`}
                                                  <br />
                                                </Fragment>
                                              )
                                          )}
                                      </Small>
                                    </Text>
                                  </Detail>
                                )}
                              </>
                            )}

                            {(modificationHasAttr(id, false, "options") ||
                              (item?.options &&
                                ["multiselect", "radio", "dropdown"].includes(item?.type))) && (
                              <>
                                <Label bold>Options</Label>
                                {modificationHasAttr(id, false, "options") && (
                                  <RegularText isOld>
                                    {modifications[id].options
                                      .map(option => option.option || option.value || option)
                                      .join(", ")}
                                  </RegularText>
                                )}

                                <RegularText
                                  isNew={!removedItem && modificationHasAttr(id, true, "options")}>
                                  {item.options
                                    .map(option => option.option || option.value || option)
                                    .join(", ")}
                                </RegularText>
                              </>
                            )}

                            {(modificationHasAttr(
                              id,
                              false,
                              "aMin",
                              "aMax",
                              "strictMin",
                              "strictMax"
                            ) ||
                              item?.hasARange) && (
                              <>
                                <Label bold>Acceptable Range</Label>
                                {modificationHasAttr(
                                  id,
                                  false,
                                  "aMin",
                                  "aMax",
                                  "strictMin",
                                  "strictMax"
                                ) &&
                                  modifications[id].hasARange !== false &&
                                  buildRangeDisplay(
                                    modifications[id].units || item?.units,
                                    exists(modifications[id].aMin)
                                      ? modifications[id].aMin
                                      : item?.aMin,
                                    exists(modifications[id].aMax)
                                      ? modifications[id].aMax
                                      : item?.aMax,
                                    modifications[id].strictMin !== undefined
                                      ? modifications[id].strictMin
                                      : item?.strictMin,
                                    modifications[id].strictMax !== undefined
                                      ? modifications[id].strictMax
                                      : item?.strictMax,
                                    "old"
                                  )}
                                {item?.hasARange &&
                                  buildRangeDisplay(
                                    item.units,
                                    item.aMin,
                                    item.aMax,
                                    item.strictMin,
                                    item.strictMax,
                                    modificationHasAttr(
                                      id,
                                      true,
                                      "aMin",
                                      "aMax",
                                      "strictMin",
                                      "strictMax"
                                    )
                                      ? "new"
                                      : undefined
                                  )}
                              </>
                            )}

                            {(modificationHasAttr(id, false, "alertCondition") ||
                              item?.hasAlert) && (
                              <>
                                <Label bold>Acceptable Values</Label>
                                {modificationHasAttr(id, false, "alertCondition") &&
                                  modifications[id].hasAlert !== false && (
                                    <RegularText isOld>
                                      {Array.isArray(modifications[id].alertCondition)
                                        ? modifications[id].alertCondition.join(", ")
                                        : modifications[id].alertCondition}
                                    </RegularText>
                                  )}
                                {item?.hasAlert && (
                                  <RegularText
                                    isNew={
                                      !removedItem &&
                                      modificationHasAttr(id, true, "alertCondition")
                                    }>
                                    {Array.isArray(item.alertCondition)
                                      ? item.alertCondition.join(", ")
                                      : item.alertCondition}
                                  </RegularText>
                                )}
                              </>
                            )}
                          </>
                        )}

                        {renderedChildren || null}
                      </ElementOptions>
                      {provided.placeholder}
                    </Wrapper>
                  )}
                </Draggable>
              )
            );

          return (
            !item.stale &&
            (!filterResult ||
              item.element === FIELD ||
              (renderedChildren && renderedChildren.some(child => !!child))) && (
              <Element
                key={id}
                byId={builder.byId}
                attributes={item}
                readOnly
                renderOptions={renderOptions}
                activeDroppableId={activeDroppableId}
                togglePreview={togglePreview}
                setShowModalHelp={setShowModalHelp}
                setTarget={setTarget}
                preview>
                {renderedChildren || null}
              </Element>
            )
          );
        });
    }
    return null;
  };

  const renderElements = ids => {
    const view = getRenderedElements(ids);
    if (view && view.some(element => !!element)) return view;
    return (
      <Wrapper data-testid="checksheetBuilder.body" adjustMargin={inChecksheetHistory ? 1 : 0}>
        <Builder empty>
          <Message>No Elements Found</Message>
        </Builder>
      </Wrapper>
    );
  };

  return (
    <ChecksheetBuilderSection data-testid="checksheetBuilder.body">
      {builder?.allIds?.length > 0 ? (
        <Wrapper adjustMargin={inChecksheetHistory ? 1 : 0}>
          <FormProvider {...form}>
            <Form>{renderElements(combineAllIds())}</Form>
          </FormProvider>
        </Wrapper>
      ) : (
        <RelativeWrapper>
          {!inEventBuilder && (
            <Wrapper>
              <Builder empty>
                <Message>Checksheet Elements...</Message>
              </Builder>
            </Wrapper>
          )}
        </RelativeWrapper>
      )}
      {children}
      {!inTemplateBuilder && !inChecksheetHistory && !toggleOnly && (
        <Button type="button" onClick={handleAdd} data-testid="checksheet.addField">
          <FontAwesomeIcon icon={faPlus} /> Field
        </Button>
      )}
      {handleBulk && (
        <>
          <BulkEditSticky>
            <BulkButton type="button" onClick={handleBulk}>
              Save
            </BulkButton>
          </BulkEditSticky>
          <WarningWrapper>
            <Warning>Warning!</Warning>
            <Text quiet>You have one or more unsaved changes!</Text>
          </WarningWrapper>
        </>
      )}
    </ChecksheetBuilderSection>
  );
};

RenderChecksheetBuilder.propTypes = {
  builder: PropTypes.objectOf(PropTypes.any),
  setBuilder: PropTypes.func.isRequired,
  activeDroppableId: PropTypes.string,
  readOnly: PropTypes.bool,
  modifications: PropTypes.objectOf(PropTypes.any),
  setTarget: PropTypes.func,
  setShowModalEdit: PropTypes.func,
  setShowModalAdd: PropTypes.func,
  setShowModalDelete: PropTypes.func,
  setShowModalFormula: PropTypes.func,
  setShowModalConditional: PropTypes.func,
  setShowModalElapsed: PropTypes.func,
  setShowModalHelp: PropTypes.func,
  handleBulk: PropTypes.func,
  cloneElement: PropTypes.func,
  children: PropTypes.node,
  setShowModalSaveTemplate: PropTypes.func,
  togglePreview: PropTypes.func,
  setHasToggledOff: PropTypes.func,
  error: PropTypes.arrayOf(PropTypes.string),
  editing: PropTypes.bool,
  toggleOnly: PropTypes.bool,
  templates: PropTypes.arrayOf(PropTypes.any),
  inChecksheetHistory: PropTypes.bool,
  inTemplateBuilder: PropTypes.bool,
  inEventBuilder: PropTypes.bool,
  filterResult: PropTypes.objectOf(PropTypes.any)
};

// Style Overrides
const ChecksheetBuilderSection = styled(Section)`
  padding: 0;
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: max-content;

  ${({adjustMargin}) =>
    adjustMargin
      ? css`
          padding-top: ${pad}px;

          :first-child {
            padding-top: 0;
          }
        `
      : css`
          margin-bottom: ${pad * 2}px;
        `}

  ${({hidden}) =>
    hidden &&
    css`
      height: 0;
      opacity: 0;
    `}
`;

const Builder = styled.div`
  min-height: 200px;
  padding: ${pad}px;

  ${({empty, theme}) =>
    empty &&
    css`
      border: ${border} dashed ${theme.primary};
      border-radius: ${radius};
      ${flex("row", "wrap", "center", "center")};
    `}
`;

const Message = styled(Text)`
  color: ${({theme}) => theme.primary};
  ${voice.medium};
`;

const Options = styled.div`
  ${flex("row", "nowrap", "center", "center")};
  z-index: ${z("base")};
  visibility: hidden;
  opacity: 0;
  transition: all ease 0.2s;
  min-height: 38px;
`;

const Option = styled(Button)`
  margin: 0 0 0 ${pad}px;
  font-weight: bold;
  ${voice.normal}
`;

const Detail = styled.div`
  ${({noFlex}) =>
    !noFlex &&
    css`
      display: flex;
      justify-content: space-between;
    `}

  margin: ${pad}px 0;

  p,
  span {
    color: ${({theme}) => theme.secondary};

    ${({isNew, isOld}) => {
      if (isNew)
        return css`
          color: ${status.success};
        `;

      if (isOld)
        return css`
          color: ${status.error};
          text-decoration: line-through;
        `;

      return "";
    }}
  }
`;

const DetailLabel = styled(Text)`
  width: 25%;
  padding-right: ${pad}px;
  text-overflow: ellipsis;
  overflow: hidden;
  min-width: 80px;

  ${({fullWidth}) =>
    fullWidth &&
    css`
      width: 100%;
    `}
`;

const StyledNotes = styled.div`
  display: flex;
  align-items: center;
  width: 75%;
`;

const StyledNoteText = styled.span`
  font-weight: 700;
  color: ${({theme}) => theme.secondary};
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;

  &:before {
    content: attr(title);
  }
`;

const RegularText = styled.div`
  text-align: left;
  color: ${({theme}) => theme.secondary};
  ${voice.normal};

  ${({isNew, isOld}) => {
    if (isNew)
      return css`
        color: ${status.success};
      `;

    if (isOld)
      return css`
        color: ${status.error};
        text-decoration: line-through;
      `;

    return "";
  }}
`;

const BulkEditSticky = styled.div`
  bottom: ${pad * 2}px;
  margin-top: ${pad}px;
  display: flex;
  position: sticky;
  justify-content: start;
  z-index: ${z("above")};
  height: 35px;
`;

const WarningWrapper = styled(Inline)`
  position: absolute;
  right: 0;
  bottom: ${pad}px;
  padding: 0 ${pad}px;
  width: 75%;
  align-items: start;
  justify-self: end;
  justify-content: end;
`;

const BulkButton = styled(Button)`
  width: 150px;
  box-shadow: 0 10px 20px rgb(0 0 0 / 50%);
`;

export default RenderChecksheetBuilder;
