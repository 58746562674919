import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import styled, {css} from "styled-components";
import PropTypes from "prop-types";
import {FormProvider, useFieldArray, useForm} from "react-hook-form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faArrowsUpDownLeftRight,
  faCheck,
  faClose,
  faEye,
  faEyeSlash,
  faPencil
} from "@fortawesome/free-solid-svg-icons";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";

// Contexts
import {FacilityNavContext} from "../../contexts/facilitynav.js";

// Hooks
import usePrevious from "../../hooks/usePrevious.js";
import useApi from "../../hooks/useApi.js";

// Utils
import {exists, getReadableKey} from "../../utils/helpers.js";
import {getAncestors, getFields} from "../../utils/builder.js";
import {
  STATIC,
  TABLE,
  CHART,
  STAT,
  HEADER,
  TEXT,
  INPUT,
  CHECKSHEET,
  EVENT,
  EXISTING_ANALYTIC,
  ANALYTIC_FROM_TABLE,
  AGGREGATE
} from "../../utils/report.js";

// Components
import Region from "./Region.js";
import Modal from "../../components/Modal.js";
import SearchSelect from "../../components/SearchSelect.js";
import {
  InputText,
  InputSelect,
  InputTable,
  InputCheck,
  InputTextArea,
  InputError,
  InputCheckGroup,
  InputRadioGroup
} from "../../components/form/FormInputs.js";

// Style
import {pad, radius} from "../../style/components/variables.js";
import {
  Abbr,
  Button,
  ButtonFull,
  Error,
  Form,
  FormField,
  FormFieldWrapper,
  FormGroup,
  Heading,
  Inline,
  Pill,
  Text
} from "../../style/components/general.js";
import voice from "../../style/components/typography.js";

// Type Groups
const STATIC_OPTIONS = [HEADER, TEXT, INPUT];
const SOURCE_OPTIONS = [CHECKSHEET, EVENT];
const TABLE_OPTIONS = [CHECKSHEET, EVENT, STATIC];
const ANALYTIC_OPTIONS = [EXISTING_ANALYTIC, ANALYTIC_FROM_TABLE];
const STAT_OPTIONS = [
  {name: "min", label: "Min"},
  {name: "average", label: "Avg"},
  {name: "median", label: "Median"},
  {name: "max", label: "Max"},
  {name: "total", label: "Total"}
];

const defaultValues = {
  sectionType: null,
  sourceType: null,
  sourceFacility: null,
  source: null,
  fields: null,
  title: "",
  columns: null,
  cells: null,
  table: {},
  highlight: false,
  hasSecondInput: false,
  secondInput: null,
  cellInterval: "month"
};

const schema = yup.object().shape({
  sourceType: yup.string().required("Please provide a source type."),
  title: yup
    .mixed()
    .nullable()
    .when(["sectionType", "sourceType"], {
      is: (sectionType, sourceType) =>
        (sectionType !== CHART && sectionType !== STAT) || sourceType === ANALYTIC_FROM_TABLE,
      then: () => yup.string().required("Please provide a title.")
    }),
  source: yup
    .string()
    .nullable()
    .when(["sectionType", "sourceType"], {
      is: (sectionType, sourceType) =>
        sectionType === CHART ||
        sectionType === STAT ||
        sourceType === CHECKSHEET ||
        sourceType === EVENT,
      then: () => yup.string().required("Please provide a source.")
    }),
  fields: yup
    .mixed()
    .nullable()
    .when("sourceType", {
      is: val => val === "Checksheet" || val === "Event",
      then: () =>
        yup
          .array()
          .of(yup.object())
          .test({
            test: val => Array.isArray(val) && val.length > 0,
            message: "Please provide at least one field"
          })
    }),
  columns: yup
    .mixed()
    .nullable()
    .when(["sectionType", "sourceType"], {
      is: (sectionType, sourceType) => sectionType === TABLE && sourceType === STATIC,
      then: () => yup.string().required("Column count is required.")
    }),
  cells: yup
    .mixed()
    .nullable()
    .when(["sectionType", "sourceType"], {
      is: (sectionType, sourceType) => sectionType === TABLE && sourceType === STATIC,
      then: () => yup.string().required("Cell count is required.")
    }),
  table: yup
    .mixed()
    .nullable()
    .when(["sectionType", "sourceType"], {
      is: (sectionType, sourceType) => sectionType === TABLE && sourceType === STATIC,
      then: () =>
        yup.object().test({
          message: "Table cells must be filled out.",
          test: arr => {
            let valid = true;
            Object.values(arr).map(row => {
              Object.values(row).map(cell => {
                if (!cell || cell === "") valid = false;
              });
            });
            return valid;
          }
        })
    }),
  chartSources: yup
    .mixed()
    .nullable()
    .when("sectionType", {
      is: sectionType => sectionType === CHART,
      then: () =>
        yup
          .array()
          .of(yup.string())
          .test({
            test: val => Array.isArray(val) && val.length > 0,
            message: "Please select at least one field"
          })
    }),
  statSource: yup
    .mixed()
    .nullable()
    .when(["sectionType", "sourceType"], {
      is: (sectionType, sourceType) => sectionType === STAT && sourceType === ANALYTIC_FROM_TABLE,
      then: () => yup.string().nullable().required("Please provide field")
    }),
  statType: yup
    .mixed()
    .nullable()
    .when(["sectionType", "sourceType"], {
      is: (sectionType, sourceType) => sectionType === STAT && sourceType === ANALYTIC_FROM_TABLE,
      then: () => yup.string().nullable().required("Please select statistic type")
    }),
  highlight: yup
    .mixed()
    .nullable()
    .when("sourceType", {
      is: sourceType => sourceType === TEXT,
      then: () => yup.bool()
    }),
  hasSecondInput: yup
    .mixed()
    .nullable()
    .when("sourceType", {
      is: sourceType => sourceType === INPUT,
      then: () => yup.bool()
    }),
  secondInput: yup
    .mixed()
    .nullable()
    .when("hasSecondInput", {
      is: hasSecondInput => !!hasSecondInput,
      then: () => yup.string().required("Please provide a title.")
    }),
  stats: yup
    .mixed()
    .nullable()
    .when("hasStats", {
      is: val => !!val,
      then: () =>
        yup
          .array()
          .of(yup.object())
          .test({
            test: val => Array.isArray(val) && val.length > 0 && val.some(({enabled}) => !!enabled),
            message: "Please select at least one stat"
          })
    })
});

const statsInitial = [
  {name: "hasMin", enabled: false},
  {name: "hasAvg", enabled: false},
  {name: "hasMedian", enabled: false},
  {name: "hasMax", enabled: false},
  {name: "hasTotal", enabled: false}
];

const statLabels = {
  hasMin: "Min",
  hasAvg: "Avg",
  hasMedian: "Median",
  hasMax: "Max",
  hasTotal: "Total"
};

const aggregationTypes = {
  total: "Total",
  avg: "Avg",
  median: "Median",
  min: "Min",
  max: "Max"
};

const ModalAddSection = ({
  visible = false,
  setVisible,
  handleInsertRegion,
  selectedType,
  goBack,
  tables,
  start,
  end,
  base,
  interval,
  target,
  fontSize = 16
}) => {
  const {facility} = useContext(FacilityNavContext);
  const {types} = facility || {};

  const [checksheets, setChecksheets] = useState();
  const [events, setEvents] = useState();
  const [analytics, setAnalytics] = useState();
  const [regionPreview, setRegionPreview] = useState();
  const [results, setResults] = useState([]);
  const [refreshHeaders, setRefreshHeaders] = useState(false);

  const defaultColumns = useRef({});

  const headerRef = useRef();
  const labelRef = useRef();
  const overrideRef = useRef({});

  const {api: apiAnalytics} = useApi("facility-analytics");
  const {api: apiChecksheets} = useApi("checksheets");
  const {api: apiEvents} = useApi("events");
  const {api: apiReport} = useApi("reports");

  const inferSectionType = useCallback(
    type => {
      if (ANALYTIC_OPTIONS.includes(type)) return target?.analyticType;
      if (STATIC_OPTIONS.includes(type)) return STATIC;
      if (TABLE_OPTIONS.includes(type) && !!target.cellInterval && target.cellInterval !== "day")
        return AGGREGATE;
      if (TABLE_OPTIONS.includes(type)) return TABLE;
      return null;
    },
    [target]
  );

  const getEditableTable = t => {
    if (!t) return null;
    const colCount = t.length;
    const cellCount = t[0]?.length;
    if (!exists(colCount) || !exists(cellCount)) return {};

    const editable = {};

    t.map((col, colIdx) => {
      editable[`col${colIdx}`] = {};
      col.map((cell, cellIdx) => {
        editable[`col${colIdx}`][`cell${cellIdx}`] = cell;
      });
    });

    return {columns: colCount, cells: cellCount - 1, table: editable};
  };

  const formattedDefault = useMemo(
    () =>
      target
        ? {
            ...target,
            sectionType: inferSectionType(target.type),
            sourceType: target.type,
            source: target.sourceTable || target.sourceId,
            hasStats: target.stats?.some(({enabled}) => !!enabled),
            statSource: target.statSource,
            statType: target.statType,
            fields: [],
            ...getEditableTable(target.table)
          }
        : {...defaultValues, sectionType: selectedType},
    [target, selectedType, inferSectionType]
  );

  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: formattedDefault
  });
  const {
    watch,
    control,
    reset,
    handleSubmit,
    setValue,
    trigger,
    formState: {dirtyFields, errors, submitCount}
  } = form;
  const {fields, append, remove, replace, insert} = useFieldArray({control, name: "fields"});
  const {
    fields: stats,
    remove: removeStat,
    insert: insertStat
  } = useFieldArray({control, name: "stats"});
  const {
    sectionType,
    sourceType,
    sourceFacility,
    source,
    fields: watchedFields,
    stage,
    table,
    fullWidth,
    highlight,
    secondInput,
    hasBandedRows,
    hasHiddenGridlines,
    chartSources,
    statType,
    statSource,
    title,
    columns,
    cells,
    hasSecondInput,
    hasStats,
    cellInterval
  } = watch();

  const prevHasStats = usePrevious(hasStats);

  const getChecksheets = useCallback(
    () =>
      apiChecksheets
        .callGet(null, {facilityId: target?.sourceFacility || sourceFacility})
        .then(({status, data}) => {
          if (status === 200 && data) setChecksheets(data);
        }),
    [apiChecksheets, target, sourceFacility]
  );

  const getEvents = useCallback(
    () =>
      apiEvents
        .callGet(null, {facilityId: target?.sourceFacility || sourceFacility})
        .then(({status, data}) => {
          if (status === 200 && data) setEvents(data);
        }),
    [apiEvents, target, sourceFacility]
  );

  const getAnalytics = useCallback(
    () =>
      apiAnalytics
        .callGet(null, {facilityId: target?.sourceFacility || sourceFacility})
        .then(({status, data}) => {
          if (status === 200 && data?.analytics) setAnalytics(data.analytics);
        }),
    [apiAnalytics, target, sourceFacility]
  );

  // Set Options based on selected section
  const sourceTypeOptions = useMemo(() => {
    if (sectionType === TABLE || sectionType === AGGREGATE) return TABLE_OPTIONS;
    if (sectionType === STATIC) return STATIC_OPTIONS;
    if (sectionType === CHART || sectionType === STAT) return ANALYTIC_OPTIONS;
    return undefined;
  }, [sectionType]);

  // Get Checksheets / Events based on selected Facility Type
  useEffect(() => {
    if (sourceType === CHECKSHEET && sourceFacility) getChecksheets();
    if (sourceType === EVENT && sourceFacility) getEvents();
  }, [sourceFacility, sourceType, getChecksheets, getEvents]);

  // Get Analytics based on selected Facility Type
  useEffect(() => {
    if ((sectionType === CHART || sectionType === STAT) && sourceFacility) getAnalytics();
  }, [sectionType, sourceFacility, getAnalytics]);

  const sourceOptions = useMemo(() => {
    if (sourceType === CHECKSHEET && checksheets?.length > 0)
      return checksheets.map(({id, name, frequency, ...rest}) => ({
        name: id,
        label: frequency?.name ? `${frequency.name.toUpperCase()} ${name}` : name,
        ...rest
      }));

    // only allow events with existing records
    if (sourceType === EVENT && events?.length > 0)
      return events
        .filter(({lastCompleted}) => lastCompleted)
        .map(({id, name}) => ({
          name: id,
          label: name
        }));

    let filtered;
    if (sectionType === CHART && sourceType === EXISTING_ANALYTIC)
      filtered = analytics?.filter(({type}) => type === "plot");
    if (sectionType === STAT && sourceType === EXISTING_ANALYTIC)
      filtered = analytics?.filter(({type}) => type !== "plot");

    return filtered?.map(({id, ...rest}) => ({
      name: id,
      ...rest
    }));
  }, [sectionType, sourceType, checksheets, events, analytics]);

  // Get source based on selected sourceType
  const selectedChecksheet = useMemo(() => {
    if (sourceType === CHECKSHEET && checksheets?.length > 0 && source)
      return checksheets.find(({id}) => `${id}` === source);
    return undefined;
  }, [sourceType, source, checksheets]);

  const selectedEvent = useMemo(() => {
    if (sourceType === EVENT && events?.length > 0 && source)
      return events.find(({id}) => `${id}` === source);
    return undefined;
  }, [sourceType, source, events]);

  // Get target stage based on selected event
  const stageOptions = useMemo(() => {
    if (sourceType === EVENT && selectedEvent);
  }, [sourceType, selectedEvent]);

  const selectedStage = useMemo(() => {
    const {byId} = selectedEvent?.stages || {};
    if (byId && stage in byId) return byId[stage];
    return undefined;
  }, [selectedEvent, stage]);

  const selectedChart = useMemo(() => {
    if (sectionType !== CHART || !source || !analytics || sourceType !== EXISTING_ANALYTIC)
      return null;
    const selected = analytics.find(a => `${a.id}` === source);
    return selected || null;
  }, [source, sectionType, sourceType, analytics]);

  // Get available fields based on selected source
  const fieldOptions = useMemo(() => {
    let builder;

    if (sourceType === CHECKSHEET && source && selectedChecksheet)
      builder = selectedChecksheet?.builder;

    if (sourceType === EVENT && source && selectedStage) builder = selectedStage?.builder;

    if (builder)
      return getFields(builder.allIds, builder.byId)
        .filter(field => field.type === "number" || field.type === "generated")
        .map(({name, label, ancestry, units}) => ({name, label: ancestry || label, units}));

    return undefined;
  }, [sourceType, source, selectedChecksheet, selectedStage]);

  const targetFields = useMemo(() => {
    if (target) {
      let builder;

      if (sourceType === CHECKSHEET && source && selectedChecksheet)
        builder = selectedChecksheet?.builder;

      if (sourceType === EVENT && source && selectedStage) builder = selectedStage?.builder;

      if (builder) {
        const {byId} = builder;
        return target?.fields?.map(({name, stat}, i) => ({
          name: byId[name].name,
          defaultLabel: `${getAncestors(name, byId)} ${byId[name].label}`,
          label:
            target.headers?.[`column${i}`]?.enteredLabel ||
            `${getAncestors(name, byId)} ${byId[name].label}`,
          units: byId[name].units,
          stat: stat
        }));
      }
    }
    return [];
  }, [selectedChecksheet, selectedStage, source, sourceType, target]);

  // Update target fields
  useEffect(() => {
    if (targetFields?.length > 0) {
      defaultColumns.current = Object.fromEntries(
        targetFields.map(({name, defaultLabel}) => [name, defaultLabel])
      );
      setValue("fields", targetFields);
    }
  }, [targetFields, setValue]);

  const tableOptions = useMemo(() => {
    if (tables) return tables.map(t => ({value: t.rowId, label: t.title}));
    return [];
  }, [tables]);

  const tableSource = useMemo(() => {
    if (
      !tables ||
      ![CHART, STAT].includes(sectionType) ||
      !source ||
      sourceType !== ANALYTIC_FROM_TABLE
    )
      return null;
    const selected = tables.find(a => a.rowId === source);
    return selected || null;
  }, [source, sectionType, sourceType, tables]);

  const statSourceObj = useMemo(() => {
    if (tableSource && statSource)
      return (
        Object.entries(tableSource.headers)
          .filter(([key]) => key === statSource)
          .map(([, val]) => val)[0] || null
      );
    return null;
  }, [tableSource, statSource]);

  const analyticSourceOptions = useMemo(() => {
    if (selectedChart)
      return selectedChart.data?.sources?.map(s => ({
        label:
          selectedChart.data.fullLabels?.[s] || selectedChart.data.labels?.[s] || getReadableKey(s),
        name: s
      }));
    if (tableSource)
      return Object.entries(tableSource.headers)
        .filter(([, {index}]) => !exists(index) || index > 0)
        .map(([key, {enteredLabel, defaultLabel, label}]) => ({
          name: key,
          label: enteredLabel || defaultLabel || label
        }));
    return null;
  }, [selectedChart, tableSource]);

  // Reset when higher level field changes
  useEffect(() => {
    if (dirtyFields.sectionType) reset({...defaultValues, sectionType}, {keepDirty: false});
    else if (dirtyFields.sourceType)
      reset({...defaultValues, sectionType, sourceType}, {keepDirty: false});
    else if (dirtyFields.sourceFacility)
      reset({...defaultValues, sectionType, sourceType, sourceFacility}, {keepDirty: false});
    else if (dirtyFields.source) {
      reset(
        {
          ...defaultValues,
          sectionType,
          sourceType,
          sourceFacility,
          source,
          chartSources: analyticSourceOptions ? analyticSourceOptions.map(({name}) => name) : []
        },
        {keepDirty: false}
      );

      replace([]);
      defaultColumns.current = {};
    }
  }, [
    dirtyFields,
    sectionType,
    sourceType,
    sourceFacility,
    source,
    reset,
    analyticSourceOptions,
    replace
  ]);

  const headers = useMemo(() => {
    if (refreshHeaders) setRefreshHeaders(false);
    if (
      (sourceType === CHECKSHEET || sourceType === EVENT) &&
      source &&
      title &&
      watchedFields?.length
    ) {
      const h = {date: {label: "Date", index: 0}};
      watchedFields.map(({name, label, defaultLabel, units}, i) => {
        const newLabel = `${label} ${units && `(${units})`}`;
        h[`column${i}`] = {
          name,
          label: newLabel,
          enteredLabel: label,
          defaultLabel: defaultLabel,
          units,
          index: i + 1
        };
      });
      return h;
    }
    return undefined;
  }, [source, sourceType, title, watchedFields, refreshHeaders]);

  useEffect(() => {
    if (hasSecondInput) setValue("fullWidth", true);
    else setValue("secondInput", null);
  }, [hasSecondInput, setValue]);

  useEffect(() => {
    if (hasStats && prevHasStats === false) {
      setValue("stats", statsInitial);
    }
  }, [hasStats, setValue, prevHasStats]);

  // Create regions on the Report Builder
  const generateRegion = useCallback(async () => {
    const watchStats = watch("stats");

    const payload = {
      start,
      end,
      base,
      interval,
      regions: []
    };

    if (
      sectionType &&
      sectionType === CHART &&
      sourceType &&
      sourceType === EXISTING_ANALYTIC &&
      source &&
      chartSources?.length
    )
      payload.regions.push({
        rowId: "temp",
        type: sectionType,
        sourceFacility: sourceFacility,
        sourceId: source,
        fullWidth,
        chartSources
      });

    if (
      sectionType &&
      sectionType === STAT &&
      sourceType &&
      sourceType === EXISTING_ANALYTIC &&
      source
    )
      payload.regions.push({
        rowId: "temp",
        type: sectionType,
        sourceId: source,
        fullWidth
      });

    if (
      sectionType &&
      sectionType === CHART &&
      sourceType &&
      sourceType === ANALYTIC_FROM_TABLE &&
      tableSource &&
      title
    )
      payload.regions.push({
        rowId: "temp",
        title,
        type: sourceType,
        sourceId: tableSource.sourceId,
        sourceTable: tableSource.rowId,
        fullWidth,
        analyticType: CHART,
        sourceType: tableSource.type,
        stageId: tableSource.stageId,
        chartSources
      });

    if (
      sectionType &&
      sectionType === STAT &&
      sourceType &&
      sourceType === ANALYTIC_FROM_TABLE &&
      tableSource &&
      title
    )
      payload.regions.push({
        rowId: "temp",
        title,
        type: sourceType,
        sourceId: tableSource.sourceId,
        sourceTable: tableSource.rowId,
        fullWidth,
        analyticType: STAT,
        sourceType: tableSource.type,
        statType,
        statSource
      });

    if (sourceType && (sourceType === CHECKSHEET || sourceType === EVENT) && title && source) {
      payload.regions.push({
        rowId: "temp",
        type: sourceType,
        sourceFacility: sourceFacility,
        sourceId: source,
        title,
        fields: fields.map(({name, stat}) => ({name, stat})),
        headers,
        fullWidth,
        stats: watchStats?.map(stat => {
          delete stat.id;
          return stat;
        }),
        stageId: stage,
        hasBandedRows,
        hasHiddenGridlines,
        headerHeight:
          (headerRef.current?.getBoundingClientRect()?.height || 0) +
          (labelRef.current?.getBoundingClientRect()?.height || 0),
        cellInterval: sectionType === AGGREGATE ? cellInterval : undefined
      });
    }

    // Static Sections
    if (sectionType === STATIC && title)
      payload.regions.push({
        rowId: "temp",
        type: sourceType,
        title,
        fullWidth,
        highlight,
        hasSecondInput,
        secondInput
      });

    if (
      sectionType === TABLE &&
      sourceType === STATIC &&
      title &&
      table &&
      Object.values(table)?.length > 0
    ) {
      const formatted = Object.values(table).map(cell => Object.values(cell));
      payload.regions.push({
        rowId: "temp",
        type: sourceType,
        title,
        table: formatted,
        fullWidth,
        hasBandedRows,
        hasHiddenGridlines,
        headerHeight:
          (headerRef.current?.getBoundingClientRect()?.height || 0) +
          (labelRef.current?.getBoundingClientRect()?.height || 0)
      });
    }

    if (payload.regions.length > 0) {
      const {status, data} = await apiReport.callPost(payload);
      if (status === 200 && data?.temp) {
        return data.temp;
      }
    }

    return null;
  }, [
    apiReport,
    base,
    chartSources,
    cellInterval,
    end,
    fields,
    fullWidth,
    hasBandedRows,
    hasHiddenGridlines,
    hasSecondInput,
    headers,
    highlight,
    interval,
    secondInput,
    sectionType,
    sourceType,
    statType,
    statSource,
    sourceFacility,
    source,
    stage,
    start,
    table,
    title,
    watch,
    tableSource
  ]);

  useEffect(() => {
    generateRegion().then(r => setRegionPreview(r));
  }, [generateRegion]);

  const submitRegion = () => {
    generateRegion().then(r => {
      handleInsertRegion(r, target?.id);
      reset(defaultValues);
      setVisible(false);
    });
  };

  const listForSearch = useMemo(() => {
    if (sectionType === STAT && analyticSourceOptions) return analyticSourceOptions;
    if (sectionType !== STAT) return fieldOptions;
    return undefined;
  }, [analyticSourceOptions, fieldOptions, sectionType]);

  const search = query => {
    if (!listForSearch) return;
    const selected = fields && (sectionType === TABLE || sectionType === AGGREGATE) ? fields : [];
    if (query && listForSearch) {
      const lower = query.toLowerCase();
      const tempResults = listForSearch.filter(option => {
        if (option.label.toLowerCase().includes(lower)) {
          const filtered = selected.filter(({name}) => name === option.name);
          if (
            (sectionType === AGGREGATE && filtered.length < 5) ||
            (sectionType !== AGGREGATE && filtered.length === 0)
          )
            return true;
        }
        return false;
      });
      tempResults.sort(
        (a, b) => a.label.toLowerCase().indexOf(lower) - b.label.toLowerCase().indexOf(lower)
      );
      setResults(tempResults);
    } else
      setResults(
        listForSearch?.filter(option => {
          const filtered = selected.filter(({name}) => name === option.name);
          if (
            (sectionType === AGGREGATE && filtered.length < 5) ||
            (sectionType !== AGGREGATE && filtered.length === 0)
          )
            return true;
          return false;
        }) || []
      );
  };

  const onDragEnd = result => {
    const {destination, source: dragSource} = result;
    if (
      !destination ||
      dragSource?.index === destination.index ||
      destination.droppableId !== dragSource.droppableId
    ) {
      return;
    }
    const movingField = watch(`${dragSource.droppableId}.${dragSource.index}`);
    let sourceOffset = 0;
    let destinationOffset = 1;
    if (destination.index < dragSource.index) {
      sourceOffset = 1;
      destinationOffset = 0;
    }

    if (dragSource.droppableId === "stats") {
      insertStat(destination.index + destinationOffset, {...movingField});
      removeStat(dragSource.index + sourceOffset);
    } else {
      insert(destination.index + destinationOffset, {...movingField});
      remove(dragSource.index + sourceOffset);
    }
  };

  const noNumberFields = useMemo(() => fieldOptions?.length === 0, [fieldOptions]);
  const noneExistOfType = useMemo(
    () =>
      ((sourceType && SOURCE_OPTIONS.includes(sourceType)) ||
        ((sectionType === CHART || sectionType === STAT) && sourceType === EXISTING_ANALYTIC)) &&
      sourceOptions &&
      sourceOptions.length === 0,
    [sectionType, sourceOptions, sourceType]
  );

  const hasError = useMemo(
    () => noNumberFields || noneExistOfType,
    [noNumberFields, noneExistOfType]
  );

  // should go down list of stats and not allow duplicates
  const getInitialStatType = useCallback(
    fieldName => {
      if (!fields?.length) return "total";
      const typeNames = Object.keys(aggregationTypes);
      for (let i = 0; i < typeNames.length; i++) {
        const typeName = typeNames[i];
        if (!fields.some(({name, stat}) => name === fieldName && stat === typeName))
          return typeName;
      }
      return "";
    },
    [fields]
  );

  const getAvailStatTypes = useCallback(
    (fieldName, idx) => {
      if (!fields?.length) return aggregationTypes;
      const available = [];
      const typeNames = Object.keys(aggregationTypes);
      for (let i = 0; i < typeNames.length; i++) {
        const typeName = typeNames[i];
        if (!fields.some(({name, stat}, j) => name === fieldName && stat === typeName && j !== idx))
          available.push({name: typeName, label: aggregationTypes[typeName]});
      }
      return available;
    },
    [fields]
  );

  return (
    <Modal visible={visible} setVisible={setVisible} hasBackButton={!!goBack} goBack={goBack}>
      <Heading>
        Section Builder - {sectionType === AGGREGATE ? "Aggregate Table" : sectionType}
      </Heading>
      <FormProvider {...form}>
        <Form noValidate onSubmit={handleSubmit(submitRegion)}>
          {sectionType === AGGREGATE && (
            <FormField>
              <InputRadioGroup
                name="cellInterval"
                options={[
                  {name: "month", label: "By Month"},
                  {name: "year", label: "By Year"}
                ]}
              />
            </FormField>
          )}
          {sectionType && sourceTypeOptions && (
            <FormField>
              <InputSelect
                name="sourceType"
                placeholder="Source Type..."
                options={sourceTypeOptions}
              />
            </FormField>
          )}

          {sourceType &&
            sourceType !== STATIC &&
            sourceType !== ANALYTIC_FROM_TABLE &&
            types?.length > 0 && (
              <FormField>
                <InputSelect
                  name="sourceFacility"
                  placeholder="Source Facility..."
                  options={types.map(({id, name}) => ({
                    name: id,
                    label: name
                  }))}
                />
              </FormField>
            )}

          {sourceFacility && sourceOptions?.length > 0 && (
            <FormField>
              <InputSelect name="source" placeholder="Source..." options={sourceOptions} />
            </FormField>
          )}

          {sourceType === ANALYTIC_FROM_TABLE && (
            <FormField>
              <InputSelect name="source" placeholder="Source..." options={tableOptions} />
            </FormField>
          )}

          {sourceType === ANALYTIC_FROM_TABLE && sectionType === STAT && source && (
            <>
              <FormField>
                <InputSelect name="statType" placeholder="Type..." options={STAT_OPTIONS} />
              </FormField>
              {statType && (
                <FormField>
                  {!statSourceObj ? (
                    <SearchSelect
                      results={results}
                      setResults={setResults}
                      search={search}
                      add={field => setValue("statSource", field.name)}
                      showAll
                      large
                    />
                  ) : (
                    <StatSourceContainer>
                      <StatSourceName>
                        <Abbr title={statSourceObj.label}>{statSourceObj.label}</Abbr>
                      </StatSourceName>
                      <IconButton onClick={() => setValue("statSource", null)}>
                        <FontAwesomeIcon icon={faClose} />
                      </IconButton>
                    </StatSourceContainer>
                  )}
                </FormField>
              )}
            </>
          )}

          {noneExistOfType ? (
            <StyledError>
              There are no {sourceType ? sourceType.toLowerCase() : sectionType.toLowerCase()}s on
              this facility
            </StyledError>
          ) : (
            <>
              {/* Checksheet & Event Field Options */}
              {sourceType === EVENT && stageOptions && (
                <FormField>
                  <InputSelect name="stage" placeholder="Stage..." options={stageOptions} />
                </FormField>
              )}
              {fieldOptions?.length > 0 && (
                <StyledFormFieldWrapper>
                  <Inline>
                    <SearchSelect
                      results={results}
                      setResults={setResults}
                      search={search}
                      add={field => {
                        append({
                          ...field,
                          defaultLabel: field.label,
                          stat:
                            sectionType === AGGREGATE ? getInitialStatType(field.name) : undefined
                        });
                        defaultColumns.current[field.name] = field.label;
                        if (submitCount) trigger("fields");
                      }}
                      showAll
                      large
                      keepOpen
                    />
                    {fields?.length !== fieldOptions?.length && (
                      <Button
                        type="button"
                        onClick={() => {
                          defaultColumns.current = {};
                          fieldOptions.map(field => {
                            defaultColumns.current[field.name] = field.label;
                          });

                          replace(fieldOptions);
                        }}>
                        Select All
                      </Button>
                    )}
                  </Inline>
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="fields">
                      {outerProvided => (
                        <div {...outerProvided.droppableProps} ref={outerProvided.innerRef}>
                          {fields?.length > 0 &&
                            fields.map(({name, label, stat}, i) => {
                              const override = watch(`fields.${i}.override`);
                              const newLabel = watch(`fields.${i}.label`);
                              const currentLabel = newLabel || label;
                              return (
                                <Draggable
                                  draggableId={`${name}.${i}`}
                                  index={i}
                                  key={`${name}-${stat}`}
                                  isDragDisabled={fields.length <= 1}>
                                  {innerProvided => (
                                    <>
                                      <ColumnInfo
                                        ref={innerProvided.innerRef}
                                        hasAggregateOptions={sectionType === AGGREGATE}
                                        {...innerProvided.draggableProps}
                                        {...innerProvided.dragHandleProps}>
                                        <Inline key={`${name}-${stat}`}>
                                          <FontAwesomeIcon icon={faArrowsUpDownLeftRight} />
                                          {override ? (
                                            <InputText
                                              name={`fields.${i}.label`}
                                              callbackFocus={() => {
                                                // set original name
                                                if (!defaultColumns.current[name]) {
                                                  setValue(`fields.${i}.defaultLabel`, label);
                                                  defaultColumns.current[name] = label;
                                                }
                                              }}
                                              callbackBlur={e => {
                                                if (
                                                  !overrideRef.current[i]?.contains(e.relatedTarget)
                                                ) {
                                                  setValue(`fields.${i}.override`, false);
                                                  setRefreshHeaders(true);
                                                }
                                              }}
                                            />
                                          ) : (
                                            <Abbr title={currentLabel}>
                                              {currentLabel?.toUpperCase()}
                                            </Abbr>
                                          )}
                                          &nbsp;
                                          {newLabel?.toUpperCase() !==
                                            defaultColumns.current[name].toUpperCase() && (
                                            <Abbr title={`(${defaultColumns.current[name]})`}>
                                              ({defaultColumns.current[name].toUpperCase()})
                                            </Abbr>
                                          )}
                                        </Inline>
                                        <Inline>
                                          <SelectWrapper>
                                            {sectionType === AGGREGATE && (
                                              <InputSelect
                                                name={`fields.${i}.stat`}
                                                options={getAvailStatTypes(name, i)}
                                              />
                                            )}
                                          </SelectWrapper>
                                          <Button
                                            type="button"
                                            title="Add Alias"
                                            onClick={() => {
                                              if (override) setRefreshHeaders(true);
                                              setValue(`fields.${i}.override`, !override);
                                            }}
                                            ref={ref => {
                                              overrideRef.current[i] = ref;
                                            }}>
                                            <FontAwesomeIcon icon={override ? faCheck : faPencil} />
                                          </Button>
                                          <Button
                                            type="button"
                                            title="Remove"
                                            onClick={() => remove(i)}>
                                            <FontAwesomeIcon icon={faClose} />
                                          </Button>
                                        </Inline>
                                      </ColumnInfo>
                                      {innerProvided.placeholder}
                                    </>
                                  )}
                                </Draggable>
                              );
                            })}
                          {outerProvided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                  <ClearAll>
                    {!!fields?.length && fields.length > 1 && (
                      <Button
                        type="button"
                        onClick={() => {
                          defaultColumns.current = {};
                          replace([]);
                        }}>
                        Clear
                      </Button>
                    )}
                  </ClearAll>
                  <InputError errors={errors} name="fields" />
                </StyledFormFieldWrapper>
              )}

              {sectionType === CHART && analyticSourceOptions && (
                <FormGroup>
                  <InputCheckGroup
                    name="chartSources"
                    label="FIELDS INCLUDED"
                    options={analyticSourceOptions}
                  />
                </FormGroup>
              )}

              {noNumberFields && (
                <StyledError>
                  The selected {sourceType ? sourceType.toLowerCase() : "checksheet"} does not
                  contain any number fields
                </StyledError>
              )}

              {!hasError && (!!stage || sourceType !== EVENT) && (
                <>
                  {/* Static Table */}
                  {sectionType && sectionType === TABLE && sourceType && sourceType === STATIC && (
                    <FormGroup>
                      <InputSelect
                        name="columns"
                        placeholder="Columns..."
                        label="# of Columns"
                        options={[1, 2, 3, 4]}
                      />
                      <InputSelect
                        name="cells"
                        label="Cells per Column"
                        placeholder="Cells..."
                        options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
                      />
                    </FormGroup>
                  )}

                  {sectionType &&
                    sourceType &&
                    ((sectionType !== CHART && sectionType !== STAT) ||
                      sourceType !== EXISTING_ANALYTIC) &&
                    sourceType !== TEXT && (
                      <FormGroup>
                        <InputText
                          name="title"
                          placeholder={`${
                            sectionType === AGGREGATE ? "Table" : sectionType
                          } Title...`}
                          spellcheck
                        />
                      </FormGroup>
                    )}

                  {sectionType && sourceType && sourceType === TEXT && (
                    <FormGroup>
                      <InputTextArea
                        name="title"
                        placeholder={`${sectionType} Text...`}
                        spellcheck
                      />
                    </FormGroup>
                  )}

                  {columns && cells && (
                    <FormGroup>
                      <InputTable
                        name="table"
                        label="Provide rows"
                        colCount={Number.parseInt(columns, 10)}
                        cellCount={Number.parseInt(cells, 10)}
                      />
                    </FormGroup>
                  )}
                  <FormGroup>
                    {source && (sourceType !== EVENT || !!stage) && (
                      <InputCheck name="fullWidth" disabled={!!hasSecondInput}>
                        Force section to occupy full width of page?
                      </InputCheck>
                    )}
                    {sourceType === INPUT && (
                      <InputCheck name="hasSecondInput">Add additional input to line?</InputCheck>
                    )}
                    {hasSecondInput && (
                      <InputText
                        name="secondInput"
                        placeholder="Additional Input Title..."
                        maxLength={75}
                      />
                    )}
                    {sourceType === TEXT && (
                      <InputCheck name="highlight">Highlight in text box?</InputCheck>
                    )}
                    {(sectionType === TABLE || sectionType === AGGREGATE) &&
                      sourceType &&
                      sourceType !== STATIC &&
                      source && (
                        <>
                          <InputCheck name="hasBandedRows">Banded rows?</InputCheck>
                          <InputCheck name="hasHiddenGridlines">Hide grid lines?</InputCheck>
                        </>
                      )}
                    {(sectionType === TABLE || sectionType === AGGREGATE) &&
                      sourceType &&
                      sourceType !== STATIC &&
                      source && (
                        <>
                          <InputCheck name="hasStats" showError={false}>
                            Include Stats?
                          </InputCheck>
                          {hasStats && (
                            <StatsWrapper>
                              <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="stats">
                                  {outerProvided => (
                                    <div
                                      {...outerProvided.droppableProps}
                                      ref={outerProvided.innerRef}>
                                      {stats.map((_v, i) => {
                                        const enabled = watch(`stats.${i}.enabled`);
                                        const name = watch(`stats.${i}.name`);

                                        return (
                                          <Draggable draggableId={name} index={i} key={name}>
                                            {innerProvided => (
                                              <>
                                                <ColumnInfo
                                                  enabled={enabled}
                                                  ref={innerProvided.innerRef}
                                                  {...innerProvided.draggableProps}
                                                  {...innerProvided.dragHandleProps}>
                                                  <Inline key={name}>
                                                    <FontAwesomeIcon
                                                      icon={faArrowsUpDownLeftRight}
                                                    />
                                                    {statLabels[name]?.toUpperCase()}
                                                  </Inline>
                                                  <Enable
                                                    type="button"
                                                    title="Toggle Visibility"
                                                    onClick={() => {
                                                      setValue(`stats.${i}.enabled`, !enabled, {
                                                        shouldDirty: true,
                                                        shouldValidate: !!submitCount
                                                      });
                                                    }}>
                                                    <FontAwesomeIcon
                                                      icon={enabled ? faEye : faEyeSlash}
                                                    />
                                                  </Enable>
                                                </ColumnInfo>
                                                {innerProvided.placeholder}
                                              </>
                                            )}
                                          </Draggable>
                                        );
                                      })}
                                      {outerProvided.placeholder}
                                    </div>
                                  )}
                                </Droppable>
                              </DragDropContext>
                            </StatsWrapper>
                          )}
                        </>
                      )}
                    <InputError errors={errors} name="stats" />
                  </FormGroup>
                </>
              )}
            </>
          )}

          <FormFieldWrapper>
            <StyledButtonFull type="submit">Create Section</StyledButtonFull>
          </FormFieldWrapper>
        </Form>
      </FormProvider>

      {regionPreview && (
        <Hidden fullWidth={fullWidth || (headers && Object.keys(headers).length > 4)}>
          <Region
            {...regionPreview}
            fontSize={fontSize}
            headerRef={headerRef}
            labelRef={labelRef}
          />
        </Hidden>
      )}
    </Modal>
  );
};

ModalAddSection.propTypes = {
  visible: PropTypes.bool,
  setVisible: PropTypes.func.isRequired,
  handleInsertRegion: PropTypes.func.isRequired,
  goBack: PropTypes.func,
  selectedType: PropTypes.string,
  tables: PropTypes.arrayOf(PropTypes.any),
  start: PropTypes.string,
  end: PropTypes.string,
  base: PropTypes.string,
  interval: PropTypes.string,
  target: PropTypes.objectOf(PropTypes.any),
  fontSize: PropTypes.number
};

const StyledButtonFull = styled(ButtonFull)`
  padding: ${pad / 2}px;
`;

const StyledFormFieldWrapper = styled(FormFieldWrapper)`
  margin-bottom: ${pad}px;
`;

const ColumnInfo = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${pad}px;
  color: ${props => props.theme.tertiary};
  background: ${props => props.theme.secondary};
  border-radius: ${radius};
  margin-top: ${pad}px;

  ${({enabled}) =>
    enabled === false &&
    css`
      opacity: 0.6;
    `}

  ${FormFieldWrapper} {
    margin: 0;
  }

  ${Inline}:first-child {
    ${({hasAggregateOptions}) =>
      hasAggregateOptions
        ? css`
            width: calc(90% - 150px);
          `
        : css`
            width: 90%;
          `}
  }

  ${Inline}:last-child {
    ${({hasAggregateOptions}) =>
      hasAggregateOptions
        ? css`
            width: calc(10% + 150px);
          `
        : css`
            width: 10%;
          `}
    justify-content: end;
    gap: ${pad}px;
  }

  svg {
    fill: ${props => props.theme.tertiary};
  }

  ${Abbr} {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  input {
    background: ${props => props.theme.tertiary};
    max-width: 150px;
  }

  ${Button} {
    padding: 0 ${pad}px;
    background: transparent;
  }
`;

const StyledError = styled(Error)`
  margin-bottom: ${pad / 2}px;
`;

const ClearAll = styled.div`
  margin-top: ${pad}px;
`;

const StatsWrapper = styled.div`
  width: 150px;
`;

const Hidden = styled.div`
  opacity: 0;
  position: fixed;
  width: 450px;

  ${({fullWidth}) =>
    fullWidth &&
    css`
      width: 900px;
    `}
`;

const Enable = styled(Button)`
  margin-right: 0px;
`;

const StatSourceContainer = styled(Pill)`
  width: min-content;
  height: min-content;
  max-width: 100%;
  margin: 0 ${pad}px ${pad}px 0;
`;

const StatSourceName = styled(Text)`
  ${voice.normal};
  width: min-content;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
  margin-right: ${pad / 2}px;
`;

const IconButton = styled(Button)`
  ${voice.normal};
  background-color: transparent;
  width: min-content;
  padding: 0;

  svg {
    fill: ${props => props.theme.secondary};
  }
`;

const SelectWrapper = styled.div`
  width: 150px;
`;

export default ModalAddSection;
