import React, {useCallback, useContext, useEffect, useLayoutEffect, useRef, useState} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faFileExport,
  faSearch,
  faUserPlus,
  faTrash,
  faPaperPlane,
  faEdit
} from "@fortawesome/free-solid-svg-icons";

// Hooks
import useApi from "../../hooks/useApi.js";
import useMountedState from "../../hooks/useMountedState.js";
import usePrevious from "../../hooks/usePrevious.js";

// Contexts
import {useToast} from "../../contexts/toast.js";
import {AuthContext} from "../../contexts/auth.js";

// Components
import BaseTable from "../../components/BaseTable.js";
import Modal from "../../components/Modal.js";
import ModalDelete from "../../components/ModalDelete.js";
import ModalUserFilter from "./ModalUserFilter.js";
import ModalExport from "../../components/ModalExport.js";
import ModalAssign from "../users/ModalAssign.js";
import ModalManage from "../users/ModalManage.js";

// Style
import {heroTheme, pad} from "../../style/components/variables.js";
import {bp} from "../../style/components/breakpoints.js";
import {
  Abbr,
  Inline,
  Text,
  TableFooter,
  TableWrapper,
  Small,
  SearchWrapper,
  Search,
  Button,
  Heading,
  TableMenu,
  SearchIcon,
  Pill,
  HeadingCenter
} from "../../style/components/general.js";

const ModalViewAllUsers = ({visible, setVisible, defaultFilters, showOptions, reload}) => {
  const isMounted = useMountedState();

  const {addToast} = useToast();

  const {roleCanAccessResource} = useContext(AuthContext);

  const [tableData, setTableData] = useState();
  const [original, setOriginal] = useState();
  const [loading, setLoading] = useState(false);
  // Edit Options
  const [edit, setEdit] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  // Search
  const [query, setQuery] = useState();
  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [pageTotal, setPageTotal] = useState(1);
  const [total, setTotal] = useState(0);
  const [orderBy, setOrderBy] = useState("asc");
  const [groupBy, setGroupBy] = useState("firstName");
  // Filters
  const [activeFilters, setActiveFilters] = useState();
  const [showHidden, setShowHidden] = useState(false);
  // Modals
  const [showModal, setShowModal] = useState(false);
  const [showModalFilters, setShowModalFilters] = useState(false);
  const [showModalInvite, setShowModalInvite] = useState(false);
  const [showModalManage, setShowModalManage] = useState(false);
  const [showModalAssign, setShowModalAssign] = useState(false);
  const [showModalExport, setShowModalExport] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);

  const scrollPos = useRef(null);
  const scrollContainer = useRef(null);

  const {api: apiUsers} = useApi("users");
  const {api: apiSuppressed} = useApi("users", {suppress: {success: true, error: true}});
  const {api: apiSend} = useApi("send-invite");

  const limit = useRef(12);

  const prevOrderBy = usePrevious(orderBy);
  const prevGroupBy = usePrevious(groupBy);

  const restore = useCallback(
    u => {
      const payload = {restore: true, ids: u.map(({publicId}) => publicId)};
      apiSuppressed.callPatch(null, payload).then(({status}) => {
        if (status === 200) {
          addToast(u.length === 1 ? "User restored" : "Users restored", "success");
          setTableData(null);
        } else
          addToast(u.length === 1 ? "Failed to restore user" : "Failed to restore users", "error");
      });
    },
    [apiSuppressed, addToast]
  );

  const formatTableData = useCallback(
    formattedUsers =>
      formattedUsers.map(user => {
        const {
          publicId,
          firstName,
          lastName,
          company,
          department,
          isInvited,
          isAccepted,
          isActive,
          isDeleted
        } = user;

        return {
          ...user,
          disabled: !isActive || !isAccepted,
          publicId: publicId,
          firstName: `${firstName} ${lastName}`,
          userCompanyId: company?.name,
          userDepartmentId: department?.name,
          status: (
            <Pill color={!isDeleted ? heroTheme.success : heroTheme.error}>
              {isActive && isAccepted && !isDeleted && <Small inverted>Active</Small>}
              {isDeleted && (
                <Small inverted>
                  <Abbr title="User is deleted">deleted!</Abbr>
                </Small>
              )}
              {isInvited && !isAccepted && !isDeleted && (
                <Small inverted>
                  <Abbr title="User has not accepted invitation">invited</Abbr>
                </Small>
              )}
              {isInvited && isAccepted && !isActive && !isDeleted && (
                <Small inverted>
                  <Abbr title="User is disabled">disabled</Abbr>
                </Small>
              )}
              {!isInvited && !isActive && !isDeleted && (
                <Small inverted>
                  <Abbr title="User has not been invited">contact</Abbr>
                </Small>
              )}
            </Pill>
          ),
          invite: (
            <Button
              type="button"
              onClick={() => {
                setSelectedUser(user);
                setShowModalInvite(true);
              }}
              title={isInvited ? "Resend" : "Send"}>
              <FontAwesomeIcon icon={faPaperPlane} />
            </Button>
          ),
          options: (
            <Inline>
              <Button
                type="button"
                onClick={() => {
                  setSelectedUser(user);
                  setShowModalManage(true);
                }}
                title="Edit User">
                <FontAwesomeIcon icon={faEdit} />
              </Button>
              {roleCanAccessResource("user", "delete") && (
                <Delete
                  type="button"
                  onClick={() => {
                    if (isDeleted) restore([user]);
                    else {
                      setSelectedUser(user);
                      setShowModalDelete(true);
                    }
                  }}
                  title={isDeleted ? "Restore" : "Deleted"}>
                  {isDeleted ? "Restore" : <FontAwesomeIcon icon={faTrash} />}
                </Delete>
              )}
            </Inline>
          )
        };
      }),
    [restore, roleCanAccessResource]
  );

  const getTableData = useCallback(
    (p, l) => {
      setLoading(true);

      const filter = {...defaultFilters};
      if (query) filter.Search = query;
      if (activeFilters?.roles) filter.Roles = activeFilters.roles;
      if (showHidden) {
        filter.showHidden = true;
        if (activeFilters?.state) filter.State = activeFilters.state;
      }

      apiUsers
        .callGet("", {
          page: p || 1,
          orderBy,
          groupBy,
          limit: l ?? limit.current,
          filter: JSON.stringify(filter)
        })
        .then(({status, data}) => {
          if (status === 200 && data) {
            setCurrentPage(data.page);
            setPageTotal(data.pages);
            setOriginal(data.users);
            const formatted = formatTableData(data.users);
            setTableData(prev => (p === 1 ? formatted : [...prev, ...formatted]));

            // Page Limit
            const {total: dataTotal} = data;
            setTotal(dataTotal);
            let count = 10;
            const temp = [];
            while (count < dataTotal + 10 && count <= 30) {
              temp.push(count);
              count += 10;
            }

            setEdit([]);
            setLoading(false);
          }
        });
    },
    [
      apiUsers,
      defaultFilters,
      query,
      activeFilters,
      showHidden,
      orderBy,
      groupBy,
      limit,
      formatTableData
    ]
  );

  useEffect(() => {
    if (
      isMounted() &&
      prevOrderBy &&
      prevGroupBy &&
      (prevOrderBy !== orderBy || prevGroupBy !== groupBy)
    )
      getTableData(1);
  }, [isMounted, getTableData, prevOrderBy, prevGroupBy, orderBy, groupBy]);

  useEffect(() => {
    if (query || query === "") getTableData(1);
  }, [getTableData, query]);

  // Initial Load
  useEffect(() => {
    if (isMounted() && !tableData) {
      // estimation for number of cards to overflow
      const approxRows = Math.ceil((window.innerHeight * 0.9 - 200) / 40);
      limit.current = approxRows;
      getTableData(1, approxRows);
    }
  }, [isMounted, tableData, getTableData]);

  // Load more on Scroll
  const handleScroll = useCallback(() => {
    const maxScrollTop =
      scrollContainer.current.scrollHeight - scrollContainer.current.clientHeight;
    if (
      scrollContainer.current.scrollTop + 1 >= maxScrollTop &&
      currentPage < pageTotal &&
      !loading
    )
      getTableData(currentPage + 1);
  }, [currentPage, getTableData, loading, pageTotal]);

  useEffect(() => {
    const modal = scrollContainer.current;
    modal.addEventListener("scroll", handleScroll);
    return () => modal.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  useLayoutEffect(() => {
    if (showModalFilters || showModalManage || showModalExport || showModalDelete) {
      scrollPos.current = document.getElementById("modal").scrollTop;
      setShowModal(true);
    } else {
      scrollContainer.current.scrollTop = scrollPos.current;
      setShowModal(false);
    }
  }, [showModalFilters, showModalManage, showModalExport, showModalDelete]);

  useEffect(() => {
    if (edit.length === 1)
      original.map(user => {
        if (user.publicId === edit[0].publicId) setSelectedUser(user);
      });
    else if (edit.length > 1) setSelectedUser(null);
  }, [edit, original, setSelectedUser]);

  const handleSearch = e => {
    setCurrentPage(1);
    setQuery(e.target.value);
  };

  const handleReload = () => {
    setCurrentPage(1);
    getTableData(1);
    setSelectedUser(null);
    setEdit([]);
    reload();
  };

  if (showModal && showModalFilters)
    return (
      <ModalUserFilter
        visible={showModalFilters}
        setVisible={setVisible}
        hasBackButton
        goBack={() => setShowModalFilters(false)}
        filters={activeFilters}
        setFilters={setActiveFilters}
        showHidden={showHidden}
        setShowHidden={setShowHidden}
      />
    );

  if (showModal && showModalExport)
    return (
      <ModalExport
        visible={showModalExport}
        setVisible={setVisible}
        hasBackButton
        goBack={() => setShowModalExport(false)}
        getExport={exportParams =>
          apiUsers.callGet(null, {
            ...exportParams,
            filter: JSON.stringify({ExcludeCreator: true})
          })
        }
      />
    );

  if (showModalInvite && selectedUser)
    return (
      <Modal
        visible={showModalInvite}
        setVisible={setVisible}
        hasBackButton
        goBack={() => {
          setSelectedUser(null);
          setShowModalInvite(false);
        }}>
        <HeadingCenter>Send Invite?</HeadingCenter>
        <Text>
          Are you sure you want send a new invite to {selectedUser.firstName}&nbsp;
          {selectedUser.lastName} ({selectedUser.email})?
        </Text>
        <br />
        <Button
          type="button"
          onClick={() =>
            apiSend.callPatch(null, {
              publicId: selectedUser?.publicId
            })
          }>
          Invite
        </Button>
      </Modal>
    );

  if (edit && showModalAssign)
    return (
      <ModalAssign
        visible={showModalAssign}
        setVisible={setVisible}
        hasBackButton
        goBack={() => {
          setSelectedUser(null);
          setShowModalAssign(false);
        }}
        users={edit}
        setUsers={setEdit}
      />
    );

  if (showModal && showModalManage)
    return (
      <ModalManage
        visible={showModalManage}
        setVisible={setVisible}
        hasBackButton
        goBack={() => {
          setSelectedUser(null);
          setShowModalManage(false);
        }}
        user={selectedUser}
        updateTable={handleReload}
      />
    );

  if (showModal && showModalDelete)
    return (
      <ModalDelete
        visible={showModalDelete}
        setVisible={setVisible}
        hasBackButton
        goBack={() => {
          setSelectedUser(null);
          setShowModalDelete(false);
        }}
        title="Delete Users"
        prompt={`Are your sure you want to delete ${
          selectedUser ? `${selectedUser.firstName}'s account?` : "the selected accounts?"
        } This action cannot be undone.`}
        confirmDelete={() => {
          const ids =
            edit?.length > 1 ? edit.map(current => current.publicId) : [selectedUser.publicId];
          apiUsers.callDelete(null, {data: {ids: ids}}).then(({status}) => {
            if (status === 200) {
              handleReload();
              setShowModalDelete(false);
            }
          });
        }}
      />
    );

  return (
    <Modal
      visible={visible}
      setVisible={setVisible}
      scrollRef={scrollContainer}
      maxWidth="90vw"
      width="800px">
      <TableWrapper>
        <TableMenuRow>
          <StyledInline>
            <Heading>Users</Heading>
            <InlineSpaced>
              <SearchWrapper>
                <SearchIcon>
                  <FontAwesomeIcon icon={faSearch} />
                </SearchIcon>
                <Search
                  name="search"
                  type="text"
                  placeholder="Search..."
                  onChange={e => handleSearch(e)}
                />
              </SearchWrapper>
              <TableMenu>
                {edit?.length > 0 ? (
                  <Inline>
                    {edit?.length > 0 && <Small>{edit.length}&nbsp;selected</Small>}
                    {edit?.length > 0 && !edit.some(user => user.isDeleted) && (
                      <Button
                        type="button"
                        title="Assign User(s)"
                        onClick={() => setShowModalAssign(true)}>
                        Assign Users(s)
                      </Button>
                    )}
                    {edit?.length === 1 &&
                      !edit[0].isDeleted &&
                      roleCanAccessResource("user", "update") && (
                        <Button
                          type="button"
                          title="Edit"
                          onClick={() => selectedUser && setShowModalManage(true)}>
                          <FontAwesomeIcon icon={faEdit} />
                        </Button>
                      )}
                    {roleCanAccessResource("user", "delete") && (
                      <Delete
                        type="button"
                        title={edit.length === 1 && edit[0].isDeleted ? "Restore" : "Delete"}
                        onClick={() => {
                          if (edit.length === 1 && edit[0].isDeleted) restore();
                          else setShowModalDelete(true);
                        }}>
                        <FontAwesomeIcon icon={faTrash} />
                      </Delete>
                    )}
                  </Inline>
                ) : (
                  <Inline>
                    {roleCanAccessResource("user", "export") &&
                      tableData &&
                      tableData.length > 0 && (
                        <Button type="button" onClick={() => setShowModalExport(true)}>
                          <Abbr title="Export">
                            <FontAwesomeIcon icon={faFileExport} />
                          </Abbr>
                        </Button>
                      )}
                    {roleCanAccessResource("user", "create") && (
                      <Button
                        type="button"
                        aria-label="add user"
                        data-testid="openUserModal"
                        title="Add User"
                        onClick={() => setShowModalManage(true)}>
                        <FontAwesomeIcon icon={faUserPlus} />
                      </Button>
                    )}
                  </Inline>
                )}
              </TableMenu>
            </InlineSpaced>
          </StyledInline>
        </TableMenuRow>
        <BaseTable
          headings={{
            publicId: {header: "", disabled: true},
            firstName: {header: "Name", disabled: false},
            userCompanyId: {header: "Company", disabled: false},
            userDepartmentId: {header: "Department", disabled: false},
            status: {header: "Status", disabled: false},
            invite: {header: "Invite", disabled: true},
            options: {header: "Options", disabled: true}
          }}
          data={tableData}
          loading={loading}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          groupBy={groupBy}
          setGroupBy={setGroupBy}
          items={edit}
          setItems={setEdit}
          showOptions={showOptions}
        />
        <TableFooter>
          <Inline>
            <Text>
              <span>{total} total</span>
            </Text>
          </Inline>
        </TableFooter>
      </TableWrapper>
    </Modal>
  );
};

ModalViewAllUsers.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  defaultFilters: PropTypes.objectOf(PropTypes.any),
  showOptions: PropTypes.bool,
  reload: PropTypes.func.isRequired
};

// Style Overrides
const TableMenuRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${pad}px;
`;

const StyledInline = styled(Inline)`
  gap: ${pad}px;
  display: block;
  width: 100%;

  ${bp(1)} {
    display: flex;
  }
`;

const InlineSpaced = styled(Inline)`
  justify-content: space-between;
  width: 100%;
`;

const Delete = styled(Button)`
  background: ${({theme}) => theme.error};
`;

export default ModalViewAllUsers;
